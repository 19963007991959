const oldColors = {
  pajarito: '#05495F',
  pajaritoDarker: '#043342',
  pajaritoBrighter: '#076685',
  pajaritoMuted: '#3F5056',
  pajaritoLowOpacity: '#87A6B1',
  pajaritoTransparent: '#05495F25',
  pajaritoLight: '#F1F5F6',
  dark: '#1E1E1E',
  white: '#FFFFFF',
  grey: '#AFAFAF',
  greyTransparent: '#AFAFAF50',
  green: '#4F9B80',
  greenTransparent: '#4F9B8050',
  red: '#D56E68',
  redTransparent: '#D56E6850',
  purple: '#8184D7',
  yellow: '#F8DF7E',
  yellowLight: '#F8E7A590',
  neutralHighMedium: '#F3F3F3',
  neutralHighUltraLight: '#F7F9FA',
  neutralLowLight: '#8E8E8E',
  neutralLowMedium: '#565656',
  neutralLowPure: '#1E1E1E',
  greyLight: '#F7F8F9',
  greyLightTransparent: '#F7F8F970',
  greenLight: '#F1F5F6',
  greenBackground: '#E5ECEF',
  stroke: '#EFEFEF',
  greyBorder: ' #E4E4E4',
  neutralMedium: '#565656'
}

export const colors = {
  ...oldColors,
  brandPrimaryPure: '#05495F',
  brandPrimaryMedium: '#81A3AE',
  brandPrimaryLight: '#F1F5F6',
  brandPrimaryLight50: '#F1F5F680',
  brandPrimaryTransparentStrong: '#05495FCC',
  brandPrimaryTransparentModerate: '#05495F52',
  brandPrimaryTransparentWeak: '#05495F29',
  brandPrimaryTransparent07: '#05495F12',

  brandPrimaryHover: '#E9EFF1',

  feedbackPositivePure: '#4F9B80',
  feedbackPositiveMedium: '#A6CCBF',
  feedbackPositiveLight: '#E5F0EC',
  feedbackWarningPure: '#FBDF7E',
  feedbackWarningMedium: '#FCEFBE',
  feedbackWarningLight: '#FFF8DF',
  feedbackNegativePure: '#D46E68',
  feedbackNegativeMedium: '#E9B6B3',
  feedbackNegativeLight: '#FBF1F0',
  feedbackInformativePure: '#8184D7',
  feedbackInformativeMedium: '#BFC1EA',
  feedbackInformativeLight: '#F3F3FB',

  neutralLowPure: '#1E1E1E',
  neutralLowPure50: '#1E1E1E80',
  neutralLowMedium: '#565656',
  neutralLowMedium50: '#56565680',
  neutralLowLight: '#8E8E8E',
  neutralLowLight50: '#8E8E8E80',
  neutralHighPure: '#E4E4E4',
  neutralHighPure50: '#E4E4E480',
  neutralHighMedium: '#F3F3F3',
  neutralHighMedium50: '#F3F3F380',
  neutralHighLight: '#FFFFFF',
  neutralHighLight50: '#FFFFFF80',

  intentionQuestion: '#EDBB0D',
  blueHighlight: '#4D9CBF',

  gray200: '#ECECEE',

  topbar: '#011720',
  brandPrimaryLowLight: '#C2D3D8'
}

export const chartColors = [
  colors.brandPrimaryPure,
  colors.brandPrimaryMedium,
  colors.feedbackInformativePure,
  colors.feedbackNegativePure,
  colors.feedbackPositivePure,
  colors.feedbackInformativeMedium,
  colors.feedbackNegativeMedium,
  colors.feedbackPositiveMedium,
  colors.feedbackWarningPure
]

export type ColorKeys = keyof typeof colors
type ColorVariant = {
  [x in ColorKeys]: { color: string }
}
export const colorVariant: ColorVariant = Object.keys(colors).reduce((acc, color) => {
  return { ...acc, [color]: { color: `$${color}` } }
}, {} as ColorVariant)

export const backgroundColorVariant: ColorVariant = Object.keys(colors).reduce((acc, color) => {
  return { ...acc, [color]: { backgroundColor: `$${color}Light` } }
}, {} as ColorVariant)
