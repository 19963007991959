import FlexContainer from '@/components/atoms/flex-container'
import Card from '../../card/Card'
import { ChatText, Export } from '@phosphor-icons/react'
import useFeedbackSearch from '@/hooks/feedback/new/useFeedbackSearch'
import { Suspense, lazy, useEffect, useMemo, useState } from 'react'
import { NewFeedback } from '@/types/feedbacks/Feedback'
import FeedError from '../../feed/FeedError'
import ScrollLoader from '../../feed/ScrollLoader'
import NoResults from '../../feed/NoResults'
import InfiniteScroll from 'react-infinite-scroll-component'
import FeedbackItem from '../../feed/new/feedback/feedback-item/FeedbackItem'
import { DeleteDialog } from '@/components/atoms/dialog'
import { shallow } from 'zustand/shallow'
import useRemoveOpportunityFeedbackStore from '@/store/useRemoveOpportunityFeedbackStore'
import useFeedbackMutations from '@/hooks/feedback/new/useFeedbackMutations'
import FeedbackSourcesDistribution from './FeedbackSourcesDistribution'
import { SourceDistributionItem } from '@/types/analytics/Sources'
import useStringSelection from '@/hooks/useStringSelection'
import FeedbackActionBar from './FeedbackActionBar'
import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import { CheckedState } from '@radix-ui/react-checkbox'
import useLocalStorage from '@/hooks/useLocalStorage'
import { OPPORTUNITY_PLAN_KEY } from '@/types/params/OpportunityPlanParams'
import Button from '@/components/atoms/button'
import Tooltip from '@/components/atoms/tooltip'
import useFeedbackExport from '@/hooks/feedback/new/useFeedbackExport'
import useSegment from '@/hooks/useSegment'
import { useTranslation } from 'react-i18next'
import { defaultOpportunityPlanFormData } from '@/pages/params'
import useOpportunityStore from '@/store/useOpportunityStore'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import usePinnedFeedback from '@/hooks/opportunity/usePinnedFeedback'
import TextDivider from '@/components/atoms/divider/TextDivider'

const FeedbackDetails = lazy(
  () => import('../../feed/new/feedback/feedback-details/FeedbackDetails')
)
const FeedbackConversation = lazy(
  () => import('../../feed/new/feedback/feedback-conversation/FeedbackConversation')
)

interface Props {
  trackSourceChange: (sourceKey: string | undefined) => void
  trackExportFeedback: () => void
}

const FeedbackListExploration = ({ trackSourceChange, trackExportFeedback }: Props) => {
  const {
    selectedOptions: feedbackListToRemove,
    selectOption: selectFeedbackToRemove,
    isChecked: isFeedbackSelectedToRemove,
    setSelectedOptions: setFeedbackListToRemove
  } = useStringSelection()

  const { queryParams } = useFeedQueryParams()

  const { t } = useTranslation()

  // biome-ignore lint/correctness/useExhaustiveDependencies: it should clear the feedback list when the query params change
  useEffect(() => {
    setFeedbackListToRemove([])
  }, [queryParams])

  const [opportunityPlanParams] = useLocalStorage(
    OPPORTUNITY_PLAN_KEY,
    defaultOpportunityPlanFormData
  )

  const { enableBatchSelection } = opportunityPlanParams

  const {
    feedbackList,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    error,
    selectedFeedback,
    setSelectedFeedback,
    currentSource,
    setCurrentSource
  } = useFeedbackSearch()

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)
  const currentInitiative = useInitiativeStore(state => state.currentInitiative, shallow)

  const { pinnedFeedback } = usePinnedFeedback(currentOpportunity?.id)

  const { exportFeedbackWithArea } = useFeedbackExport()

  const [messagesOpen, setMessagesOpen] = useState<NewFeedback | null>(null)

  function openMessagesModal(feedback: NewFeedback) {
    // TODO: track
    setMessagesOpen(feedback)
  }

  function onMessagesModalClose() {
    setMessagesOpen(null)
  }

  const { setFeedbackToRemove, feedbackToRemove, pushRemovingFeedback, setRemovingFeedbacks } =
    useRemoveOpportunityFeedbackStore()

  const { removeFeedbackFromOpportunity } = useFeedbackMutations()

  const { track } = useSegment()
  const onConfirmDelete = () => {
    if (!feedbackToRemove) return

    setFeedbackToRemove(null)
    pushRemovingFeedback(feedbackToRemove.id)
    removeFeedbackFromOpportunity(
      {
        feedbackIdList: [feedbackToRemove.id],
        opportunityId: currentOpportunity?.id ?? ''
      },
      {
        onSuccess: () => {
          track('opportunity_inaccurate_feedback')
        }
      }
    )
  }

  const onClickSource = (source: SourceDistributionItem | undefined) => {
    trackSourceChange(source?.key)
    setCurrentSource(prevSources => {
      if (!source) return []

      if (prevSources.find(prevSource => prevSource.key === source.key)) {
        return prevSources.filter(prevSource => prevSource.key !== source.key)
      }
      return [...prevSources, source]
    })
  }

  const title = t('whatArePeopleSaying')

  const onSelectFeedbackChange = (checked: CheckedState, feedbackId: string) => {
    if (!currentOpportunity) return
    selectFeedbackToRemove(checked, feedbackId)
  }

  const isAllChecked = useMemo(
    () => feedbackList.every(feedback => feedbackListToRemove.includes(feedback.id)),
    [feedbackList, feedbackListToRemove]
  )

  const selectAll = () => {
    if (isAllChecked) {
      setFeedbackListToRemove([])
      return
    }
    setFeedbackListToRemove(feedbackList.map(feedback => feedback.id))
  }

  const onConfirmBatchRemove = () => {
    if (!currentOpportunity) return
    setFeedbackListToRemove([])
    setRemovingFeedbacks(feedbackListToRemove)
    removeFeedbackFromOpportunity({
      feedbackIdList: feedbackListToRemove,
      opportunityId: currentOpportunity.id
    })
  }

  const onClickExportFeedback = () => {
    trackExportFeedback()
    exportFeedbackWithArea()
  }

  const showPin = Boolean(currentInitiative) || Boolean(currentOpportunity)

  const { pinFeedback, unpinFeedback } = useOpportunityMutations()
  const onPinFeedback = (isPinned: boolean, feedback: NewFeedback) => {
    if (!currentOpportunity) return
    if (isPinned) {
      pinFeedback({ feedbackList: [feedback], opportunityId: currentOpportunity.id })
    } else {
      unpinFeedback({ feedbackId: feedback.id, opportunityId: currentOpportunity.id })
    }
  }

  const isFeedbackPinned = (feedback: NewFeedback) => {
    if (!currentOpportunity) return false
    return pinnedFeedback.some(pinnedFeedback => pinnedFeedback.id === feedback.id)
  }

  return (
    <>
      <Card.Root
        color="brandPrimaryPure"
        direction="column"
        gap="xxs"
        id="list-root-exploration-feedback"
      >
        <FlexContainer alignItems="center" fullWidth justifyContent="spaceBetween">
          <Card.Header>
            <Card.IconBox>
              <ChatText />
            </Card.IconBox>
            <Card.Title>{title}</Card.Title>
          </Card.Header>

          <Tooltip side="bottom" text={t('exportFeedback')} variant="small">
            <Button onClick={onClickExportFeedback} size="square" variant="white-bordered">
              <Export />
            </Button>
          </Tooltip>
        </FlexContainer>

        <FeedbackSourcesDistribution currentSource={currentSource} onClickSource={onClickSource} />

        {isLoading && !feedbackList.length && <ScrollLoader />}

        {!!error && !feedbackList.length && <FeedError />}

        {!isLoading && !error && !feedbackList.length && <NoResults />}

        <InfiniteScroll
          dataLength={feedbackList.length}
          endMessage={<FlexContainer css={{ mb: 96 }} />}
          hasMore={hasNextPage ?? false}
          loader={isFetchingNextPage ? <ScrollLoader /> : ''}
          next={fetchNextPage}
          scrollThreshold="250px"
          scrollableTarget="scroll-analytics"
        >
          <FlexContainer css={{ mb: '$xxxs' }} direction="column" gap="xxs">
            {pinnedFeedback.length > 0 && (
              <TextDivider>{t('pinnedFeedbackDividerLabel')}</TextDivider>
            )}
            {pinnedFeedback.map(item => (
              <FeedbackItem
                key={item.id + '-pinned'}
                {...item}
                canSelect={enableBatchSelection}
                showPin={showPin}
                css={{
                  '&:first-of-type': {
                    mt: '-$sm'
                  }
                }}
                isPinned
                onPinToggle={isPinned => onPinFeedback(isPinned, item)}
                isOpportunityFeedback={!!currentOpportunity}
                onMessagesClick={() => openMessagesModal(item)}
                onRemoveFeedbackFromOpportunity={() => setFeedbackToRemove(item)}
                onSelectFeedback={() => setSelectedFeedback(item)}
                onSelectedChange={selected => onSelectFeedbackChange(selected, item.id)}
                selected={isFeedbackSelectedToRemove(item.id)}
              />
            ))}
            {pinnedFeedback.length > 0 && (
              <TextDivider css={{ my: '$nano' }}>{t('allFeedbackDividerLabel')}</TextDivider>
            )}
            {feedbackList.map(item => (
              <FeedbackItem
                key={item.id}
                {...item}
                canSelect={enableBatchSelection}
                css={{
                  '&:first-of-type': {
                    mt: '$xxs'
                  }
                }}
                showPin={showPin}
                isPinned={isFeedbackPinned(item)}
                onPinToggle={isPinned => onPinFeedback(isPinned, item)}
                isOpportunityFeedback={!!currentOpportunity}
                onMessagesClick={() => openMessagesModal(item)}
                onRemoveFeedbackFromOpportunity={() => setFeedbackToRemove(item)}
                onSelectFeedback={() => setSelectedFeedback(item)}
                onSelectedChange={selected => onSelectFeedbackChange(selected, item.id)}
                selected={isFeedbackSelectedToRemove(item.id)}
              />
            ))}
          </FlexContainer>
        </InfiniteScroll>
      </Card.Root>

      <Suspense>
        <FeedbackDetails feedback={selectedFeedback} onClose={() => setSelectedFeedback(null)} />
      </Suspense>

      <Suspense>
        {messagesOpen && (
          <FeedbackConversation feedback={messagesOpen} onOpenChange={onMessagesModalClose} />
        )}
      </Suspense>

      {!!feedbackToRemove && (
        <DeleteDialog
          cancelText={t('cancel')}
          confirmText={t('remove')}
          description={t(
            'youveMarkedThisFeedbackAsInaccurateForTheOpportunityWellRemoveItAndRecalculateTheDataThisActionCantBeUndoneProceedWithCaution'
          )}
          isDeleting={false}
          onConfirmDelete={onConfirmDelete}
          onOpenChange={() => setFeedbackToRemove(null)}
          open={!!feedbackToRemove}
          title={t('weAreGoingToRemoveInaccurateFeedback')}
        />
      )}

      {enableBatchSelection && (
        <FeedbackActionBar
          isChecked={isAllChecked}
          onClearSelection={() => setFeedbackListToRemove([])}
          onConfirm={onConfirmBatchRemove}
          onSelectAll={selectAll}
          selectedFeedback={feedbackListToRemove}
          total={feedbackList.length}
        />
      )}
    </>
  )
}

export default FeedbackListExploration
