import { useCallback, useMemo } from 'react'
import useMetricTimeSeriesQuery from './useMetricTimeSeriesQuery'
import {
  BreakdownOption,
  TimeSeriesGroup,
  TimeSeriesIntervalOption
} from '@/types/time-series/TimeSeries'
import { allMetricItems } from '@/utils/metrics'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import moment from 'moment'
import { FilterItem } from '@/types/filters/Filters'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useAdvancedFiltersStore from '@/store/useFiltersStore/useAdvancedFiltersStore'
import { AllMetricsKey } from '@/types/metrics'
import useCollections from '@/hooks/collections/useCollections'
import useAreasAndOpportunitiesState from '@/store/useHomeStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import useAdvancedAreasFiltersCombined from '@/hooks/areaOfInterest/useAdvancedAreasFiltersCombined'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import useCompetitorsModeStore from '@/store/useCompetitorsModeStore'
import useBaseMetricFilters from '@/hooks/feedback/new/useBaseMetricsFilters'
import useOpportunitiesSortedByMetric from '@/hooks/opportunity/useOpportunitiesSortedByMetric'
import useOpportunitiesContexts from '@/hooks/opportunity/useOpportunitiesContexts'

const npsSatisfactionNames = ['Detractors', 'Neutrals', 'Promoters']
const reviewCsatSatisfactionNames = ['Unsatisfied', 'Neutrals', 'Satisfied']

interface Params {
  interval: TimeSeriesIntervalOption
  metric: AllMetricsKey
  breakdown?: BreakdownOption
  selectedField?: FilterItem | null
  useAppliedFilters?: boolean
  tableBreakdown?: BreakdownOption
  enabled?: boolean
}

const useMetricsTrendline = ({
  interval,
  metric,
  breakdown = 'org',
  selectedField = null,
  useAppliedFilters = false,
  tableBreakdown,
  enabled = true
}: Params) => {
  const isFetchingContext = useAdvancedFiltersStore(state => state.isFetchingContext)

  const currentAreaOfInterest = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const searchText = useAreasAndOpportunitiesState(state => state.searchText)

  const filterAreaOppsByText = useMemo(() => {
    const allowedBreakdowns: BreakdownOption[] = ['areas', 'opportunity']
    if (
      allowedBreakdowns.includes(breakdown) ||
      (tableBreakdown && allowedBreakdowns.includes(tableBreakdown))
    ) {
      if (!searchText.length) return undefined
      return searchText
    }

    return undefined
  }, [breakdown, tableBreakdown, searchText])

  const { currentCollection } = useCollections()

  const { areas: advancedAreas, isLoading: isLoadingAreas } = useBasicAreaOfInterestQuery({
    enabled: breakdown === 'areas' || tableBreakdown === 'areas',
    collectionId: currentCollection?.collectionId
  })

  const filteredAreas = useMemo(() => {
    return advancedAreas.filter(area => {
      const byName = filterAreaOppsByText
        ? area.name.toLowerCase().includes(filterAreaOppsByText.trim().toLowerCase())
        : true

      return byName
    })
  }, [advancedAreas, filterAreaOppsByText])

  const { data: combinedAreasContexts, isLoading: isLoadingAreaContexts } =
    useAdvancedAreasFiltersCombined({
      areas: filteredAreas,
      enabled: useAppliedFilters && (breakdown === 'areas' || tableBreakdown === 'areas')
    })

  const { competitorsModeEnabled } = useCompetitorsModeStore(state => ({
    competitorsModeEnabled: state.competitorsModeEnabled
  }))

  const { data: baseFiltersData, isLoading: isLoadingBaseQueryParams } = useBaseMetricFilters({
    useAppliedFilters,
    useReviewCompetitors: competitorsModeEnabled
  })

  const baseQueryParams = useMemo(() => {
    return baseFiltersData as FeedbackListQueryParams | undefined
  }, [baseFiltersData])

  const areaFilters = useMemo(() => {
    if (useAppliedFilters && combinedAreasContexts) {
      return combinedAreasContexts.map(areaContext => ({
        context: areaContext.context
      }))
    }

    return filteredAreas.map(area => ({
      context: area.context
    })) as FeedbackListQueryParams[]
  }, [combinedAreasContexts, useAppliedFilters, filteredAreas])

  const isOpportunityBreakdown = breakdown === 'opportunity' || tableBreakdown === 'opportunity'

  const { opportunities, isLoading: isOpportunitiesLoading } = useOpportunitiesSortedByMetric({
    enabled: isOpportunityBreakdown,
    areaId: currentAreaOfInterest?.id,
    collectionId: currentCollection?.collectionId,
    searchText: filterAreaOppsByText,
    limit: 200,
    onlyOngoing: true,
    sortDirection: 'desc',
    metric,
    useAppliedFilters,
    useReviewCompetitors: competitorsModeEnabled
  })

  const { opportunitiesWithContext, isLoading: isOppsContextLoading } = useOpportunitiesContexts({
    enabled: isOpportunityBreakdown && !useAppliedFilters,
    opportunities: opportunities,
    areasId: currentAreaOfInterest && [currentAreaOfInterest.id],
    collectionId: currentCollection?.collectionId
  })

  const opportunitiesFilters = useMemo(() => {
    return opportunitiesWithContext.map(opportunity => {
      const oppParams = {
        ...(useAppliedFilters ? baseQueryParams : {}),
        context: useAppliedFilters ? baseQueryParams?.context : opportunity.mergedContext,
        opportunity_id: opportunity.id
      } as FeedbackListQueryParams

      return oppParams
    }) as FeedbackListQueryParams[]
  }, [opportunitiesWithContext, useAppliedFilters, baseQueryParams])

  const getGroup = useCallback(
    (breakdownOption: BreakdownOption) => {
      if (
        [
          'org',
          'area',
          'areas',
          'opportunity',
          'all',
          'satisfaction',
          'satisfaction_by_nps',
          'satisfaction_by_csat',
          'satisfaction_by_review',
          'date'
        ].includes(breakdownOption)
      ) {
        return
      }

      if (breakdownOption === 'account') {
        return 'account.' + selectedField?.name
      }

      if (breakdownOption === 'user') {
        return 'user.' + selectedField?.name
      }

      if (['customField', 'feedbackDetail'].includes(breakdownOption)) {
        return selectedField?.name
      }

      return breakdownOption
    },
    [selectedField]
  )

  const currentBreakdown = useMemo(() => {
    // is transposed with a date breakdown
    if (breakdown === 'date' && tableBreakdown) return tableBreakdown

    return breakdown
  }, [breakdown, tableBreakdown])

  const group = useMemo(() => {
    return getGroup(currentBreakdown)
  }, [currentBreakdown, getGroup])

  const npsSatisfactionFilters = useMemo(() => {
    const detractors: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'nps.rating.lte': '6'
    }
    const neutrals: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'nps.rating.gte': '7',
      'nps.rating.lte': '8'
    }

    const promoters: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'nps.rating.gte': '9'
    }

    return [detractors, neutrals, promoters]
  }, [baseQueryParams])

  const csatSatisfactionFilters = useMemo(() => {
    const unsatisfied: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'csat.rating.lte': '2'
    }
    const neutrals: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'csat.rating.gte': '3',
      'csat.rating.lte': '3'
    }

    const satisfied: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'csat.rating.gte': '4'
    }

    return [unsatisfied, neutrals, satisfied]
  }, [baseQueryParams])

  const reviewSatisfactionFilters = useMemo(() => {
    const unsatisfied: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'review.rating.lte': '2'
    }
    const neutrals: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'review.rating.gte': '3',
      'review.rating.lte': '3'
    }

    const satisfied: FeedbackListQueryParams & Record<string, ParamValue | number> = {
      ...baseQueryParams,
      'review.rating.gte': '4'
    }

    return [unsatisfied, neutrals, satisfied]
  }, [baseQueryParams])

  const filters = useMemo(() => {
    if (currentBreakdown === 'areas') return areaFilters
    if (currentBreakdown === 'opportunity') return opportunitiesFilters
    if (currentBreakdown === 'satisfaction') return npsSatisfactionFilters
    if (currentBreakdown === 'satisfaction_by_nps') return npsSatisfactionFilters
    if (currentBreakdown === 'satisfaction_by_csat') return csatSatisfactionFilters
    if (currentBreakdown === 'satisfaction_by_review') return reviewSatisfactionFilters

    return [baseQueryParams].filter(Boolean) as FeedbackListQueryParams[]
  }, [
    currentBreakdown,
    areaFilters,
    opportunitiesFilters,
    npsSatisfactionFilters,
    reviewSatisfactionFilters,
    csatSatisfactionFilters,
    baseQueryParams
  ])

  const isEnabled = useMemo(() => {
    return enabled && filters.length > 0
  }, [enabled, filters.length])

  const { data, isLoading: isLoadingData } = useMetricTimeSeriesQuery({
    filters,
    interval,
    group,
    metric,
    breakdown: currentBreakdown,
    enabled: isEnabled
  })

  const timeSeries = useMemo(() => {
    const timeseriesMetric = allMetricItems[metric]
    if (!timeseriesMetric) return []

    if (currentBreakdown === 'areas') {
      return (
        data
          ?.map(([timeSeriesGroup], index): TimeSeriesGroup => {
            return {
              name: filteredAreas[index].name ?? `Area ${index + 1}`,
              values: timeSeriesGroup.values.map(value => timeseriesMetric.numberTransformer(value))
            }
          })
          .filter(serie => serie.values.some(value => value !== 0)) ?? []
      )
    }

    if (currentBreakdown === 'opportunity') {
      return (
        data
          ?.map(
            ([timeSeriesGroup], index): TimeSeriesGroup => ({
              name: opportunities[index].name ?? `Opportunity ${index + 1}`,
              values: timeSeriesGroup.values.map(value => timeseriesMetric.numberTransformer(value))
            })
          )
          .filter(serie => serie.values.some(value => value !== 0)) ?? []
      )
    }

    if (currentBreakdown === 'satisfaction') {
      return (
        data?.map(
          ([timeSeriesGroup], index): TimeSeriesGroup => ({
            name: npsSatisfactionNames[index],
            values: timeSeriesGroup.values.map(value => timeseriesMetric.numberTransformer(value))
          })
        ) ?? []
      )
    }
    if (currentBreakdown === 'satisfaction_by_nps') {
      return (
        data?.map(
          ([timeSeriesGroup], index): TimeSeriesGroup => ({
            name: npsSatisfactionNames[index],
            values: timeSeriesGroup.values.map(value => timeseriesMetric.numberTransformer(value))
          })
        ) ?? []
      )
    }
    if (currentBreakdown === 'satisfaction_by_csat') {
      return (
        data?.map(
          ([timeSeriesGroup], index): TimeSeriesGroup => ({
            name: reviewCsatSatisfactionNames[index],
            values: timeSeriesGroup.values.map(value => timeseriesMetric.numberTransformer(value))
          })
        ) ?? []
      )
    }
    if (currentBreakdown === 'satisfaction_by_review') {
      return (
        data?.map(
          ([timeSeriesGroup], index): TimeSeriesGroup => ({
            name: reviewCsatSatisfactionNames[index],
            values: timeSeriesGroup.values.map(value => timeseriesMetric.numberTransformer(value))
          })
        ) ?? []
      )
    }

    if (currentBreakdown === 'all' || currentBreakdown === 'org') {
      let name = 'Organization'
      if (currentBreakdown === 'all') {
        if (currentOpportunity) {
          name = currentOpportunity.name
        } else if (currentAreaOfInterest) {
          name = currentAreaOfInterest.name
        }
      }
      return (
        data?.map(
          ([timeSeriesGroup]): TimeSeriesGroup => ({
            name,
            values: timeSeriesGroup.values.map(value => timeseriesMetric.numberTransformer(value))
          })
        ) ?? []
      ).filter(serie => serie.values.some(value => value !== 0))
    }

    if (group) {
      // const groupData = data?.[0]?.[0]?.group[group]
      const groupData = Object.values(data?.[0]?.[0]?.group ?? {})[0]
      if (!groupData) return []

      // const sortedGroupData = groupData.sort((a, b) => a.name.localeCompare(b.name))

      return groupData
        .map(
          (item): TimeSeriesGroup => ({
            name: currentBreakdown === 'kind' ? getFeedbackKindPrettyName(item.name) : item.name,
            values: item.values.map(value => timeseriesMetric.numberTransformer(value))
          })
        )
        .filter(serie => serie.values.some(value => value !== 0))
    }

    return []
  }, [
    metric,
    currentBreakdown,
    data,
    filteredAreas,
    opportunities,
    currentAreaOfInterest,
    currentOpportunity,
    group
  ])

  const timestamps = useMemo(
    () => data?.[0]?.[0]?.timestamps.map(timestamp => moment.unix(timestamp).utc()) ?? [],
    [data]
  )

  const isLoading = useMemo(() => {
    if (!enabled) return false

    const baseIsLoading = isFetchingContext || isLoadingData || isLoadingBaseQueryParams

    if (currentBreakdown === 'areas') {
      return baseIsLoading || isLoadingAreaContexts || isLoadingAreas
    }

    if (currentBreakdown === 'opportunity') {
      return baseIsLoading || isOpportunitiesLoading || isOppsContextLoading || isLoadingAreas
    }

    return baseIsLoading
  }, [
    isOpportunitiesLoading,
    isOppsContextLoading,
    enabled,
    currentBreakdown,
    isFetchingContext,
    isLoadingAreaContexts,
    isLoadingAreas,
    isLoadingData,
    isLoadingBaseQueryParams
  ])

  return {
    timestamps,
    timeSeries,
    isLoading,
    isLoadingData: isEnabled ? isLoadingData : false
  }
}

export default useMetricsTrendline
