import { BaseInterestArea } from '../area/AreaOfInterest'
import { AdvancedFilterContent } from '../filters/AdvancedFilters'
import { RawMetric } from '../metrics'

export enum OpportunityStatus {
  Draft = 'e7cc50cf-2da4-4ff6-aa27-ec1492f33b1e',
  Processing = '1e7681e2-ff85-42af-a702-1714894a3773',
  Quantified = 'd8f6d0f6-da3b-4eaa-87d3-a8397fecba61',
  Refined = '31a736be-e671-4d1c-98e5-c1098222e254',
  Prioritized = 'e5ec1179-8fca-4812-a627-1b6c77d5212d',
  Tracking = '16744a83-8014-45a1-bdbb-669b10c944b5',
  Dismissed = '1cd9c71c-3c7e-4a5f-99c0-f2ca6d8ce64b'
}

export interface OpportunityItem {
  name: string
  id: string
  filterId: string
  status: OpportunityStatus
  description?: string
  createdAt?: Date
  new: boolean
  createdBy: string | undefined
  relations: string[]
  initiatives: string[]
  pinnedFeedback: string[]
}

export interface OpportunityItemWithMetrics extends OpportunityItem {
  metrics: RawMetric[]
  opportunityCount: 0
  area?: BaseInterestArea
}

export interface OpportunityItemWithMergedContext extends OpportunityItem {
  mergedContext?: string
  content?: { filter: AdvancedFilterContent }
  contextBy?: 'collection-areas' | 'area' | 'all'
}
