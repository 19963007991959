import { useMemo, useState } from 'react'
import useNumberFiltersQuery from '../filters/useNumberFiltersQuery'
import useAdvancedFilters from './useAdvancedFilters'
import { NumericRangeOption } from '@/types/filters/Filters'
import {
  NumberFilterType,
  NumberFilterValue,
  NumericFilterOption
} from '@/types/filters/AdvancedFilters'
import {
  CustomerRecordsNumberFieldValue,
  CustomerRecordType
} from '@/types/manage-customers/CustomerRecordsFilters'
import useCustomerRecordsFilterQuery from '@/components/molecules/filters/FiltersSidebar/CustomerRecordsFilter/common/useCustomerRecordsFilterQuery'
import { prefixFilterName } from '@/utils/filters'

type NumberFilterRange = [number, number] | null

const getInitialRangeState = (initialValue: NumberFilterValue | null) => {
  return (
    initialValue ? [initialValue.$eq ?? initialValue.$gte, initialValue.$lte] : null
  ) as NumberFilterRange
}

const getInitialOptionState = (initialOption: NumericFilterOption): NumericRangeOption => {
  if (initialOption === 'empty') return 'null'
  if (initialOption === 'matches') return 'is'
  if (initialOption === 'range') return 'between'
  return 'all'
}

interface Params {
  name: string
  path: string
  enableQuery?: boolean
  initialValue?: NumberFilterValue | null
  initialOption?: NumericFilterOption
  type?: NumberFilterType
  index?: number
}

const useNumberAdvancedFilter = ({
  name,
  path,
  enableQuery,
  initialValue = null,
  initialOption = 'range',
  type = 'number',
  index
}: Params) => {
  const isCustomerRecord = type !== 'number'
  const { data, isLoading } = useNumberFiltersQuery({
    name,
    enabled: Boolean(enableQuery && !isCustomerRecord)
  })

  const recordType: CustomerRecordType = type === 'accounts-number' ? 'accounts' : 'customerUsers'
  const { data: customerData, isLoading: isLoadingCustomer } = useCustomerRecordsFilterQuery[
    recordType
  ]({
    filterKey: name,
    enabled: Boolean(enableQuery && isCustomerRecord)
  })

  const { min: customerMin, max: customerMax } = useMemo(() => {
    const defaultRange = { min: 0, max: 10 }
    if (!customerData) return defaultRange
    const [error, result] = customerData
    if (error) return defaultRange

    if (result.type === 'number') {
      const resultFilter = result as CustomerRecordsNumberFieldValue
      return { min: resultFilter.min, max: resultFilter.max }
    }
    return defaultRange
  }, [customerData])

  const min = useMemo(
    () => (isCustomerRecord ? customerMin : data?.min ?? 0),
    [data, customerMin, isCustomerRecord]
  )
  const max = useMemo(
    () => (isCustomerRecord ? customerMax : data?.max ?? 10),
    [data, customerMax, isCustomerRecord]
  )

  const { addFilter, removeFilter, updateFilter } = useAdvancedFilters()
  const [numberRange, setNumberRange] = useState<NumberFilterRange>(
    getInitialRangeState(initialValue)
  )
  const [selectedOption, setSelectedOption] = useState<NumericRangeOption>(
    getInitialOptionState(initialOption)
  )

  const onOptionChange = (value: string) => {
    const option = value as NumericRangeOption
    setSelectedOption(option)

    if (option === 'null' || option === 'all') {
      setNumberRange(null)
      return
    }

    if (option === 'is') {
      const range = numberRange ?? [min, max]
      setNumberRange(range)
      return
    }

    let range = numberRange ?? [min, max]
    if (range[0] === range[1]) {
      const value = range[0] === max ? max - 1 : range[0]
      range = [value, value + 1]
    }
    setNumberRange(range)
  }

  const rangeValue = numberRange ?? [min, max]
  const exactValue = numberRange?.[0] ?? min

  const onRangeChange = (range: [number, number]) => {
    setNumberRange(range)
  }

  const onValueChange = (range: [number]) => {
    const [value] = range
    setNumberRange([value, value])
  }

  let prefix = ''
  if (type === 'accounts-number') {
    prefix = 'account.'
  } else if (type === 'users-number') {
    prefix = 'user.'
  }

  const apply = () => {
    if (selectedOption === 'all') return

    let option: NumericFilterOption
    let value: NumberFilterValue | null

    if (selectedOption === 'null') {
      option = 'empty'
      value = null
    } else if (selectedOption === 'is') {
      option = 'matches'
      value = { $eq: exactValue }
    } else {
      option = 'range'
      value = { $gte: rangeValue[0], $lte: rangeValue[1] }
    }

    addFilter({
      name: prefixFilterName(prefix, name),
      type,
      value,
      option,
      path,
      status: 'valid'
    })
  }

  const update = () => {
    if (index === undefined) return
    if (selectedOption === 'all') {
      removeFilter({ index })
      return
    }

    let option: NumericFilterOption
    let value: NumberFilterValue | null

    if (selectedOption === 'null') {
      option = 'empty'
      value = null
    } else if (selectedOption === 'is') {
      option = 'matches'
      value = { $eq: exactValue }
    } else {
      option = 'range'
      value = { $gte: rangeValue[0], $lte: rangeValue[1] }
    }

    updateFilter({
      index,
      filter: {
        name: prefixFilterName(prefix, name),
        type,
        value,
        option,
        path,
        status: 'valid'
      }
    })
  }

  const isRangeOpen = selectedOption === 'between'
  const isExactValueOpen = selectedOption === 'is'

  const rangeText = `${rangeValue[0]} - ${rangeValue[1]}`
  const exactValueText = exactValue.toString()

  const loading = (!isCustomerRecord && isLoading) || (isCustomerRecord && isLoadingCustomer)

  return {
    rangeValue,
    exactValue: [exactValue] as [number],
    selectedOption,
    isRangeOpen,
    isExactValueOpen,
    rangeText,
    exactValueText,
    min,
    max,
    isLoading: loading,
    onOptionChange,
    onRangeChange,
    onValueChange,
    apply,
    update
  }
}

export default useNumberAdvancedFilter
