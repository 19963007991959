import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import { DialogTitleDefault } from '@/components/atoms/dialog/Dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Text from '@/components/atoms/text'
import { CollectionType } from '@/types/collection'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  type: CollectionType
  filterIds: string[]
  open: boolean
  onOpenChange: (open: boolean) => void
  onSave: (name: string, filterIds: string[]) => void
}

const SaveCollectionDialog = ({ type, filterIds, open, onOpenChange, onSave }: Props) => {
  const [name, setName] = useState('')
  const { t } = useTranslation()

  const title = type === 'area_interest' ? t('saveAreaCollection') : t('saveOpportunityCollection')

  const onSaveClick = () => {
    onSave(name, filterIds)
    onOpenChange(false)
    setName('')
  }

  return (
    <Dialog align="center" closeIcon modal onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <DialogTitleDefault>{title}</DialogTitleDefault>
        <FlexContainer direction="column" gap="xxs">
          <Text as="p" fontSize="xxxs">
            {t('collectionName')}
          </Text>
          <Input onChange={e => setName(e.currentTarget.value)} small value={name} />
        </FlexContainer>
        <Divider line="solid" orientation="horizontal" />
        <Button fullWidth onClick={onSaveClick} size="small">
          {t('save')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default SaveCollectionDialog
