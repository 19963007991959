import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import Dialog from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from '../search'
import Button from '@/components/atoms/button'
import { AreaList, AreasSkeleton } from './ModalComponents'
import { OpportunityItem, OpportunityItemWithMetrics } from '@/types/opportunity/Opportunity'
import { DialogTitleDefault } from '@/components/atoms/dialog/Dialog'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  areas: BaseInterestArea[]
  opportunity: OpportunityItem | OpportunityItemWithMetrics
  isLoading?: boolean
  onAddToAreas: (areaIds: string[]) => void
}

export const AssociateWithAreasModal = ({
  open,
  onOpenChange,
  areas,
  opportunity,
  isLoading,
  onAddToAreas
}: Props) => {
  const { t } = useTranslation()

  const [search, setSearch] = useState('')
  const filteredAreas = useMemo(() => {
    if (!search) return areas
    return areas.filter(area => area.name.toLowerCase().includes(search.toLowerCase()))
  }, [areas, search])

  const [checkedAreas, setCheckedAreas] = useState<string[]>(opportunity.relations ?? [])
  const onCheckedChange = (checkedState: CheckboxState, id: string) => {
    if (checkedState) {
      setCheckedAreas([...checkedAreas, id])
    } else {
      setCheckedAreas(checkedAreas.filter(checkboxId => checkboxId !== id))
    }
  }
  const isChecked = (id: string) => checkedAreas.includes(id)

  const isButtonDisabled = checkedAreas.length === 0
  const onAddToAreasClick = useCallback(() => {
    onAddToAreas(checkedAreas)
    onOpenChange(false)
  }, [checkedAreas, onAddToAreas, onOpenChange])

  return (
    <Dialog align="center" closeIcon modal onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <DialogTitleDefault>{t('associateOpportunityWithMultipleAreas')}</DialogTitleDefault>
        <Search
          css={{ py: '$micro' }}
          onChange={e => setSearch(e.currentTarget.value)}
          onKeyDown={e => {
            e.stopPropagation()
          }}
          size="small"
          small
          value={search}
          placeholder="Search areas"
        />
        <AreaList css={{ pl: '$nano' }}>
          {isLoading && <AreasSkeleton />}
          {filteredAreas.map(area => (
            <Checkbox
              checked={isChecked(area.id)}
              key={area.id}
              onCheckedChange={checkedState => onCheckedChange(checkedState, area.id)}
              text={area.name}
              value={`area-${area.id}`}
            />
          ))}
        </AreaList>
        <Button disabled={isButtonDisabled} onClick={onAddToAreasClick} size="small" fullWidth>
          {t('apply')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}
