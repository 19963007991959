import { useEffect, useRef, useState } from 'react'
import { TextEditorContainer, TextEditorContent } from './TextEditor.styles'
import { useEditor } from '@tiptap/react'
import defaultExtensions from './extensions'
import Button from '@/components/atoms/button'
import TextEditor from './TextEditor'
import { useTranslation } from 'react-i18next'

interface CollapsableEditableTextProps {
  content: string
  onSave: (content: string) => void
  onCancel?: () => void
  editable?: boolean
  onEditableChange?: (editable: boolean) => void
}

const CollapsableEditableText = ({
  content,
  onSave,
  onCancel,
  editable = false,
  onEditableChange
}: CollapsableEditableTextProps) => {
  const [collapsed, setCollapsed] = useState(true)

  const { t } = useTranslation()

  const editor = useEditor({ extensions: defaultExtensions, content, editable: false })
  // useEffect(() => {
  //   if (!editor) return
  //   editor.commands.setContent(content)
  // }, [content])

  const ref = useRef<HTMLDivElement>(null)
  const [isCollapsable, setIsCollapsable] = useState(false)

  // biome-ignore lint/correctness/useExhaustiveDependencies: should react to content and editor
  useEffect(() => {
    const currentEl = ref.current
    if (!currentEl) return

    const textElement = currentEl.querySelector('.ProseMirror')
    if (!textElement) return

    const scrollHeight = textElement.scrollHeight
    const clientHeight = textElement.clientHeight

    setIsCollapsable(scrollHeight !== clientHeight || scrollHeight > 48)
  }, [content, editor])

  const toggleCollapsed = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    setCollapsed(prev => !prev)
  }

  const onSaveHandler = (content: string) => {
    onSave(content)
    onEditableChange?.(false)

    editor?.commands.setContent(content)
  }

  const onCancelHandler = () => {
    onCancel?.()
    onEditableChange?.(false)
  }

  if (!editable) {
    return (
      <TextEditorContainer collapsed={collapsed} direction="column" editable={false}>
        <TextEditorContent editable={false} editor={editor} ref={ref} />
        {isCollapsable && (
          <Button
            css={{ textDecoration: 'underline', color: '$brandPrimaryMedium', mt: -8, pl: 0 }}
            onClick={toggleCollapsed}
            variant="link"
          >
            {collapsed ? t('showMore') : t('showLess')}
          </Button>
        )}
      </TextEditorContainer>
    )
  }

  return <TextEditor initialContent={content} onCancel={onCancelHandler} onSave={onSaveHandler} />
}

export default CollapsableEditableText
