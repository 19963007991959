import Dialog from '@/components/atoms/dialog'
import { DialogTitleDefault } from '@/components/atoms/dialog/Dialog'
import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'
import RelatedInitiativeRow from './RelatedInitiativeRow'
import Button from '@/components/atoms/button'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import { useMemo } from 'react'

interface Props {
  opportunity: OpportunityItem
  open: boolean
  onOpenChange: (open: boolean) => void
  onAddInitiativeClick: () => void
}

const RelatedInitiativesModal = ({
  opportunity,
  open,
  onOpenChange,
  onAddInitiativeClick
}: Props) => {
  const { t } = useTranslation()

  const { initiatives: allInitiatives } = useInitiatives()
  const opportunityInitiatives = useMemo(() => {
    if (!allInitiatives) return []
    return allInitiatives.filter(initiative => opportunity.initiatives.includes(initiative.id))
  }, [opportunity, allInitiatives])

  return (
    <Dialog align="center" closeIcon modal onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <DialogTitleDefault>{t('initiatives')}</DialogTitleDefault>
        {opportunityInitiatives.map(initiative => (
          <RelatedInitiativeRow key={initiative.id} initiative={initiative} />
        ))}
        <FlexContainer justifyContent="flexEnd">
          <Button size="small" variant="flat" onClick={onAddInitiativeClick}>
            {t('addInitiative')}
          </Button>
        </FlexContainer>
      </FlexContainer>
    </Dialog>
  )
}

export default RelatedInitiativesModal
