import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useUser from '@/hooks/useUser'
import { CSS, styled } from '@/theme'
import { UserWithInviter } from '@/types/auth'
import { MouseEventHandler, useMemo } from 'react'

const FirstLetterIndicator = styled(FlexContainer, {
  color: '$neutralHighLight',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '$micro',
  br: 38,

  variants: {
    small: {
      true: {
        absoluteSize: 16,
        fontWeight: '$bold',
        fontSize: '$nano'
      },
      false: {
        absoluteSize: 24,
        fontSize: '$xxxs'
      }
    }
  },

  defaultVariants: {
    small: false
  }
})

interface Props {
  userId?: string
  onClick?: MouseEventHandler<HTMLDivElement>
  css?: CSS
  smallIcon?: boolean
}

export const UserIcon = ({ user, small = false }: { user: UserWithInviter; small?: boolean }) => {
  const { usersColors } = useUser()

  return (
    <FirstLetterIndicator css={{ bc: usersColors[user.user_id] }} small={small}>
      {user.name.at(0)?.toUpperCase()}
    </FirstLetterIndicator>
  )
}

const UserCell = ({ userId, onClick, css, smallIcon }: Props) => {
  const { getUserById } = useUser()
  const user = useMemo(() => (userId ? getUserById(userId) : undefined), [userId, getUserById])

  if (!user) return <></>

  return (
    <FlexContainer gap="micro" css={css} onClick={onClick} alignItems="center">
      <UserIcon user={user} small={smallIcon} />
      <Text color="neutralLowPure" fontSize={smallIcon ? 'xxxs' : 'micro'}>
        {user.name}
      </Text>
    </FlexContainer>
  )
}

export default UserCell
