import {
  FeedbackDate,
  FeedbackHeader,
  FeedbackRating as FeedbackRatingContainer,
  FeedbackType,
  Group
} from '@/components/molecules/feedback-item/FeedbackItem.styles'
import { FeedbackItemProps } from './FeedbackItem.types'
import { dateShortMonthFormat } from '@/utils/date'
import { FeedbackKindName, ReviewFields } from '@/types/feedbacks/FeedbackKindFields'
import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import { ListDashes, ThumbsDown, Star, PushPin } from '@phosphor-icons/react'
import { colors } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import useUser from '@/hooks/useUser'
import IconButton from '@/components/atoms/icon-button'

const SATISFACTION_KINDS: FeedbackKindName[] = ['csat', 'nps', 'review']
const FeedbackRating = (props: FeedbackItemProps) => {
  if (!SATISFACTION_KINDS.includes(props.kind.name)) {
    return <></>
  }

  return (
    <FeedbackRatingContainer>
      : {(props.kind.fields as ReviewFields).rating}
      {props.kind.name === 'review' && <Star size={16} weight="fill" />}
    </FeedbackRatingContainer>
  )
}

const FeedbackItemHeader = (props: FeedbackItemProps) => {
  const feedackType = props.kind.prettyName

  const menuOptions: OptionsMenuItem[] = [
    {
      text: 'Details',
      icon: <ListDashes color={colors.neutralLowPure} size={16} />,
      onClick: () => {
        props.onSelectFeedback?.()
      }
    }
  ]

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  if (props.isOpportunityFeedback && isManager) {
    menuOptions.push({
      text: "It's inaccurate",
      icon: <ThumbsDown />,
      onClick: () => {
        props.onRemoveFeedbackFromOpportunity?.()
      }
    })
  }

  const date = props.postedAt ?? props.messages?.firstPostedAt ?? props.updatedAt

  return (
    <FeedbackHeader>
      <Group>
        {props.enableHighlightOnFocus && props.index !== undefined && (
          <span title={`Index: ${props.index + 1}`}>({props.index + 1})</span>
        )}
        <FlexContainer>
          <FeedbackType>{feedackType}</FeedbackType>
          <FeedbackRating {...props} />
        </FlexContainer>
      </Group>
      <Group>
        {props.showPin && (
          <IconButton size="small" onClick={() => props.onPinToggle?.(!props.isPinned)}>
            <PushPin weight={props.isPinned ? 'fill' : 'regular'} />
          </IconButton>
        )}
        {date ? <FeedbackDate>{dateShortMonthFormat(date, false)}</FeedbackDate> : null}
        <OptionsMenu options={menuOptions} size="small" stopPropagation />
      </Group>
    </FeedbackHeader>
  )
}

export default FeedbackItemHeader
