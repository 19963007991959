import { CSS, styled } from '@/theme'
import FlexContainer from '../flex-container'

const Root = styled(FlexContainer, {
  flexDirection: 'column',
  gap: '$nano',

  '&:last-child': {
    mb: 0
  },

  variants: {
    side: {
      right: {
        alignItems: 'flex-end'
      },
      left: {
        alignItems: 'flex-start'
      }
    },
    nextMessage: {
      sameSide: {
        mb: '$xxs'
      },
      diffSide: { mb: '$md' },
      end: {
        mb: '$md'
      }
    }
  }
})

const BalloonContainer = styled(FlexContainer, {
  maxWidth: '75%',
  minWidth: '45%',
  width: 'fit-content',
  position: 'relative',
  flexDirection: 'column',
  gap: '$nano',

  variants: {
    side: {
      right: {
        alignItems: 'flex-end'
      },
      left: {
        alignItems: 'flex-start'
      }
    }
  }
})

const Balloon = styled(FlexContainer, {
  bAll: 'transparent',
  flexDirection: 'column',
  padding: '$xs $xxs',
  gap: '$xs',
  position: 'relative',

  '& .options': {
    visibility: 'hidden'
  },

  '&:hover': {
    '& .options': {
      visibility: 'visible'
    }
  },

  variants: {
    isEditing: {
      true: {
        '& .options': {
          visibility: 'visible'
        }
      }
    },

    isBusy: {
      true: {
        '& .options': {
          visibility: 'hidden'
        }
      }
    },

    side: {
      right: {
        borderRadius: '24px 24px 0px 24px',
        backgroundColor: '$neutralHighMedium',

        '&:hover': {
          bAll: '$neutralLowLight'
        }
      },
      left: {
        borderRadius: '24px 24px 24px 0px',
        backgroundColor: '$brandPrimaryLight',

        '&:hover': {
          bAll: '$brandPrimaryTransparentStrong'
        }
      }
    },
    chat: {
      true: {
        maxWidth: '80%',
        minWidth: '45%'
      }
    }
  }
})

const ContentContainer = styled(FlexContainer, {
  gap: '$nano'
})

const markdownCSS: CSS = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '$nano',

  '& ol, & ul': {
    ml: '$xs'
  },
  '& li': {
    lineHeight: '$xl',
    fontSize: '$xxxs',
    fontWeight: '$light'
  },
  '& li::marker': {
    fontSize: '$xxxs',
    fontWeight: '$light',
    fontFamily: '$default'
  },

  '& p, & span': {
    lineHeight: '21px',
    fontSize: '$xxxs',
    fontWeight: '$light'
  },

  '& table': {
    bRight: '$neutralLowLight',
    bLeft: '$neutralLowLight',
    br: '$micro',
    borderCollapse: 'collapse'
  },

  '& th, & td': {
    textAlign: 'left',
    py: '$nano',
    px: '$micro',
    bAll: '$neutralLowLight',
    lineHeight: '$xl',
    fontSize: '$xxxs',
    fontWeight: '$light'
  },

  '& th': {
    textAlign: 'center'
  },

  '& b, & strong': {
    fontWeight: '$semibold'
  }
}

const Content = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',

  '.markdown': markdownCSS,

  variants: {
    side: {
      right: {
        color: '$neutralLowPure'
      },
      left: {
        color: '$brandPrimaryPure'
      }
    },
    isInfoMessage: {
      true: {
        display: 'flex',
        flexDirection: 'column'
      }
    }
  }
})

const Header = styled('h3', {
  fontSize: 14,
  fontWeight: '$semibold'
})

const Text = styled('p', {
  lineHeight: '$lg',
  fontSize: '$xxxs',
  fontWeight: '$light',

  variants: {
    highlight: {
      true: {
        em: {
          fontStyle: 'normal',
          fontWeight: 600,
          background: '$feedbackWarningMedium'
        }
      }
    }
  }
})

const LoadingWrapper = styled('div', { width: 40 })

const OptionsContainer = styled(FlexContainer, {
  padding: 'nano',

  '& svg': {
    cursor: 'pointer'
  }
})

const Link = styled('a', {
  display: 'flex',
  alignSelf: 'flex-end',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '$micro',

  textDecoration: 'none',
  cursor: 'pointer',

  br: '$micro',
  bAll: '$brandPrimaryPure',
  color: '$brandPrimaryPure',

  fontSize: '$xxxs',
  fontWeight: '$semibold',
  lineHeight: '$xs',

  padding: '$xxxs $xs',
  width: 'fit-content',
  mt: '$xs'
})

const Footer = styled('p', {
  color: '$neutralLowLight',
  fontSize: '$micro',
  lineHeight: 'sm',
  width: '100%',
  textAlign: 'left',

  variants: {
    side: {
      right: {
        textAlign: 'right'
      },
      left: {
        textAlign: 'left'
      }
    }
  }
})

const UserIcon = styled('div', {
  size: 32,
  minWidth: 32,
  borderRadius: 50,
  backgroundColor: '$feedbackInformativeMedium',
  color: '$neutralHighLight',
  fontStyle: 'normal',
  fontWeight: '$semibold',
  fontSize: '$xxs',
  lineHeight: '18.4px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-end',
  // position: 'absolute',

  variants: {
    side: {
      right: {
        backgroundColor: '$neutralLowLight',
        order: 0
      },
      left: {
        backgroundColor: '$brandPrimaryPure',
        order: -1
      }
    }
  }
})

const FullMessageContainer = styled(FlexContainer, {
  gap: '$xxs',
  variants: {
    side: {
      right: {
        justifyContent: 'flex-end'
      },
      left: {
        justifyContent: 'flex-start'
      }
    }
  }
})

const ChatBalloon = {
  Root,
  Balloon,
  BalloonContainer,
  FullMessageContainer,
  Content,
  ContentContainer,
  Header,
  Text,
  LoadingWrapper,
  OptionsContainer,
  Link,
  Footer,
  UserIcon
}

export default ChatBalloon
