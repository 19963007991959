import { useCallback, useMemo } from 'react'
import useCollections from '../collections/useCollections'
import { getParamsFromFilterContent } from '@/utils/filters'
import { SavedFilterContent } from '@/types/filters/Filters'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useFeedQueryParams from '../feedback/new/useFeedQueryParams'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { MetricListPayloadItem } from '@/types/metrics/MetricsRequests'
import {
  allMetricItems,
  allMetricItemsList,
  AREA_METRICS,
  COLLECTION_METRICS,
  metricKeyToAllMetricsKeys,
  NPS_TIMESERIES_METRICS_KEYS,
  OVERALL_METRIC_KEYS,
  SUPPORT_TICKET_TIMESERIES_METRICS_KEYS
} from '@/utils/metrics'
import { BreakdownOption } from '@/types/time-series/TimeSeries'
import { AllMetricsKey, MetricKey, TicketsSatisfactionMetricKey } from '@/types/metrics'
import useUser from '../useUser'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'
import {
  TICKET_CSAT_METRIC_KEYS,
  TICKET_NPS_METRIC_KEYS,
  TICKET_SATISFACTION_METRICS_KEYS
} from '@/utils/metrics/ticketsSatisfactionMetrics'
import useTicketSurveyType from '../useTicketSurveyType'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSourcesQuery from '../useSourcesQuery'

const useMetricListPayload = () => {
  const { contentData } = useCollections()
  const { currentInterestArea } = useCurrentInterestAreaStore()

  const { queryParams } = useFeedQueryParams()

  const { areas: advancedAreas } = useBasicAreaOfInterestQuery()

  const { organization } = useUser()

  const { hasTicketSurveyType, surveyTypeIsCSAT, surveyTypeIsNPS } = useTicketSurveyType({
    enabled: true
  })

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)
  const { values: sourceValues } = useSourcesQuery()

  const collectionShareFilter = useMemo(() => {
    const rawContent = contentData?.content
    const content = Array.isArray(rawContent) ? (rawContent as SavedFilterContent[]) : []

    return {
      ...getParamsFromFilterContent(content),
      context: contentData?.context,
      label: undefined,
      'posted_at.lt': undefined,
      'posted_at.gte': undefined
    }
  }, [contentData])

  const areaShareFilter = useMemo(() => {
    if (!currentInterestArea) return {}

    if (!advancedAreas.length) return {}

    const currentAdvancedArea = currentInterestArea.isUnmappedArea
      ? currentInterestArea
      : advancedAreas.find(area => area.id === currentInterestArea.id)

    return {
      context: currentAdvancedArea?.context,
      label: undefined,
      'posted_at.lt': undefined,
      'posted_at.gte': undefined
    } as FeedbackListQueryParams
  }, [advancedAreas, currentInterestArea])

  const getMetricKeys = useCallback(
    (
      metricKey: MetricKey,
      options?: {
        breakdown?: BreakdownOption
        specificBreakdowns?: BreakdownOption[]
        enableShareOverFilters?: boolean
        useTimeseriesMetrics?: boolean
      }
    ) => {
      // all keys are on the list by default
      let list: AllMetricsKey[] = metricKeyToAllMetricsKeys[metricKey]

      if (!list) return

      if (metricKey === 'support_ticket') {
        const supportTicketMetrics = options?.useTimeseriesMetrics
          ? SUPPORT_TICKET_TIMESERIES_METRICS_KEYS
          : metricKeyToAllMetricsKeys.support_ticket

        const hasContactRatePremise =
          Object.keys(organization?.config?.activeUsersByDate ?? {}).length > 0

        let supportTicketsList = supportTicketMetrics

        if (!hasContactRatePremise) {
          supportTicketsList = supportTicketsList.filter(key => key !== 'ticket_contact_rate')
        }

        if (!hasTicketSurveyType) {
          supportTicketsList = supportTicketsList.filter(
            key => !TICKET_SATISFACTION_METRICS_KEYS.includes(key as TicketsSatisfactionMetricKey)
          )
        } else if (!surveyTypeIsCSAT) {
          supportTicketsList = supportTicketsList.filter(
            key => !TICKET_CSAT_METRIC_KEYS.includes(key as TicketsSatisfactionMetricKey)
          )
        } else if (!surveyTypeIsNPS) {
          supportTicketsList = supportTicketsList.filter(
            key => !TICKET_NPS_METRIC_KEYS.includes(key as TicketsSatisfactionMetricKey)
          )
        }

        list = supportTicketsList
      }
      if (metricKey === 'nps' && options?.useTimeseriesMetrics) {
        list = NPS_TIMESERIES_METRICS_KEYS
      }

      if (
        metricKey === 'count' &&
        options?.breakdown &&
        options?.specificBreakdowns?.includes(options.breakdown) &&
        options?.enableShareOverFilters
      ) {
        const subMetrics = [...OVERALL_METRIC_KEYS]
        const defaultFeedbackShareIndex = subMetrics.findIndex(
          metricKey => metricKey === 'feedback_share'
        )

        subMetrics.splice(defaultFeedbackShareIndex + 1, 0, 'feedback_share_group')
        list = subMetrics
      }

      if (!contentData) {
        list = list.filter(item => !COLLECTION_METRICS.includes(item))
      }

      if (!currentInterestArea) {
        list = list.filter(item => !AREA_METRICS.includes(item))
      }

      return list
    },
    [
      organization,
      contentData,
      currentInterestArea,
      hasTicketSurveyType,
      surveyTypeIsCSAT,
      surveyTypeIsNPS
    ]
  )

  const getMetricAndAddShareFilter = useCallback(
    (metricKey: AllMetricsKey): MetricListPayloadItem => {
      const metricItem = allMetricItems[metricKey]

      if (COLLECTION_METRICS.includes(metricKey) && contentData) {
        return {
          ...metricItem.metric,
          args: metricItem.metric.filter,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...collectionShareFilter,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      if (AREA_METRICS.includes(metricKey) && currentInterestArea) {
        return {
          ...metricItem.metric,
          args: metricItem.metric.filter,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...areaShareFilter,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      if (metricKey === 'feedback_share_group') {
        return {
          ...metricItem.metric,
          args: metricItem.metric.filter,
          share_filter: {
            ...metricItem.metric.share_filter,
            ...queryParams,
            label: undefined,
            'posted_at.lt': undefined,
            'posted_at.gte': undefined
          }
        }
      }

      return {
        ...metricItem.metric,
        args: metricItem.metric.filter
      }
    },
    [areaShareFilter, collectionShareFilter, queryParams, contentData, currentInterestArea]
  )

  const addShareFiltersToMetrics = useCallback(
    (metricList: MetricListPayloadItem[]) => {
      const metricListWithShareFilters: (MetricListPayloadItem | null)[] = metricList.map(
        metricPayloadItem => {
          const metricItem = allMetricItemsList.find(
            item => item.metric.label === metricPayloadItem.label
          )
          if (!metricItem)
            return {
              ...metricPayloadItem,
              args: metricPayloadItem.filter
            }

          if (COLLECTION_METRICS.includes(metricItem.key)) {
            if (!contentData) return null
            return {
              ...metricPayloadItem,
              args: metricPayloadItem.filter,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...collectionShareFilter,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          if (AREA_METRICS.includes(metricItem.key)) {
            if (!currentInterestArea) return null
            return {
              ...metricPayloadItem,
              args: metricPayloadItem.filter,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...areaShareFilter,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          if (metricItem.key === 'feedback_share_group') {
            return {
              ...metricPayloadItem,
              args: metricPayloadItem.filter,
              share_filter: {
                ...metricPayloadItem.share_filter,
                ...queryParams,
                label: undefined,
                'posted_at.lt': undefined,
                'posted_at.gte': undefined
              }
            }
          }

          return {
            ...metricPayloadItem,
            args: metricPayloadItem.filter
          }
        }
      )

      return metricListWithShareFilters.filter(Boolean) as MetricListPayloadItem[]
    },
    [areaShareFilter, collectionShareFilter, queryParams, contentData, currentInterestArea]
  )

  const getMetricList = useCallback(
    (params: { includePreviousValue?: boolean; customSources?: string[] }) => {
      const { includePreviousValue, customSources } = params
      const list: MetricListPayloadItem[] = []

      const overallMetricKeys = getMetricKeys('count')
      overallMetricKeys
        ?.filter(overallKey => !hiddenMetrics.includes(`count:${overallKey}`))
        .filter(overallKey => !hiddenMetrics.includes(overallKey))
        .forEach(overallKey => {
          const metricItem = allMetricItems[overallKey]
          list.push({
            ...metricItem.metric,
            args: metricItem.metric.filter,
            include_previous_value: includePreviousValue ?? false
          })
        })

      const sources = customSources ?? sourceValues
      sources.forEach(source => {
        const metricKey = source as MetricKey
        const sourceMetricKeys = getMetricKeys(metricKey)

        if (!sourceMetricKeys) return
        sourceMetricKeys
          .filter(sourceKey => !hiddenMetrics.includes(`${metricKey}:${sourceKey}`))
          .filter(sourceKey => !hiddenMetrics.includes(sourceKey))
          .forEach(sourceKey => {
            const relatedMetricItem = allMetricItems[sourceKey]
            list.push({
              ...relatedMetricItem.metric,
              args: relatedMetricItem.metric.filter,
              include_previous_value: includePreviousValue ?? false
            })
          })
      })

      return list
    },
    [hiddenMetrics, sourceValues, getMetricKeys]
  )

  return { addShareFiltersToMetrics, getMetricKeys, getMetricAndAddShareFilter, getMetricList }
}

export default useMetricListPayload
