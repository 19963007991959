import Dialog from '@/components/atoms/dialog/Dialog'
import FlexContainer from '@/components/atoms/flex-container'
import { ChatText } from '@phosphor-icons/react'
import { TopicRootCause } from '@/types/analytics/Analytics'
import { colors } from '@/theme'
import Text from '@/components/atoms/text'
import { QueryFunctionContext, useQuery } from '@tanstack/react-query'
import FeedbackService, { getDefaultSearchPayload } from '@/services/FeedbackService'
import Loader from '@/components/atoms/loader'
import FeedbackItem from '@/components/molecules/feedback-item'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import { useKeywordsDetailsFeedbackStore } from '@/store'
import shallow from 'zustand/shallow'
import { Feedback } from '@/types/feedbacks'
import { Suspense, lazy, useState } from 'react'
import useToastMessageStore from '@/store/useToastMessageStore'
import RootCauseEMptyFeedbacks from './RootCauseEmptyFeedbacks'
import RootCauseFeedbackModalStyles from './RootCauseFeedbackModal.styles'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

const FeedbackDetails = lazy(() => import('@/components/molecules/feedback-details/index'))
const FeedbackThread = lazy(() => import('@/components/molecules/feed/feedbacks/FeedbackThread'))

interface Props {
  rootCause?: TopicRootCause
  onClose: () => void
}

const RootCauseFeedbackModal = ({ rootCause, onClose }: Props) => {
  const { logException } = useLogging({ context: 'root-cause-feedback-modal' })

  const { selectedFeedback } = useKeywordsDetailsFeedbackStore(
    state => ({
      selectedFeedback: state.selectedFeedback
    }),
    shallow
  )
  const setSelectedFeedback = useKeywordsDetailsFeedbackStore(state => state.setSelectedFeedback)
  const { t } = useTranslation()

  const { archive } = useFeedbacks({
    archived: false,
    store: 'explore',
    pageSize: 0
  })

  const [feedbackThreadOpen, setFeedbackThreadOpen] = useState<Feedback | null>(null)

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const getFeedbackEvidence = async (
    params: QueryFunctionContext<[string, { reasons?: string[]; text?: string }]>
  ) => {
    const { queryKey, signal } = params
    const { reasons } = queryKey[1]
    if (!reasons) return []

    const { filter_keyword: filterKeyword, ...remainingPayload } = getDefaultSearchPayload()
    const newFilterKeyword = { ...filterKeyword }
    newFilterKeyword.reason_hashes = reasons
    remainingPayload.size = 3

    const [error, feedbackData] = await FeedbackService.search(
      {
        ...remainingPayload,
        filter_keyword: newFilterKeyword
      },
      signal
    )

    if (error) {
      const message = t('failedToLoadRootCauseEvidence')
      logException(error, { priority: 'low', message })
      addErrorToast({ text: `${message}. Error: ${error.key}` })
      throw error
    }

    return feedbackData.data
  }

  const { data, isLoading } = useQuery({
    queryKey: [
      'feedback-evidence-root-cause',
      { reasons: rootCause?.reasonsHash, text: rootCause?.text }
    ],
    queryFn: getFeedbackEvidence,
    enabled: !!rootCause,
    retry: false
  })

  const feedbacks = data || []
  const showFeedbacks = !isLoading && feedbacks.length > 0
  const showNoResults = !isLoading && !feedbacks.length

  const isShrinked = !!feedbackThreadOpen || !!selectedFeedback

  const onOpenChange = (open: boolean) => {
    if (!open) onClose()
  }
  return (
    <>
      <Dialog
        align="center"
        closeIcon
        onOpenChange={onOpenChange}
        open={!!rootCause}
        useFullHeight
        width={isShrinked ? 'maximizedAndShrinked' : 'maximized'}
        title="Root cause evidence"
      >
        <RootCauseFeedbackModalStyles.Container direction={'column'} fullHeight>
          {rootCause && (
            <>
              <RootCauseFeedbackModalStyles.Header>
                <RootCauseFeedbackModalStyles.IconBox>
                  <ChatText color={colors.neutralHighLight} size={16} weight="regular" />
                </RootCauseFeedbackModalStyles.IconBox>
                {!isLoading && (
                  <Text
                    as="p"
                    color="neutralLowPure"
                    fontSize="xs"
                    fontWeight="regular"
                    lineHeight="default"
                  >
                    {t('feedbackEvidence', {
                      feedbacksLength: feedbacks.length,
                      rootCauseText: rootCause?.text
                    })}
                  </Text>
                )}
              </RootCauseFeedbackModalStyles.Header>
              <RootCauseFeedbackModalStyles.FeedbackListContainer
                className="scroll-on-hover-container"
                direction="column"
                fullHeight
                fullWidth
                gap="xxs"
              >
                {isLoading && (
                  <FlexContainer className="scroll-on-hover-child" justifyContent="center">
                    <Loader />
                  </FlexContainer>
                )}

                {showFeedbacks &&
                  feedbacks.map(feedback => (
                    <FeedbackItem
                      key={feedback.feedbackId}
                      {...feedback}
                      className="scroll-on-hover-child feedback-evidence-item"
                      onArchive={archive}
                      onRepliesClick={() => setFeedbackThreadOpen(feedback)}
                      onSelect={setSelectedFeedback}
                      selectedId={selectedFeedback?.feedbackId}
                    />
                  ))}

                {showNoResults && <RootCauseEMptyFeedbacks />}
              </RootCauseFeedbackModalStyles.FeedbackListContainer>
            </>
          )}
        </RootCauseFeedbackModalStyles.Container>
      </Dialog>

      <Suspense>
        <FeedbackDetails store="keywordsDetails" />
      </Suspense>

      <Suspense>
        {feedbackThreadOpen && (
          <FeedbackThread
            feedback={feedbackThreadOpen}
            onOpenChange={() => setFeedbackThreadOpen(null)}
            ticketId={feedbackThreadOpen?.ticket?.id ?? ''}
          />
        )}
      </Suspense>
    </>
  )
}

export default RootCauseFeedbackModal
