import FlexContainer from '@/components/atoms/flex-container'
import ExplorationSourceDistribution from '../../analytics/indicators/ExplorationSourceDistribution'
import { SourceDistributionItem } from '@/types/analytics/Sources'
import { styled } from '@/theme'
import { chartColors } from '@/theme/colors'
import useMetricsQuery from '@/hooks/metrics/useMetricsQuery'
import useFeedQueryParams from '@/hooks/feedback/new/useFeedQueryParams'
import useSourcesQuery from '@/hooks/useSourcesQuery'
import { useMemo } from 'react'
import { MetricItem, MetricKey, MetricsRequests } from '@/types/metrics'
import { allMetricItems } from '@/utils/metrics'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { useTranslation } from 'react-i18next'
import useOpportunityStore from '@/store/useOpportunityStore'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'

const DistributionContainer = styled(FlexContainer, {
  bc: '$neutralHighMedium',
  py: '$xs',
  px: '$sm',
  br: '$micro'
})

const allCountMetrics: Record<MetricKey, MetricItem> = {
  nps: allMetricItems.nps_count,
  count: allMetricItems.count,
  csat: allMetricItems.csat_count,
  issue: allMetricItems.issue,
  review: allMetricItems.review_count,
  social_media_post: allMetricItems.social_media_post,
  support_ticket: allMetricItems.support_ticket,
  feedback_share: allMetricItems.count,
  transcript: allMetricItems.transcript,
  tcsat: allMetricItems.tcsat,
  tnps: allMetricItems.tnps
}

interface Props {
  currentSource?: SourceDistributionItem[]
  onClickSource: (value: SourceDistributionItem | undefined) => void
}

const FeedbackSourcesDistribution = ({ currentSource, onClickSource }: Props) => {
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)

  const { queryParams } = useFeedQueryParams()

  const { t } = useTranslation()

  const { data: sourcesData, isLoading: isLoadingSources } = useSourcesQuery({ enabled: false })

  const metricList = useMemo(() => {
    const countMetric = allCountMetrics.count.metric
    const list: MetricsRequests.MetricsPayload['metric_list'] = [
      {
        ...countMetric,
        args: countMetric.filter,
        include_previous_value: false
      }
    ]

    const sources = sourcesData?.values ?? []

    sources?.forEach(source => {
      const metricOption = source as MetricKey
      const metricItem = allCountMetrics[metricOption]
      if (metricItem) {
        const metric = metricItem.metric
        list.push({
          ...metric,
          args: metric.filter,
          include_previous_value: false
        })
      }
    })

    return list
  }, [sourcesData])

  const { areas: advancedAreas } = useBasicAreaOfInterestQuery()

  const filters = useMemo(() => {
    return {
      ...queryParams,
      opportunity_id: currentOpportunity?.id
    }
  }, [queryParams, currentOpportunity])

  const { data: metricsData, isLoading } = useMetricsQuery({
    filters: filters ? [{ ...filters }] : [],
    enabled: advancedAreas.length > 0,
    customMetricList: metricList
  })

  const sourceResults = useMemo(() => {
    let total = 0
    const sourceDistribution: SourceDistributionItem[] = []

    if (!metricsData) return { total, sourceDistribution }
    const metricResult = metricsData[0]

    if (!metricResult) return { total, sourceDistribution }

    metricResult.forEach(metric => {
      if (metric.label === allCountMetrics.count.metric.label) {
        total = metric.current_value
        return
      }

      let metricKey = ''
      Object.entries(allCountMetrics).forEach(([key, item]) => {
        if (item.metric.label === metric.label) {
          metricKey = key as MetricKey
        }
      })

      if (metric.current_value > 0) {
        sourceDistribution.push({
          count: metric.current_value,
          key: metricKey,
          name: getFeedbackKindPrettyName(metricKey),
          percentage: total !== 0 ? (metric.current_value / total) * 100 : 0
        })
      }
    })
    const sortedSources = [...sourceDistribution].sort((a, b) => b.count - a.count)
    const sourcesWithColor = sortedSources.map((source, index) => ({
      ...source,
      color: chartColors[index] ?? undefined
    }))

    return { total, sourceDistribution: sourcesWithColor }
  }, [metricsData])

  if (isLoading || isLoadingSources) return <></>

  return (
    <DistributionContainer>
      <ExplorationSourceDistribution
        css={{ gap: '$xs' }}
        distributionContainerCss={{ minHeight: 24, gap: '$nano' }}
        highlightSource={currentSource}
        onClickSource={onClickSource}
        resetButtonCss={{ color: '$neutralLowPure' }}
        showIcon={false}
        showResetButton
        sources={sourceResults.sourceDistribution}
        titlePrefix={t('sourcesPrefix')}
        total={sourceResults.total}
      />
    </DistributionContainer>
  )
}

export default FeedbackSourcesDistribution
