import { useTranslation } from 'react-i18next'
import { Sentiment } from '@/utils/sentiments'
import RadioGroup, { RadioGroupOption } from '@/components/atoms/radio-group'

export interface SentimentFilterContentProps {
  value: Sentiment | undefined
  onValueChange: (value: Sentiment) => void
}

const SentimentFilterContent = ({ value, onValueChange }: SentimentFilterContentProps) => {
  const { t } = useTranslation()

  const options: RadioGroupOption[] = [
    { text: t('negative'), value: Sentiment.Negative },
    { text: t('mostlyNegative'), value: Sentiment.MostlyNegative },
    { text: t('mostlyNeutral'), value: Sentiment.MostlyNeutral },
    { text: t('mostlyPositive'), value: Sentiment.MostlyPositive },
    { text: t('positive'), value: Sentiment.Positive }
  ]

  return <RadioGroup options={options} onValueChange={onValueChange} value={value} />
}

export default SentimentFilterContent
