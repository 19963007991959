import { useEffect, useMemo, useRef, useState } from 'react'
import { BreakdownOption, TimeSeriesIntervalOption } from '@/types/time-series/TimeSeries'
import { ArrowUp, ArrowUUpRight, CopySimple } from '@phosphor-icons/react'
import useToastMessageStore from '@/store/useToastMessageStore'
import copyHTMLToClipboard from '@/utils/clipboard'
import {
  ColumnDef,
  ColumnSort,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable
} from '@tanstack/react-table'
import TableV2 from '@/components/atoms/table-v2'
import useComparativeTableMetrics from './useComparativeTableMetrics'
import { FilterItem } from '@/types/filters/Filters'
import { AllMetricsKey } from '@/types/metrics'
import { allMetricItems } from '@/utils/metrics'
import ChartSkeleton from '../../analytics/skeletons/ChartSkeleton'
import NoResults from '@/components/atoms/no-results'
import TimeseriesTableStyles from '../TimeseriesTable.styles'
import useComparativeTableFilters from './useComparativeTableFilters'
import { colors } from '@/theme'
import CompetitorsEnabledButton from '../../trend-line/CompetitorsEnabledButton'
import FlexContainer from '@/components/atoms/flex-container'
import { useTranslation } from 'react-i18next'

interface Props {
  interval: TimeSeriesIntervalOption
  rowBreakdown: BreakdownOption
  columnBreakdown: BreakdownOption
  favorites: boolean
  useAppliedFilters?: boolean
  selectedField?: FilterItem | null
  isAreasLoading?: boolean
  metric?: AllMetricsKey
  onCopy?: () => void
  onTransposeClick: () => void
}

type MetricSeriesTableItem = { name: string; id: string } & Record<string, number>

const ComparativeTable = ({
  rowBreakdown,
  columnBreakdown,
  favorites,
  useAppliedFilters,
  selectedField,
  metric = 'count',
  interval,
  isAreasLoading,
  onCopy,
  onTransposeClick
}: Props) => {
  const [sorting, setSorting] = useState<ColumnSort[]>([])
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const tableRef = useRef<HTMLTableElement>(null)

  const { t } = useTranslation()

  const { isLoadingResources, mapBreakdownToValues, breakdownFilters } = useComparativeTableFilters(
    {
      rowBreakdown,
      columnBreakdown,
      favorites,
      useAppliedFilters,
      selectedField,
      interval,
      metric
    }
  )

  const {
    data,
    isLoading: isLoadingData,
    isEmptyFilters
  } = useComparativeTableMetrics({
    columnBreakdown,
    rowBreakdown,
    metric,
    breakdownFilters
  })

  const normalizedData = useMemo(() => {
    const _normalizedData: MetricSeriesTableItem[] = []
    const parsedData: { id: string; rowName: string; columnId: string; value: number }[] = []
    if (!data) return _normalizedData
    if (!data.length) return _normalizedData

    const rowValues = mapBreakdownToValues[rowBreakdown]

    if (!rowValues.length) return _normalizedData

    rowValues.forEach(rowItem => {
      const columnValues = mapBreakdownToValues[columnBreakdown]

      if (!columnValues.length) return

      columnValues.forEach(columnItem => {
        parsedData.push({
          id: rowItem.id,
          rowName: rowItem.name,
          columnId: columnItem.id,
          value: data[parsedData.length][0].current_value
        })
      })
    })

    parsedData.forEach(currentValue => {
      let item = _normalizedData.find(i => i.id === currentValue.id)

      if (!item) {
        item = {
          id: currentValue.id,
          name: currentValue.rowName,
          [currentValue.columnId]: currentValue.value
        } as MetricSeriesTableItem

        _normalizedData.push(item)
      } else {
        item = {
          ...item,
          [currentValue.columnId]: currentValue.value
        }

        const itemIndex = _normalizedData.findIndex(i => i.id === currentValue.id)
        _normalizedData.splice(itemIndex, 1, item)
      }
    })

    return _normalizedData
  }, [data, rowBreakdown, columnBreakdown, mapBreakdownToValues])

  const columns = useMemo(() => {
    const columnValues = mapBreakdownToValues[columnBreakdown]
    const metricItem = allMetricItems[metric]
    const transformer = metricItem.numberTransformer

    const _columns: ColumnDef<MetricSeriesTableItem>[] = [
      {
        accessorKey: 'name',
        id: 'name',
        header: () => (
          <TimeseriesTableStyles.TableButton
            onClick={onTransposeClick}
            icon={<ArrowUUpRight />}
            text="Transpose axis"
          />
        ),
        enableHiding: false,
        enableSorting: false,
        minSize: 360,
        maxSize: 400,
        cell: data => (
          <TimeseriesTableStyles.NameCell>
            <span>{data.row.original.name}</span>
          </TimeseriesTableStyles.NameCell>
        )
      } as ColumnDef<MetricSeriesTableItem>,
      ...columnValues.map(
        (column): ColumnDef<MetricSeriesTableItem> => ({
          id: column.id,
          accessorFn: row => {
            return row[column.id]
          },
          header: column.name,
          enableHiding: false,
          enableSorting: true,
          sortDescFirst: true,
          minSize: 180,
          maxSize: 190,
          cell: data => (
            <TimeseriesTableStyles.Cell>
              <span>{transformer(data.row.original[column.id])}</span>
            </TimeseriesTableStyles.Cell>
          )
        })
      )
    ]

    return _columns
  }, [metric, mapBreakdownToValues, columnBreakdown, onTransposeClick])

  const onClickCopy = () => {
    copyHTMLToClipboard(tableRef.current)
    addSuccessToast({ text: `Copied to clipboard` })
    onCopy?.()
  }

  const table = useReactTable({
    data: normalizedData,
    columns,
    manualFiltering: false,
    getRowId: row => row.id,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    enableSorting: true,
    state: {
      sorting,
      columnPinning: {
        left: ['name']
      }
    }
  })

  // biome-ignore lint/correctness/useExhaustiveDependencies: reset sorting on rowBreakdown or columnBreakdown changes
  useEffect(() => {
    table.resetSorting()
  }, [table, rowBreakdown, columnBreakdown])

  if (isEmptyFilters && !isLoadingResources) {
    return (
      <>
        <NoResults css={{ mt: 0, gap: '$xs', color: '$neutralLowPure' }} hideDescription />
        <CompetitorsEnabledButton />
      </>
    )
  }

  if (isLoadingData || isLoadingResources || isAreasLoading) {
    return <ChartSkeleton height={280} />
  }

  if (!data?.length || isEmptyFilters) {
    return (
      <>
        <NoResults css={{ mt: 0, gap: '$xs', color: '$neutralLowPure' }} hideDescription />
        <CompetitorsEnabledButton />
      </>
    )
  }

  return (
    <>
      <TableV2
        ref={tableRef}
        table={table}
        thContainerProps={TimeseriesTableStyles.thContainerProps}
        thCss={TimeseriesTableStyles.thCss}
        tdCss={TimeseriesTableStyles.tdCss}
        sortIndicator={<ArrowUp color={colors.neutralLowPure} size={16} />}
        enableSort
      />

      <FlexContainer alignItems="center" justifyContent="flexEnd" gap="micro">
        <CompetitorsEnabledButton />

        <TimeseriesTableStyles.TableButton
          onClick={onClickCopy}
          text={t('copyTable')}
          icon={<CopySimple />}
        />
      </FlexContainer>
    </>
  )
}

export default ComparativeTable
