import { APP_ENVIRONEMNT } from '@/config'
import DefaultErrorHandler from '@/services/DefaultError'
import { useUserStore } from '@/store'
import * as Sentry from '@sentry/react'
import { AxiosError } from 'axios'

export enum ErrorCodes {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  METHOD_NOT_ALLOWED = 405,
  VALIDATION_FAILED = 422,

  INTERNAL_SERVER_ERROR = 500,
  NOT_IMPLEMENTED = 501,
  SERVICE_UNAVAILABLE = 503
}

export const CLIENT_ERROR_CODES: ErrorCodes[] = [
  ErrorCodes.BAD_REQUEST,
  ErrorCodes.UNAUTHORIZED,
  ErrorCodes.FORBIDDEN,
  ErrorCodes.NOT_FOUND,
  ErrorCodes.METHOD_NOT_ALLOWED,
  ErrorCodes.VALIDATION_FAILED
]

export const SERVER_ERROR_CODES: ErrorCodes[] = [
  ErrorCodes.INTERNAL_SERVER_ERROR,
  ErrorCodes.NOT_IMPLEMENTED,
  ErrorCodes.SERVICE_UNAVAILABLE
]

export const isErrorCode = (code: number): code is ErrorCodes =>
  [...CLIENT_ERROR_CODES, ...SERVER_ERROR_CODES].includes(code)

export const isClientErrorCode = (code: number): code is ErrorCodes =>
  CLIENT_ERROR_CODES.includes(code)
export const isServerErrorCode = (code: number): code is ErrorCodes =>
  SERVER_ERROR_CODES.includes(code)

export type ExceptionPriority = 'high' | 'medium' | 'low'
export interface ExceptionParams {
  message?: string
  priority?: ExceptionPriority
  tags?: Record<string, string>
}

export const extractErrorStatusCode = (error: unknown) => {
  let code = 'unknown'
  if (error instanceof DefaultErrorHandler) {
    code = String(error.statusCode ?? 'unknown')
  }

  if (error instanceof AxiosError) {
    code = String(error?.status ?? 'unknown')
  }

  return code
}

export const sentryCaptureException = (
  error: unknown,
  { message, priority, context }: ExceptionParams & { context?: string } = {}
) => {
  if (APP_ENVIRONEMNT !== 'production') return

  const user = useUserStore.getState().currentUser

  const code = extractErrorStatusCode(error)

  Sentry.captureException(error, {
    tags: {
      context,
      message,
      priority,
      code
    },
    user: {
      id: user?.user_id,
      username: user?.email
    }
  })

  const analytics = window.analytics
  if (!analytics) return

  analytics.track('error', {
    user_id: user?.user_id,
    organization_id: user?.organization_id,
    context,
    message
  })
}
