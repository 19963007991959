import { forwardRef, useCallback, useEffect, useMemo } from 'react'
import { FeedbackItemProps } from './FeedbackItem.types'
import {
  FeedbackContainer,
  FeedbackItemSeparator
} from '@/components/molecules/feedback-item/FeedbackItem.styles'
import FeedbackItemHeader from './FeedbackItemHeader'
import useLetterCase from '@/hooks/useLetterCase'
import useParse from '@/hooks/useParse'
import Chip from '@/components/atoms/chip'
import { Chats } from '@phosphor-icons/react'
import useRemoveOpportunityFeedbackStore from '@/store/useRemoveOpportunityFeedbackStore'
import { shallow } from 'zustand/shallow'
import { FeedbackKind, FeedbackKindName } from '@/types/feedbacks/FeedbackKindFields'
import useUser from '@/hooks/useUser'
import FlexContainer from '@/components/atoms/flex-container'
import IdChip from './IdChip'
import { NewFeedback } from '@/types/feedbacks/Feedback'

const SATISFACTION_KINDS: FeedbackKindName[] = ['csat', 'nps', 'review']
const checkIsEmptyFeedback = (kindName: FeedbackKindName, text?: string) => {
  if (!SATISFACTION_KINDS.includes(kindName)) return false

  if (!text) return true

  return text.trim().length === 0 || ['blank', '"blank"'].includes(text)
}

interface GetIdsParams {
  kind: FeedbackKind
  accounts?: NewFeedback['accounts']
  users?: NewFeedback['users']
}

export const getIds = ({ kind, accounts, users }: GetIdsParams) => {
  if (kind.name === 'nps' || kind.name === 'csat') {
    return {
      accountId: kind.fields.account_id as string,
      authorId: kind.fields.author_id as string,
      conversationId: undefined
    }
  }

  if (kind.name === 'support_ticket') {
    return {
      accountId: accounts?.[0].id ?? undefined,
      authorId: users?.[0].id ?? undefined,
      conversationId: kind.fields.id as string
    }
  }

  if (kind.name === 'transcript' || kind.name === 'social_media_post' || kind.name === 'issue') {
    return {
      accountId: undefined,
      authorId: undefined,
      conversationId: kind.fields.id as string
    }
  }

  return {
    accountId: undefined,
    authorId: undefined,
    conversationId: undefined
  }
}

const FeedbackItem = forwardRef<HTMLElement, FeedbackItemProps>(
  ({ css, className, ...props }, ref) => {
    const { shortUrls } = useLetterCase()
    const { parseAndSanitize } = useParse()

    const format = useCallback(
      (value: string) => parseAndSanitize(shortUrls(value).replaceAll('\n', '<br>')),
      [parseAndSanitize, shortUrls]
    )

    const isEmptySatisfactionFeedback = checkIsEmptyFeedback(props.kind.name, props.text)

    const title = (props.kind.fields as Record<string, string>).title ?? null
    const formattedTitle = useMemo(() => (title ? format(title) : null), [title, format])
    const formattedText = useMemo(() => {
      if (isEmptySatisfactionFeedback) return 'No comments left by user.'

      return props.text ? format(props.text) : null
    }, [props.text, format, isEmptySatisfactionFeedback])

    const messagesCount = props.messages?.total ?? 0

    const feedbackIndex =
      props.enableHighlightOnFocus && props.index !== undefined ? `${props.index + 1}` : undefined

    useEffect(() => {
      if (!props.isHighlighted || !feedbackIndex) return

      const feedbackElement = document.getElementById(feedbackIndex)
      if (!feedbackElement) return

      feedbackElement.scrollIntoView({ behavior: 'smooth' })
    }, [props.isHighlighted, feedbackIndex])

    const removingFeedbacks = useRemoveOpportunityFeedbackStore(
      state => state.removingFeedbacks,
      shallow
    )
    const isDismissed = removingFeedbacks.includes(props.id)

    const onClickHandler = () => {
      if (props.canSelect) {
        props.onSelectedChange?.(!props.selected)
      }
    }

    const { userPermissions } = useUser()
    const isManager = userPermissions.areas.includes('manager')

    const canSelect = props.canSelect && props.isOpportunityFeedback && isManager

    const canViewMessages = userPermissions.raw_message.includes('view')

    const { accountId, authorId, conversationId } = getIds({
      kind: props.kind,
      accounts: props.accounts,
      users: props.users
    })

    return (
      <FeedbackContainer
        canSelect={canSelect}
        className={className}
        css={css}
        dismissed={isDismissed}
        onClick={onClickHandler}
        ref={ref}
        selected={props.selected}
      >
        <FeedbackItemHeader {...props} />
        <FeedbackItemSeparator />

        {messagesCount > 0 && canViewMessages && (
          <>
            <Chip
              color="$brandPrimaryLight"
              css={{
                color: '$brandPrimaryPure',
                fontWeight: '$semibold',
                fontSize: '$xxxs',
                cursor: 'pointer'
              }}
              icon={<Chats size={16} weight="fill" />}
              label={`${messagesCount} ${messagesCount > 1 ? 'Messages' : 'Message'}`}
              onClick={props.onMessagesClick}
              showDeleteIcon={false}
            />
          </>
        )}

        {!!title && <h4>{formattedTitle}</h4>}
        <p className={isEmptySatisfactionFeedback ? 'muted-feedback-text' : ''}>{formattedText}</p>

        <FlexContainer gap="xxs" wrap="wrap">
          {conversationId && <IdChip name="Conversation ID" value={conversationId} />}
          {accountId && <IdChip name="Account ID" value={accountId} />}
          {authorId && <IdChip name="Author ID" value={authorId} />}
        </FlexContainer>
      </FeedbackContainer>
    )
  }
)

FeedbackItem.displayName = 'NewFeedbackItem'

export default FeedbackItem
