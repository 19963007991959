import { styled } from '@/theme'

export const AssociatedOpportunitiesGrid = styled('div', {
  display: 'grid',
  mt: '$xs',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(1, 1fr)',
  gap: '$micro',

  '@bp2': {
    gridTemplateColumns: 'repeat(2, 1fr)'
  },

  '@bp1': {
    gridTemplateColumns: 'repeat(1, 1fr)'
  }
})

export const AssociatedOpportunityCard = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  background: '$neutralHighUltraLight',
  padding: '$xs',
  gap: '$md',
  borderRadius: '$xxs',
  color: '$brandPrimaryMedium',
  fontSize: '$micro',
  cursor: 'pointer'
})
