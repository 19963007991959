import useNumberAdvancedFilter from '@/hooks/advancedFilters/useNumberAdvancedFilter'
import {
  NumberFilterType,
  NumberFilterValue,
  NumericFilterOption
} from '@/types/filters/AdvancedFilters'
import { snakeToTitle } from '@/utils/letterCase'
import { useMemo, useState } from 'react'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import FiltersSkeleton from '../../FiltersSidebar/AddFilterMenu/FiltersSkeleton'
import { NumericFilterContent } from '../../FiltersSidebar/NumericGenericFilter'
import { NumericRangeOption } from '@/types/filters/Filters'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const defaultLabels: Record<NumericRangeOption, string> = {
  all: 'All values',
  between: 'Range',
  is: 'Exactly value',
  null: 'Empty values'
}

interface Props {
  title: string
  name: string
  path: string
  value: NumberFilterValue | null
  option?: NumericFilterOption
  index: number
  type: NumberFilterType
  disabled?: boolean
}

const NumberFilterApplied = ({
  title,
  name,
  path,
  value,
  option,
  index,
  type,
  disabled = false
}: Props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const {
    exactValue,
    exactValueText,
    isExactValueOpen,
    isLoading,
    isRangeOpen,
    max,
    min,
    update,
    onOptionChange,
    onRangeChange,
    onValueChange,
    rangeText,
    rangeValue,
    selectedOption
  } = useNumberAdvancedFilter({
    name,
    path,
    enableQuery: open,
    type,
    index,
    initialValue: value,
    initialOption: option
  })

  const shortText = useMemo(() => {
    if (!value) return t('empty')
    if (option === 'range') return `${value.$gte} — ${value.$lte}`
    if (option === 'matches') return `${value.$eq}`
    return ''
  }, [value, option, t])

  const longText = useMemo(() => {
    if (!value) return i18n.t('emptyText', { escapeValue: false })
    if (option === 'range') return i18n.t('betweenValues', { gte: value.$gte, lte: value.$lte })
    if (option === 'matches') return `${value.$eq}`
    return ''
  }, [value, option])

  const tooltipText = i18n.t('tooltipText', {
    name: snakeToTitle(name),
    longText,
    escapeValue: false
  })

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={shortText}
      contentType="label"
      disabled={isLoading || disabled}
      dropdown={
        <>
          <NumericFilterContent
            exactValue={exactValue}
            exactValueText={exactValueText}
            isExactValueOpen={isExactValueOpen}
            isRangeOpen={isRangeOpen}
            labels={defaultLabels}
            max={max}
            min={min}
            onOptionChange={onOptionChange}
            onRangeChange={onRangeChange}
            onValueChange={onValueChange}
            rangeText={rangeText}
            rangeValue={rangeValue}
            selectedOption={selectedOption}
          />
          {isLoading && <FiltersSkeleton />}
        </>
      }
      onApply={update}
      onOpenChange={setOpen}
      open={open}
      title={title}
      tooltip={tooltipText}
    />
  )
}

export default NumberFilterApplied
