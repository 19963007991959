import { styled } from '@/theme'

export const InviteContainer = styled('div', {
  position: 'relative',
  width: '100vw',
  height: '100vh',
  overflow: 'auto',
  display: 'flex',
  color: '$neutralLowPure',
  background: '$brandPrimaryPure'
})

export const MainContainer = styled('main', {
  padding: '56px 92px',
  background: '$neutralHighLight',
  boxSizing: 'border-box',
  minHeight: '100vh',
  height: 'max-content',
  paddingTop: 0,
  paddingRight: 160,
  maxWidth: 850
})

export const Content = styled('div', {
  paddingTop: 80,
  mb: 0,
  width: 500,
  height: '100%',
  boxSizing: 'border-box',

  a: {
    color: '$brandPrimaryPure',
    textDecoration: 'none'
  }
})

export const RightSide = styled('aside', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'flex-end',
  padding: 72,
  paddingBottom: 32,
  color: '$brandPrimaryLight',
  background: '$brandPrimaryPure',
  minHeight: '100vh',
  overflow: 'hidden',
  boxSizing: 'border-box',

  h2: {
    fontWeight: '$extrabold',
    fontSize: '$md',
    lineHeight: '$md',
    maxWidth: 375
  },

  p: {
    fontWeight: '$regular',
    fontSize: '$xxs',
    lineHeight: 1.8,
    maxWidth: 375
  }
})

export const Logo = styled('img', {
  height: 40,
  marginLeft: -12
})

export const Illustration = styled('img', {
  imageOrientation: 'optimizeQuality',
  position: 'absolute',
  top: 26,
  marginLeft: 80,
  height: 400
})
