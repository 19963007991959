import { AreaOfInterestData } from '@/types/area/AreaOfInterest'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import useSourcesQuery from '../useSourcesQuery'
import { endDateParam, startDateParam } from '@/utils/date'
import { MetricsRequests } from '@/types/metrics'
import MetricsService from '@/services/MetricsService'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { queryClient } from '@/plugins/reactQueryClient'
import useLogging from '../useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { snakeToTitle } from '@/utils/letterCase'
import { SavedFilterContentAdvanced } from '@/types/filters/Filters'
import AreaService from '@/services/AreaService'
import useMetricListPayload from '../metrics/useMetricListPayload'
import { shallow } from 'zustand/shallow'

export const UNMAPPED_AREA_QUERY_KEY = 'unmapped-area-query'

interface Options {
  enabled?: boolean
  loadMetrics?: boolean
}

const useUnmappedAreaQuery = ({ enabled = true, loadMetrics = true }: Options) => {
  const { logException } = useLogging({ context: 'unmapped-area' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const currentInterestArea = useCurrentInterestAreaStore(state => state.currentInterestArea)
  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange
        ? { start: startDateParam(state.dateRange.start), end: endDateParam(state.dateRange.end) }
        : null,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const startDate = useMemo(() => {
    if (datePeriod !== 'allTime' && dateRange) return dateRange.start
    return undefined
  }, [datePeriod, dateRange])

  const endDate = useMemo(() => {
    if (datePeriod !== 'allTime' && dateRange) return dateRange.end
    return undefined
  }, [datePeriod, dateRange])

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { data: sourcesData, isLoading: isSourcesLoading } = useSourcesQuery()

  const { addShareFiltersToMetrics, getMetricList } = useMetricListPayload()

  const metricList = useMemo(() => {
    return addShareFiltersToMetrics(getMetricList({ includePreviousValue: false }))
  }, [getMetricList, addShareFiltersToMetrics])

  const queryKey = [
    UNMAPPED_AREA_QUERY_KEY,
    { dateRange, datePeriod },
    hiddenMetrics,
    { loadMetrics }
  ]

  const { mutate: fetchMetrics } = useMutation({
    mutationFn: async (filter: FeedbackListQueryParams) => {
      const metricsPayload: MetricsRequests.MetricsPayload = {
        filter_list: [filter],
        metric_list: metricList,
        posted_at_gte: startDate,
        posted_at_lt: endDate
      }

      const [metricsError, metricsResponse] = await MetricsService.metrics(metricsPayload)
      if (metricsError) throw metricsError

      return metricsResponse
    },
    onSuccess: async data => {
      queryClient.setQueryData<AreaOfInterestData>(queryKey, old => {
        if (!old) return

        const unmappedError = old.error

        let newMetrics = data.length ? data[0] : old.metrics

        if (unmappedError) {
          newMetrics = newMetrics.map(metric => ({
            ...metric,
            error: {
              message: unmappedError.message,
              code: unmappedError.code,
              field: unmappedError.details?.field,
              isFromArea: true
            }
          }))
        }
        const newUnmapped = { ...old, metrics: newMetrics }

        if (currentInterestArea?.id === newUnmapped.id)
          setCurrentInterestArea({ ...currentInterestArea, ...newUnmapped })

        return newUnmapped
      })
    },
    onError: error => {
      const message = 'Failed to fetch area of interest metrics.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const queryFn = async () => {
    const [error, data] = await AreaService.getUnmappedArea()
    if (error) throw error

    // NOTE: unmmaped area is always advanced filter
    const content: [SavedFilterContentAdvanced] | [] = data.content
      ? ([
          {
            key: 'advanced',
            name: 'advanced',
            type: 'advanced',
            values: {
              filter: data.content.filter
            }
          }
        ] as [SavedFilterContentAdvanced])
      : []

    if (loadMetrics) {
      const filter: FeedbackListQueryParams = data.error ? {} : { context: data.context }
      fetchMetrics(filter)
    }

    const unmappedArea: AreaOfInterestData = {
      id: data.filter_id,
      filterId: data.filter_id,
      name: snakeToTitle(data.name),
      content,
      context: data.context,
      createdBy: '',
      opportunityCount: data.opportunities?.length ?? 0,
      metrics: [],
      opportunities: [],
      isUnmappedArea: true,
      advanced: true,
      error: data.error || null
    }

    if (currentInterestArea?.id === unmappedArea.id)
      setCurrentInterestArea({ ...currentInterestArea, ...unmappedArea })

    return unmappedArea
  }

  const { data, isLoading, ...query } = useQuery({
    queryKey,
    queryFn,
    enabled: enabled && !isSourcesLoading
  })

  return {
    unmappedArea: data,
    queryKey,
    isLoading,
    sources: sourcesData?.values ?? [],
    ...query
  }
}

export default useUnmappedAreaQuery
