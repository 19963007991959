import Dialog, { DialogDescription } from '@/components/atoms/dialog'
import TextComponent from '@/components/atoms/text'
import UserService from '@/services/UserService'
import { useUserStore } from '@/store'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import InviteByEmail from './InviteByEmail'
import { InviteOptionsContainer } from './InviteOptions.styles'
import { ModalContainer, ModalTitle } from './ManageUsersModal.styles'
import UsersList from './UsersList'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
}

const ManageUsersModal = ({ open, onOpenChange }: Props) => {
  const [isSedingInvites, setIsSendingInvites] = useState(false)
  const { t } = useTranslation()

  const currentUser = useUserStore(state => state.currentUser, shallow)
  const loadUsers = useUserStore(state => state.loadUsers)
  const users = useUserStore(state => state.users, shallow)

  const usersEmails = useMemo(
    () => [currentUser?.email, ...users.map(user => user.email)].filter(Boolean) as string[],
    [currentUser, users]
  )

  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const { logException } = useLogging({ context: 'manage-users-modal' })

  const onDialogOpenChange = (open: boolean) => {
    onOpenChange(open)
  }

  const onCancel = () => onDialogOpenChange(false)

  const onFinishInviteByEmail = async (emails: string[]) => {
    if (!emails.length) return
    setIsSendingInvites(true)

    const emailsToSend = emails.filter(email => !usersEmails.includes(email))

    try {
      const inUseEmails: string[] = []

      for (const email of emailsToSend) {
        try {
          await UserService.invite({
            invitee_email: email.trim(),
            invitee_role: 'viewer',
            inviter_id: currentUser?.user_id || '',
            inviter_name: currentUser?.name || ''
          })
        } catch (error) {
          if ((error as Error).message === 'email_already_in_use') {
            inUseEmails.push(email)
          } else {
            logException(error, { message: 'Failed to invite user' })
            throw error
          }
        }
      }

      await loadUsers()

      setIsSendingInvites(false)
      inUseEmails.forEach(email => {
        addErrorToast({
          id: `${email}-error`,
          text: `Could not invite e-mail ${email} as it is already in use.`,
          duration: 3500
        })
      })

      if (inUseEmails.length && inUseEmails.length === emails.length) {
        throw new Error('All emails failed')
      } else {
        addSuccessToast({
          text: t('invitesSentSuccessfully')
        })
      }
    } catch (error) {
      logException(error, { message: t('failedToInviteUser') })
      setIsSendingInvites(false)
      throw error
    }
  }

  return (
    <Dialog
      align="top-center"
      closeIcon
      onOpenChange={onDialogOpenChange}
      open={open}
      width="medium"
    >
      <ModalContainer direction="column">
        <ModalTitle as="p">
          <TextComponent typeface="textH3SmallHeading">{t('addNewUser')}</TextComponent>
        </ModalTitle>
        <DialogDescription>
          <TextComponent className="modal-description" typeface="uiComponentsSmallRegular">
            {t('useCommasToAddMultipleEmails')}
          </TextComponent>
        </DialogDescription>

        <InviteOptionsContainer bordered>
          <InviteByEmail
            isSending={isSedingInvites}
            onCancel={onCancel}
            onFinish={onFinishInviteByEmail}
          />
        </InviteOptionsContainer>

        <UsersList />
      </ModalContainer>
    </Dialog>
  )
}

export default ManageUsersModal
