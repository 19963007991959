import { colors } from '@/theme'
import { GenericFilter, NumberFilterValue } from '@/types/filters/AdvancedFilters'

const sentimentColor = {
  positive: colors.feedbackPositivePure,
  negative: colors.feedbackNegativePure,
  neutral: colors.neutralLowLight
}

const sentimentBackgroundColor = {
  positive: colors.feedbackPositiveLight,
  negative: colors.feedbackNegativeLight,
  neutral: colors.neutralHighMedium
}

export function getSentimentName(sentiment: number | null): SimpleSentiment {
  if (sentiment === null) return 'neutral'
  if (sentiment > 0) return 'positive'
  if (sentiment < 0) return 'negative'
  return 'neutral'
}

export function getSentimentValue(sentiment: SimpleSentiment): number {
  if (sentiment === 'positive') return 1
  if (sentiment === 'negative') return -1
  if (sentiment === 'neutral') return 0
  return 0
}

export function getSentimentColor(sentiment: number | null) {
  const sentimentName = getSentimentName(sentiment ?? 0)

  return sentimentColor[sentimentName]
}

export function getSentimentBackgroundColor(sentiment: number | null) {
  const sentimentName = getSentimentName(sentiment ?? 0)

  return sentimentBackgroundColor[sentimentName]
}

export enum Sentiment {
  Negative = 'negative',
  MostlyNegative = 'mostlyNegative',
  MostlyNeutral = 'mostlyNeutral',
  MostlyPositive = 'mostlyPositive',
  Positive = 'positive'
}

export const sentimentToFilter = (sentiment: Sentiment): GenericFilter => {
  if (sentiment === Sentiment.Negative) {
    return {
      name: 'negative_ratio',
      type: 'number',
      value: { $eq: 1 },
      path: 'negative_ratio',
      status: 'valid'
    }
  } else if (sentiment === Sentiment.MostlyNegative) {
    return {
      name: 'negative_ratio',
      type: 'number',
      value: { $gte: 0.5, $lte: 1 },
      path: 'negative_ratio',
      status: 'valid'
    }
  } else if (sentiment === Sentiment.MostlyNeutral) {
    return {
      name: 'neutral_ratio',
      type: 'number',
      value: { $gte: 0.5, $lte: 1 },
      path: 'neutral_ratio',
      status: 'valid'
    }
  } else if (sentiment === Sentiment.MostlyPositive) {
    return {
      name: 'positive_ratio',
      type: 'number',
      value: { $gte: 0.5, $lte: 1 },
      path: 'positive_ratio',
      status: 'valid'
    }
  } else {
    // sentiment === Sentiment.Positive
    return {
      name: 'positive_ratio',
      type: 'number',
      value: { $eq: 1 },
      path: 'positive_ratio',
      status: 'valid'
    }
  }
}

export function filterToSentiment(filter: GenericFilter): Sentiment | undefined {
  if (!filter.value) return
  const value = filter.value as NumberFilterValue
  if (filter.name === 'negative_ratio') {
    if (value.$eq === 1) return Sentiment.Negative
    if (value.$gte === 0.5 && value.$lte === 1) return Sentiment.MostlyNegative
  }
  if (filter.name === 'positive_ratio') {
    if (value.$eq === 1) return Sentiment.Positive
    if (value.$gte === 0.5 && value.$lte === 1) return Sentiment.MostlyPositive
  }
  if (filter.name === 'neutral_ratio') {
    if (value.$gte === 0.5 && value.$lte === 1) return Sentiment.MostlyNeutral
  }

  return
}

export const sentimentFilterNames = ['positive_ratio', 'neutral_ratio', 'negative_ratio']
