import { CSS, styled } from '@/theme'
import FlexContainer from '../flex-container'
import { ReactNode } from 'react'
import Text from '../text'

const DividerContainer = styled(FlexContainer, {
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '$micro',
  height: '$xxs',
  my: '$md',

  '&:first-child': {
    mt: '0'
  }
})

const Divider = styled('div', {
  height: 1,
  display: 'flex',
  flex: 'auto',
  bc: '$neutralHighPure'
})

interface Props {
  children: ReactNode
  css?: CSS
}

const TextDivider = ({ children, css }: Props) => {
  return (
    <DividerContainer css={css}>
      <Divider />
      <Text
        fontSize="micro"
        fontWeight="regular"
        color="neutralLowLight"
        css={{ lineHeight: '16px' }}
      >
        {children}
      </Text>
      <Divider />
    </DividerContainer>
  )
}

export default TextDivider
