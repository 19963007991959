import Button from '@/components/atoms/button'
import DatePicker from '@/components/atoms/date-picker'
import Dialog, { DeleteDialog } from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import IconButton from '@/components/atoms/icon-button'
import Input, { InputErrorMessage } from '@/components/atoms/input'
import Select, { SelectOption } from '@/components/atoms/select'
import Text from '@/components/atoms/text'
import { styled } from '@/theme'
import { TimeSeriesAnnotation } from '@/types/time-series/TimeSeries'
import { getLocalTimeZone, parseDate } from '@internationalized/date'
import { TrashSimple } from '@phosphor-icons/react'
import { DateValue } from '@react-aria/calendar'
import moment from 'moment'
import { useState } from 'react'
import { ANNOTATION_NAME_LIMIT, annotationLimitMessage } from './annotationUtils'
import useUser from '@/hooks/useUser'
import { dateShortMonthFormat } from '@/utils/date'
import { useTranslation } from 'react-i18next'
import { DialogTitleDefault } from '@/components/atoms/dialog/Dialog'

const StepCircle = styled('span', {
  size: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '$nano',
  fontWeight: 500,
  borderRadius: '50%',

  variants: {
    active: {
      false: {
        color: '$brandPrimaryMedium',
        background: '$brandPrimaryLight'
      },
      true: {
        color: '$neutralHighLight',
        background: '$brandPrimaryPure'
      }
    }
  },

  defaultVariants: {
    active: false
  }
})

const StepName = styled('span', {
  fontSize: '$xxxs',
  variants: {
    active: {
      false: {
        color: '$brandPrimaryMedium'
      },
      true: {
        color: '$brandPrimaryPure'
      }
    }
  },

  defaultVariants: {
    active: false
  }
})

interface Props {
  open: boolean
  onOpenChange: (open: boolean) => void
  annotations: TimeSeriesAnnotation[]
  onEditAnnotation: (data: TimeSeriesAnnotation) => void
  onDeleteAnnotation: (annotation: TimeSeriesAnnotation) => void
}

const EditAnnotationModal = ({
  open,
  onOpenChange,
  annotations,
  onEditAnnotation,
  onDeleteAnnotation
}: Props) => {
  const [step, setStep] = useState(0)

  const [selectedAnnotation, setSelectedAnnotation] = useState<TimeSeriesAnnotation | undefined>(
    undefined
  )

  const { t } = useTranslation()

  const [description, setDescription] = useState(annotations[0]?.description ?? '')

  const stringDate = annotations[0]?.annotationDate.toISOString().slice(0, 10) ?? undefined
  const [date, setDate] = useState<DateValue | undefined>(
    stringDate ? parseDate(stringDate) : undefined
  )

  const onSelectAnnotation = (annotation: TimeSeriesAnnotation) => {
    setSelectedAnnotation(annotation)
    setDescription(annotation.description)
    const annotationStringDate = annotation.annotationDate.toISOString().slice(0, 10)
    setDate(annotationStringDate ? parseDate(annotationStringDate) : undefined)
  }

  const descriptionExceedsLimit = description.length > ANNOTATION_NAME_LIMIT
  const isDisabled = !description || !date || annotations.length === 0
  const showDeleteIcon = annotations.length === 1 || selectedAnnotation

  const currentAnnotation = selectedAnnotation ?? annotations[0]

  const annotationOptions: SelectOption[] = annotations.map(annotation => ({
    value: annotation.annotationId,
    text: annotation.name
  }))
  const selectedAnnotationId = selectedAnnotation?.annotationId
  const onSelectAnnotationChange = (value: string) => {
    const annotation = annotations.find(annotation => annotation.annotationId === value)
    if (!annotation) return
    onSelectAnnotation(annotation)
  }

  const onEditClick = () => {
    if (isDisabled) return
    onEditAnnotation({
      ...currentAnnotation,
      description,
      name: description,
      annotationDate: moment(date.toString()),
      timestamp: moment(date.toString())
    })
    onOpenChange(false)
  }

  const [annotationToDelete, setAnnotationToDelete] = useState<TimeSeriesAnnotation | null>(null)

  const onDeleteClick = () => {
    setAnnotationToDelete(currentAnnotation)
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  if (annotationToDelete) {
    return (
      <DeleteDialog
        cancelText={t('cancel')}
        confirmText={t('delete')}
        description={t(
          'deletingWillPermanentlyEraseThisAnnotationAndItsAssociatedDataPleaseProceedWithCaution'
        )}
        isDeleting={false}
        onConfirmDelete={() => onDeleteAnnotation(annotationToDelete)}
        onOpenChange={() => setAnnotationToDelete(null)}
        open={Boolean(annotationToDelete)}
        title={t('deleteAnnotationQuestion')}
      />
    )
  }

  const action = isManager ? t('annotation_edit') : t('annotation_view')
  const title = isManager ? t('editAnnotation') : t('annotation')

  return (
    <Dialog align="center" closeIcon onOpenChange={onOpenChange} open={open} width="regular">
      <FlexContainer direction="column" gap="xs">
        <FlexContainer alignItems="center" justifyContent="spaceBetween">
          <DialogTitleDefault>{title}</DialogTitleDefault>
          {showDeleteIcon && isManager && (
            <IconButton color="danger" onClick={onDeleteClick}>
              <TrashSimple />
            </IconButton>
          )}
        </FlexContainer>
        {annotations.length > 1 && (
          <FlexContainer direction="column" gap="xxs">
            <FlexContainer alignItems="center" gap="xxs">
              <FlexContainer alignItems="center" gap="micro">
                <StepCircle active={step === 0}>1</StepCircle>
                <StepName active={step === 0}>{t('selectAnnotation')}</StepName>
              </FlexContainer>
              <FlexContainer alignItems="center" gap="micro">
                <StepCircle active={step === 1}>2</StepCircle>
                <StepName active={step === 1}>{isManager ? t('edit') : t('view')}</StepName>
              </FlexContainer>
            </FlexContainer>
            <Divider line="solid" />
          </FlexContainer>
        )}

        {step === 0 && annotations.length > 1 && (
          <>
            <FlexContainer direction="column" gap="xxs">
              <Text as="label" typeface="subtitleRegularXXXS">
                {t('whichAnnotationWouldYouLikeToEditView', { action })}
              </Text>
              <Select
                onValueChange={onSelectAnnotationChange}
                options={annotationOptions}
                value={selectedAnnotationId}
              />
            </FlexContainer>
            <Divider line="solid" />
            <Button
              disabled={!selectedAnnotation}
              fullWidth
              onClick={() => setStep(1)}
              size="small"
            >
              {t('next')}
            </Button>
          </>
        )}

        {(step === 1 || annotations.length === 1) && (
          <>
            <FlexContainer css={{ position: 'relative' }} direction="column" gap="xxs">
              <Text as="label" typeface="subtitleRegularXXXS">
                {t('description')}
              </Text>
              {isManager ? (
                <>
                  <Input
                    error={descriptionExceedsLimit}
                    onChange={e => setDescription(e.target.value)}
                    placeholder={t('egReleaseMadeOnTheBlackFriday')}
                    type="text"
                    value={description}
                  />
                  {descriptionExceedsLimit && (
                    <InputErrorMessage css={{ position: 'absolute', bottom: -18 }}>
                      {annotationLimitMessage}
                    </InputErrorMessage>
                  )}
                </>
              ) : (
                <Text as="p">{description}</Text>
              )}
            </FlexContainer>

            <FlexContainer direction="column" gap="xxs">
              <Text as="label" typeface="subtitleRegularXXXS">
                {t('date')}
              </Text>
              {isManager ? (
                <DatePicker onChange={setDate} value={date} />
              ) : (
                <Text as="p">
                  {date ? dateShortMonthFormat(date.toDate(getLocalTimeZone())) : ''}
                </Text>
              )}
            </FlexContainer>

            {isManager && (
              <>
                <Divider line="solid" />

                <Button disabled={isDisabled} fullWidth onClick={onEditClick} size="small">
                  {t('save')}
                </Button>
              </>
            )}
          </>
        )}
      </FlexContainer>
    </Dialog>
  )
}

export default EditAnnotationModal
