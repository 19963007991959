import InitiativesService from '@/services/InitiativesService'
import useInitiativesFiltersStore from '@/store/initiatives/useInitiativesFiltersStore'
import { InitiativeRequests } from '@/types/initiatives'
import { InitiativeRawData } from '@/types/initiatives/InitiativeRequests'
import { InitiativeItem, InitiativeStatus } from '@/types/initiatives/Initiatives'
import { stringToDate } from '@/utils/date'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import useLogging from '../useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'
import { queryClient } from '@/plugins/reactQueryClient'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import { OPPORTUNITIES_KEY_PREFIX } from '../opportunity/useAllOpportunitiesQuery'
import useOpportunityStore from '@/store/useOpportunityStore'

export const mapInitiative = (data: InitiativeRawData): InitiativeItem => ({
  id: data.initiative_id,
  name: data.name,
  description: data.description,
  status: data.status,
  opportunities: data.opportunities ?? [],
  owner: data.owner_id,
  assignees: data.assigns_to ?? [],
  pinnedFeedbacks: data.pinned_feedbacks ?? [],
  dueDate: stringToDate(data.due_date),
  releaseDate: stringToDate(data.release_date),
  taskLink: data.task_link,
  createdAt: stringToDate(data.created_at),
  createdBy: data.created_by,
  updatedAt: stringToDate(data.updated_at),
  updatedBy: data.updated_by
})

const INITIATIVES_KEY_PREFIX = 'initiatives'

interface Params {
  enabled?: boolean
  useCurrentInitiative?: boolean
}

interface CreateParams {
  name: string
  opportunityId?: string
}

interface UpdateParams {
  initiativeId: string
  name?: string
  description?: string
  dueDate?: string
  owner?: string
  status?: InitiativeStatus
  taskLink?: string
  releaseDate?: string
}

interface AssociateOpportunitiesParams {
  initiativeId: string
  opportunityIds: string[]
}

interface AssigneeInitiativeParams {
  initiativeId: string
  assigneeId: string
}

interface AssociateFeedbackParams {
  initiativeId: string
  feedbackIds: string[]
}

const defaultParams: Params = {
  enabled: true,
  useCurrentInitiative: false
}

export const useInitiatives = ({
  enabled = defaultParams.enabled,
  useCurrentInitiative = defaultParams.useCurrentInitiative
}: Params = defaultParams) => {
  const { logException } = useLogging({ context: 'initiatives' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)

  const currentInitiative = useInitiativeStore(state => state.currentInitiative)
  const setCurrentInitiative = useInitiativeStore(state => state.setCurrentInitiative)

  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const { search, owner, assignee, status } = useInitiativesFiltersStore(
    state => ({
      search: state.search,
      owner: state.owner,
      assignee: state.assignee,
      status: state.status
    }),
    shallow
  )

  const hasFilters = useMemo(() => {
    return [search, owner, assignee, status].some(filter => filter.length > 0)
  }, [search, owner, assignee, status])

  const queryKey = useMemo(
    () => [INITIATIVES_KEY_PREFIX, { search, owner, assignee, status }],
    [search, owner, assignee, status]
  )

  const queryFn = async () => {
    const params: InitiativeRequests.SearchInitiativesParams = {
      name: search,
      owner_ids: owner,
      assignee_ids: assignee,
      statuses: status,
      limit: 100
    }

    const [error, response] = await InitiativesService.search(params)
    if (error) throw error

    return response
  }

  const { data, isLoading, ...query } = useQuery({
    queryKey,
    queryFn,
    enabled
  })

  const initiatives = useMemo(() => {
    if (!data) return []
    return data.initiatives.map(mapInitiative)
  }, [data])

  const isEmpty = useMemo(() => {
    return !isLoading && initiatives.length === 0 && !hasFilters
  }, [initiatives, isLoading, hasFilters])

  const noResult = useMemo(() => !isLoading && initiatives.length === 0, [initiatives, isLoading])

  const { mutate: createInitiative, isLoading: isCreateInitiativeLoading } = useMutation({
    mutationKey: ['create-initiative'],
    mutationFn: async ({ name, opportunityId }: CreateParams) => {
      const [error, newInitiative] = await InitiativesService.create({
        name,
        opportunity_id: opportunityId
      })

      if (error) throw error
      return { newInitiative }
    },
    onSettled: () => query.refetch(),
    onSuccess: ({ newInitiative }) => {
      addSuccessToast({ text: 'Initiative created successfully.' })

      queryClient.invalidateQueries({ queryKey: [OPPORTUNITIES_KEY_PREFIX] })
      queryClient.invalidateQueries({ queryKey: ['opportunities'] })

      if (currentOpportunity) {
        setCurrentOpportunity({
          ...currentOpportunity,
          initiatives: [...currentOpportunity.initiatives, newInitiative.initiative_id]
        })
      }
    },
    onError: error => {
      const message = 'Failed to create initiative.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: updateInitiative, isLoading: isUpdateInitiativeLoading } = useMutation({
    mutationKey: ['update-initiative'],
    mutationFn: async ({
      initiativeId,
      name,
      description,
      dueDate,
      owner,
      status,
      taskLink,
      releaseDate
    }: UpdateParams) => {
      const [error] = await InitiativesService.update(initiativeId, {
        name,
        description,
        due_date: dueDate,
        owner_id: owner,
        status,
        task_link: taskLink,
        release_date: releaseDate
      })

      if (error) throw error
    },
    onMutate: params => {
      const previousInitiatives =
        queryClient.getQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey)

      const initiativeFound = previousInitiatives?.initiatives.find(
        i => i.initiative_id === params.initiativeId
      )

      const mappedInitiative = initiativeFound ? mapInitiative(initiativeFound) : undefined

      const initiative = useCurrentInitiative ? currentInitiative : mappedInitiative

      if (!initiative) return

      const newInitiative: InitiativeRawData = {
        initiative_id: params.initiativeId,
        name: params.name ?? initiative.name,
        description: params.description ?? initiative.description,
        opportunities: initiative.opportunities,
        status: params.status ?? initiative.status,
        due_date: params.dueDate ?? initiative.dueDate?.toISOString(),
        owner_id: params.owner ?? initiative.owner,
        assigns_to: initiative.assignees,
        task_link: params.taskLink ?? initiative.taskLink,
        release_date: params.releaseDate ?? initiative.releaseDate?.toISOString(),
        created_at: initiative.createdAt?.toISOString() ?? new Date().toISOString(),
        created_by: initiative.createdBy,
        updated_at: new Date().toISOString(),
        updated_by: initiative.updatedBy
      }

      queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey, old => {
        if (!old) return
        return {
          ...old,
          initiatives: old.initiatives.map(i =>
            i.initiative_id === params.initiativeId ? newInitiative : i
          )
        }
      })

      setCurrentInitiative(mapInitiative(newInitiative))

      return { previousInitiatives, oldInitiative: initiative }
    },
    onSuccess: () => {
      addSuccessToast({ text: 'Initiative updated successfully.' })
    },
    onSettled: () => query.refetch(),
    onError: (error, _, context) => {
      if (context) {
        queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(
          queryKey,
          context.previousInitiatives
        )
        setCurrentInitiative(context.oldInitiative)
      }

      const message = 'Failed to update initiative.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: deleteInitiative, isLoading: isDeleteInitiativeLoading } = useMutation({
    mutationKey: ['delete-initiative'],
    mutationFn: async (initiativeId: string) => {
      const [error] = await InitiativesService.deleteInitiative(initiativeId)
      if (error) throw error
    },
    onMutate: initiativeId => {
      const previousInitiatives =
        queryClient.getQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey)
      queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey, old => {
        if (!old) return
        return {
          ...old,
          initiatives: old.initiatives.filter(i => i.initiative_id !== initiativeId)
        }
      })

      return { previousInitiatives }
    },
    onSuccess: (_, initiativeId) => {
      addSuccessToast({ text: 'Initiative successfully deleted.' })

      queryClient.invalidateQueries({ queryKey: [OPPORTUNITIES_KEY_PREFIX] })
      queryClient.invalidateQueries({ queryKey: ['opportunities'] })

      if (currentOpportunity) {
        setCurrentOpportunity({
          ...currentOpportunity,
          initiatives: currentOpportunity.initiatives.filter(i => i !== initiativeId)
        })
      }
    },
    onSettled: () => query.refetch(),
    onError: (error, _, context) => {
      if (context?.previousInitiatives) {
        queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(
          queryKey,
          context.previousInitiatives
        )
      }

      const message = 'Failed to delete initiative.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: associateOpportunities, isLoading: isAssociateOpportunitiesLoading } =
    useMutation({
      mutationKey: ['associate-opportunities'],
      mutationFn: async ({ initiativeId, opportunityIds }: AssociateOpportunitiesParams) => {
        const [error] = await InitiativesService.upsertOpportunities(initiativeId, {
          opportunities: opportunityIds,
          sync: true
        })

        if (error) throw error
      },
      onMutate: ({ initiativeId, opportunityIds }) => {
        const oldInitiative = currentInitiative

        const previousInitiatives =
          queryClient.getQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey)
        queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey, old => {
          if (!old) return
          return {
            ...old,
            initiatives: old.initiatives.map(initiative =>
              initiative.initiative_id === initiativeId
                ? {
                    ...initiative,
                    opportunities: opportunityIds
                  }
                : initiative
            )
          }
        })

        if (oldInitiative) {
          setCurrentInitiative({ ...oldInitiative, opportunities: opportunityIds })
        }

        return { previousInitiatives, oldInitiative }
      },
      onSuccess: () => {
        addSuccessToast({ text: 'Initiative updated successfully.' })
      },
      onSettled: () => query.refetch(),
      onError: (error, _, context) => {
        if (context) {
          queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(
            queryKey,
            context.previousInitiatives
          )
          setCurrentInitiative(context.oldInitiative)
        }

        const message = 'Failed to associate opportunities to initiative.'
        logException(error, { message })
        addErrorToast({ text: message })
      }
    })

  const { mutate: assigneeInitiative, isLoading: isAssigneeInitiativeLoading } = useMutation({
    mutationKey: ['assignee-initiative'],
    mutationFn: async ({ initiativeId, assigneeId }: AssigneeInitiativeParams) => {
      const [error] = await InitiativesService.upsertAssignee(initiativeId, {
        assigns_to: [assigneeId],
        sync: true
      })

      if (error) throw error
    },
    onMutate: ({ initiativeId, assigneeId }) => {
      const oldInitiative = currentInitiative

      const previousInitiatives =
        queryClient.getQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey)
      queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey, old => {
        if (!old) return
        return {
          ...old,
          initiatives: old.initiatives.map(initiative =>
            initiative.initiative_id === initiativeId
              ? {
                  ...initiative,
                  assigns_to: [assigneeId]
                }
              : initiative
          )
        }
      })

      if (oldInitiative) {
        setCurrentInitiative({ ...oldInitiative, assignees: [assigneeId] })
      }

      return { previousInitiatives, oldInitiative }
    },
    onSuccess: () => {
      addSuccessToast({ text: 'Initiative updated successfully.' })
    },
    onSettled: () => query.refetch(),
    onError: (error, _, context) => {
      if (context) {
        queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(
          queryKey,
          context.previousInitiatives
        )
        setCurrentInitiative(context?.oldInitiative)
      }

      const message = 'Failed to assignee initiative.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  const { mutate: associateFeedback, isLoading: isAssociateFeedbackLoading } = useMutation({
    mutationKey: ['associate-feedback'],
    mutationFn: async ({ initiativeId, feedbackIds }: AssociateFeedbackParams) => {
      const [error] = await InitiativesService.upsertFeedback(initiativeId, {
        feedback_ids: feedbackIds,
        sync: true
      })

      if (error) throw error
    },
    onMutate: ({ initiativeId, feedbackIds }) => {
      const oldInitiative = currentInitiative

      const previousInitiatives =
        queryClient.getQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey)
      queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(queryKey, old => {
        if (!old) return
        return {
          ...old,
          initiatives: old.initiatives.map(initiative =>
            initiative.initiative_id === initiativeId
              ? {
                  ...initiative,
                  pinned_feedbacks: feedbackIds
                }
              : initiative
          )
        }
      })

      if (oldInitiative) {
        setCurrentInitiative({ ...oldInitiative, pinnedFeedbacks: feedbackIds })
      }

      return { previousInitiatives, oldInitiative }
    },
    onSuccess: () => {
      addSuccessToast({ text: 'Initiative updated successfully.' })
    },
    onSettled: () => query.refetch(),
    onError: (error, _, context) => {
      if (context) {
        queryClient.setQueryData<InitiativeRequests.SearchInitiativesResponse>(
          queryKey,
          context.previousInitiatives
        )
        setCurrentInitiative(context.oldInitiative)
      }

      const message = 'Failed to associate feedback to initiative.'
      logException(error, { message })
      addErrorToast({ text: message })
    }
  })

  return {
    initiatives,
    ...query,
    isLoading,
    isEmpty,
    noResult,
    createInitiative,
    isCreateInitiativeLoading,
    updateInitiative,
    isUpdateInitiativeLoading,
    deleteInitiative,
    isDeleteInitiativeLoading,
    associateOpportunities,
    isAssociateOpportunitiesLoading,
    assigneeInitiative,
    isAssigneeInitiativeLoading,
    associateFeedback,
    isAssociateFeedbackLoading
  }
}
