import { API_URL } from '@/config'
import RequestHandler, { RequestReturnType } from './RequestHandlers/NewRequest'
import { ChatData } from '@/types/assistant/Assistant'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { FeedRequests } from '@/types/feed'
import { ExplorationStats } from '@/types/feed/Stats'
import { buildSafeSearchParams } from '@/utils/searchParams'
import { stringToDate } from '@/utils/date'
import { TimeSeries } from '@/types/time-series/TimeSeries'
import moment from 'moment'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { OpportunityStatsDataV2 } from '@/types/filters/FilterRequests'
import { SUMMARY_PARAMS_KEY, SummaryParamsData } from '@/types/params/SummaryParams'

const chatSortingParams = { sort: 'random', random_seed: 0 }

export default class FeedService {
  static async chatStream(
    params: FeedRequests.ChatParams,
    options?: {
      signal?: AbortSignal
    }
  ) {
    const searchParams = buildSafeSearchParams({ ...params, ...chatSortingParams })
    return fetch(`${API_URL}feed/chat?${searchParams.toString()}`, {
      method: 'GET',
      signal: options?.signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('access_token')}`
      }
    })
  }

  static async chatData(
    params: FeedRequests.ChatParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<ChatData>> {
    const [error, data] = await RequestHandler.get<FeedRequests.ChatResponse>(
      '/feed/chat',
      { ...params, ...chatSortingParams },
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        feedbackList: data.data.map(feedback => ({
          source: feedback.source,
          kind: {
            ...feedback.kind,
            prettyName: getFeedbackKindPrettyName(feedback.kind.name)
          },
          id: feedback.id,
          language: feedback.language,
          text: feedback.text,
          customFields: feedback.custom_fields,
          updatedAt: stringToDate(feedback.updated_at),
          postedAt: stringToDate(feedback.posted_at),
          messages: feedback.messages
            ? {
                total: feedback.messages.total,
                firstPostedAt: stringToDate(feedback.messages.first_posted_at),
                lastPostedAt: stringToDate(feedback.messages.last_posted_at)
              }
            : undefined,
          accounts: feedback.accounts,
          users: feedback.users
        })),
        feedbackTakenIntoAccount: data.feedback_taken_into_account,
        feedbackTotalHits: data.feedback_total_hits,
        tokenCountPrediction: {
          feedbackList: data.token_count_prediction.feedback_list,
          history: data.token_count_prediction.history,
          total: data.token_count_prediction.total
        },
        answer: data.answer,
        question: data.question
      }
    ]
  }

  static async impactStats(
    params: FeedRequests.ImpactStatsParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<ExplorationStats>> {
    const [error, data] = await RequestHandler.get<FeedRequests.ImpactStatsResponse>(
      '/feed/stats/impact',
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        support: {
          percent: data.support.percent,
          compare: data.support.compare,
          overallPercent: data.support.overall_percent
        },
        satisfaction: {
          percent: data.satisfaction.percent,
          compare: data.satisfaction.compare,
          overallPercent: data.satisfaction.overall_percent
        },
        all: data.all
      }
    ]
  }

  static async summary(
    params: FeedRequests.SummaryParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<FeedRequests.SummaryResponse>> {
    const actualParams = { ...params }

    const storedItemParams = localStorage.getItem(SUMMARY_PARAMS_KEY)
    if (storedItemParams && storedItemParams !== 'undefined') {
      const storedParams: SummaryParamsData = JSON.parse(storedItemParams)

      if (storedParams.enabled) {
        actualParams.focus = storedParams.focus ? [storedParams.focus] : params.focus
        actualParams.model = storedParams.model ? storedParams.model : params.model
        actualParams.prompt = storedParams.prompt ? storedParams.prompt : params.prompt

        actualParams.max_words = storedParams.max_words
          ? Number(storedParams.max_words)
          : params.max_words

        actualParams.sample_per_kind = storedParams.sample_per_kind
          ? Number(storedParams.sample_per_kind)
          : params.sample_per_kind
      }
    }

    return await RequestHandler.get<FeedRequests.SummaryResponse>('/feed/summary', actualParams, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async timeseries(
    params: FeedRequests.TimeSeriesQueryParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<TimeSeries>> {
    const [error, data] = await RequestHandler.get<FeedRequests.TimeSeriesResponse>(
      '/feed/timeseries',
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )

    if (error) return [error, undefined]

    return [
      undefined,
      {
        timestamps: data.timestamps.map(timestamp => moment.unix(timestamp).utc()),
        values: data.values,
        groups: data.groups
      }
    ]
  }

  static async impactStatsV2(
    params: FeedbackListQueryParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<OpportunityStatsDataV2>> {
    return RequestHandler.get<OpportunityStatsDataV2>('/feed/stats/v2/impact', params, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }
}
