import { useEffect, useState } from 'react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog/Dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Select from '@/components/atoms/select'
import Text from '@/components/atoms/text'
import CustomFieldsService from '@/services/CustomFieldsServices'
import { useUserStore } from '@/store'
import useCustomFieldsStore from '@/store/useCustomFieldsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { CreateCustomFieldPayload, UpdateCustomFieldPayload } from '@/types/integrations'
import useUser from '@/hooks/useUser'
import NoPermission from '../no-permission/NoPermission'
import { capitalizeFirst } from '@/utils/letterCase'
import useLogging from '@/hooks/useLogging'
import { useTranslation } from 'react-i18next'

interface CustomFieldFormProps {
  onOpenChange?(open: boolean): void
  open?: boolean
}

function CustomFieldForm(props: CustomFieldFormProps) {
  const { logException } = useLogging({ context: 'custom-field-form' })
  const { customFieldId } = useParams()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const customFields = useCustomFieldsStore(state => state.customFields)
  const loadCustomFields = useCustomFieldsStore(state => state.loadCustomFields)
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const currentUser = useUserStore(state => state.currentUser)

  const [isLoading, setIsLoading] = useState(false)

  const customField = customFields.find(field => field.id === customFieldId)
  const isEdit = Boolean(customField)

  const [customFieldsTypes, setCustomFieldsTypes] = useState<string[]>([])
  const [isLoadingTypes, setIsLoadingTypes] = useState(false)

  useEffect(() => {
    const getCustomFieldsTypes = async () => {
      setIsLoadingTypes(true)
      const [error, data] = await CustomFieldsService.getCustomFieldsTypes()
      setIsLoadingTypes(false)
      if (error) return

      setCustomFieldsTypes(data.map(type => type.name))
    }
    getCustomFieldsTypes()
  }, [])

  function close(open: boolean) {
    if (props.onOpenChange) {
      props.onOpenChange(open)
    } else {
      navigate('/settings/custom_fields_deprecated')
    }
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    try {
      setIsLoading(true)

      const formData = Object.fromEntries(new FormData(event.currentTarget))

      if (isEdit) {
        const payload = {
          ...formData,
          type: (formData.type as string).toLowerCase(),
          id: customFieldId
        } as UpdateCustomFieldPayload

        await CustomFieldsService.updateCustomField(payload)
      } else {
        const payload = {
          ...formData,
          type: (formData.type as string).toLowerCase(),
          organization_id: currentUser?.organization_id as string
        } as CreateCustomFieldPayload

        await CustomFieldsService.createCustomField(payload)
      }

      addSuccessToast({
        text: `Custom field was ${isEdit ? 'edited' : 'created'} successfully`
      })

      loadCustomFields()
      close(false)
    } catch (error) {
      const message = `Failed to ${isEdit ? 'edit' : 'create'} custom field`
      logException(error, { message })
      addErrorToast({ text: message })
    } finally {
      setIsLoading(false)
    }
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.source.includes('manager')

  if (!isManager && currentUser) {
    return <NoPermission />
  }

  return (
    <Dialog
      align="right"
      closeIcon
      modal
      onOpenChange={close}
      open={props.open ?? true}
      useFullHeight
      title={isEdit ? t('edit') : t('createNew')}
      hiddenTitle
    >
      <FlexContainer
        as="form"
        css={{ gap: 24, padding: 32 }}
        direction="column"
        onSubmit={onSubmit}
      >
        <FlexContainer css={{ gap: 8 }} direction="column">
          <Text as="h1" typeface="titleBoldXXS">
            {isEdit ? t('edit') : t('createNew')} {t('customField')}
          </Text>
          <Text as="p" typeface="subtitleRegularNano">
            {t('thisFieldWillBeCreatedAndCanBeUsedInAnyFieldGroup')}
          </Text>
        </FlexContainer>

        <FlexContainer css={{ gap: 8 }} direction="column">
          <Text as="label" htmlFor="customFieldName" typeface="subtitleRegularXXXS">
            {t('giveANameToYourField')}
          </Text>
          <Input
            defaultValue={customField?.name}
            id="customFieldName"
            name="name"
            placeholder={t('fieldName')}
            required
          />
        </FlexContainer>

        <FlexContainer css={{ gap: 8 }} direction="column">
          <Text as="label" htmlFor="customFieldDescription" typeface="subtitleRegularXXXS">
            {t('description')}
          </Text>
          <Input
            as="textarea"
            css={{ height: 'auto' }}
            defaultValue={customField?.description}
            id="customFieldDescription"
            name="description"
            placeholder={t('giveMoreDetails')}
            rows={4}
          />
        </FlexContainer>
        <FlexContainer css={{ gap: 8 }} direction="column">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('fieldType')}
          </Text>
          {customFieldsTypes.length > 0 ? (
            <Select
              contentAlign="start"
              defaultValue={customField?.type}
              label={t('fieldType')}
              name="type"
              options={customFieldsTypes.map(type => ({
                text: capitalizeFirst(type),
                value: type
              }))}
              placeholder={t('selectType')}
              required
            />
          ) : (
            <FlexContainer alignItems="center" justifyContent="center">
              <Text typeface="subtitleRegularXXXS">
                {isLoadingTypes ? t('loading') : t('couldNotLoadFieldTypes')}
              </Text>
            </FlexContainer>
          )}
        </FlexContainer>

        <FlexContainer css={{ gap: 16 }} direction="rowReverse">
          <Button disabled={isLoading || !customFieldsTypes.length} type="submit">
            {isLoading ? t('saving') : t('save')}
          </Button>
          <DialogPrimitive.Close asChild>
            <Button css={{ color: '$neutralLowPure' }} type="button" variant="flat">
              {t('cancel')}
            </Button>
          </DialogPrimitive.Close>
        </FlexContainer>
      </FlexContainer>
    </Dialog>
  )
}

export default CustomFieldForm
