import { IntervalOption } from '@/types/analytics/Analytics'
import { PresetPeriod } from '@/types/periods'
import {
  DateFormatter,
  DateValue,
  getLocalTimeZone,
  parseAbsoluteToLocal,
  today
} from '@internationalized/date'
import { DateRange } from '@react-types/calendar'
import moment from 'moment'

/**
 * @param dateString A valid ISO 8601 date time string in the `YYYY-MM-DDThh:mm:ss` format
 * @returns A US date time string in the `MM/DD/YYYY hh:mm:ss AM/PM` format
 */
export function formatDateTime(dateString: string) {
  const date = parseAbsoluteToLocal(`${dateString}Z`).toDate()
  const parts = new DateFormatter('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: true
  }).formatToParts(date)

  const formattedDate = parts
    .map(({ type, value }) => {
      if (type === 'literal') return value.replace(',', '')
      return value.padStart(2, '0')
    })
    .join('')

  return formattedDate
}

/**
 * @param dateString A valid ISO 8601 date time string in the `YYYY-MM-DDThh:mm:ss` format
 * @returns A US date time string in the `Jan 1, 2023` format
 */
export const dateShortMonthFormat = (date: Date, isThreadItem = false) => {
  if (isThreadItem) {
    return moment(date.toISOString()).utc().format('MMM DD, YYYY, h:mm a')
  }

  return moment(date.toISOString()).utc().format('MMM DD, YYYY')
}

/** Return formatted date range: Jan 1, 2023 - Jun 14, 2023 */
export const getFormattedDateRange = (range: DateRange) => {
  if (!range.start.toDate || !range.end.toDate) {
    return 'Custom'
  }

  const startDate = range.start.toDate(getLocalTimeZone())
  const endDate = range.end.toDate(getLocalTimeZone())
  const formattedStart = dateShortMonthFormat(startDate)
  const formattedEnd = dateShortMonthFormat(endDate)

  return `${formattedStart} - ${formattedEnd}`
}

export const dateShortMonthFormatWithTime = (value: Date) => dateShortMonthFormat(value, true)

export const intervalFormat = (date: moment.Moment, interval: IntervalOption) => {
  return {
    '1d': date.format('DD/MMM/YY'),
    '1w': date.format('DD/MMM/YY'),
    '1M': date.format('MMM/YY'),
    '1q': `Q${date.quarter()}/${date.year()}`,
    '1y': date.year()
  }[interval]
}

export const shortIntervalToLong = (interval: IntervalOption) =>
  ({
    '1d': 'day',
    '1w': 'week',
    '1M': 'month',
    '1q': 'quarter',
    '1y': 'year'
  })[interval]

export const getDifferenceInDays = (range: DateRange) => {
  const momentStart = moment(range.start.toDate(getLocalTimeZone()))
  const momentEnd = moment(range.end.toDate(getLocalTimeZone()))

  return momentEnd.diff(momentStart, 'days')
}

export const getIntervalByDateRange = (range: DateRange | null) => {
  if (!range) {
    return IntervalOption.Month
  }

  const differenceInDays = getDifferenceInDays(range)

  if (differenceInDays <= 30) {
    return IntervalOption.Day
  }

  if (differenceInDays <= 90) {
    return IntervalOption.Week
  }

  if (differenceInDays <= 180) {
    return IntervalOption.Month
  }

  if (differenceInDays <= 2 * 365) {
    return IntervalOption.Quarter
  }

  return IntervalOption.Year
}

export const dateRangeIsEqual = (a: DateRange | null, b: DateRange | null) => {
  if (a === null && b === null) {
    return true
  }

  if (a === null || b === null) {
    return false
  }

  const compareStart = a.start.toString() === b.start.toString()
  const compareEnd = a.end.toString() === b.end.toString()

  return compareStart && compareEnd
}

const getToday = () => today(getLocalTimeZone())

const periodToDateRangeMap: Record<PresetPeriod, DateRange> = {
  '1day': {
    start: getToday().subtract({ days: 1 }),
    end: getToday()
  },
  '7days': {
    start: getToday().subtract({ days: 7 }),
    end: getToday()
  },
  '30days': {
    start: getToday().subtract({ days: 30 }),
    end: getToday()
  },
  '3months': {
    start: getToday().subtract({ months: 3 }),
    end: getToday()
  },
  '6months': {
    start: getToday().subtract({ months: 6 }),
    end: getToday()
  }
}

export const getDateRangeFromNow = (period: PresetPeriod): DateRange => periodToDateRangeMap[period]

export const stringToDate = (dateString?: string) =>
  dateString ? moment(dateString).toDate() : undefined

export const startDateParam = (date: DateValue, removeTime = false) => {
  const startDateStr = moment(date.toString()).utc().startOf('day').toISOString()

  if (removeTime) {
    return startDateStr.split('T')[0]
  }

  return startDateStr
}

export const endDateParam = (date: DateValue, removeTime = false) => {
  const endDateStr = moment(date.toString()).utc().endOf('day').toISOString()

  if (removeTime) {
    return endDateStr.split('T')[0]
  }

  return endDateStr
}
