import Button from '@/components/atoms/button'
import { CollapsibleContentAnimated } from '@/components/atoms/collapsible'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import { TabItem, TabsContent } from '@/components/atoms/tabs'
import CollectionSelect from '@/components/molecules/collections/collection-select/CollectionSelect'
import CollectionContentWrapper from '@/components/molecules/collections/CollectionContentWrapper'
import PageContentScroll from '@/components/molecules/layout/PageContentScroll'
import OverallMetricsTrendline from '@/components/molecules/metrics-trendline/OverallMetricsTrendline'
import AllOpportunitiesTable from '@/components/molecules/opportunities/AllOpportunitiesTable'
import OverallMetrics from '@/components/molecules/overall-metrics'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import useUnmappedAreaQuery from '@/hooks/areaOfInterest/useUnmappedAreaQuery'
import useCollections from '@/hooks/collections/useCollections'
import useSegment from '@/hooks/useSegment'
import useUser from '@/hooks/useUser'
import { CSS } from '@/theme'
import { Plus } from '@phosphor-icons/react'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import HomeEmptyState from '@/components/molecules/area-of-interest/HomeEmptyState'
import DateFilter from '@/components/molecules/filters/DateFilter'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { FilterDatetimeValue } from '@/types/filters/Filters'
import { Period } from '@/types/periods'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import useOpportunityStore from '@/store/useOpportunityStore'
import AllAreasTable from '@/components/molecules/area-of-interest/AreaOfInterestList/AllAreasTable'

const triggerCss: CSS = {
  '&:hover': {
    h2: {
      fontWeight: '$regular'
    }
  },
  '&[data-state="active"]': {
    color: '$brandPrimaryPure',
    background: '$brandPrimaryHover',
    border: 'none',
    h2: {
      fontWeight: '$semibold'
    }
  },
  '&[data-disabled]': {
    pointerEvents: 'auto'
  }
}

const AreasAndOpportunitiesPage = () => {
  const { track } = useSegment()

  const { userPermissions } = useUser()
  const isManager = userPermissions.areas.includes('manager')

  const { t } = useTranslation()
  const { setFreeExploration } = useAreaOfInterest()

  const setCurrentInterestArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)
  const setCurrentOpportunity = useOpportunityStore(state => state.setCurrentOpportunity)

  const { areas: basicAreas, isLoading: basicAreasLoading } = useBasicAreaOfInterestQuery()

  // biome-ignore lint/correctness/useExhaustiveDependencies: remove current area when entering this page
  useEffect(() => {
    setCurrentInterestArea(undefined)
    setCurrentOpportunity(undefined)
  }, [])

  const onFreeExplorationClick = () => {
    track('areas_and_opps_create_area')
    setFreeExploration()
  }

  const { dateRange, datePeriod } = useDateFilterStore(state => ({
    dateRange: state.dateRange,
    datePeriod: state.datePeriod
  }))

  const setDateRange = useDateFilterStore(state => state.setDateFilter)
  const onDateFilterChange = (range: FilterDatetimeValue, period?: Period) => {
    track('areas_and_opps_date')
    setDateRange(range, period)
  }

  const { currentCollection } = useCollections({ enabled: false })
  const isOpportunityCollection = currentCollection?.type === 'opportunity'

  const { unmappedArea } = useUnmappedAreaQuery({
    enabled: true,
    loadMetrics: true
  })

  const tabs: TabItem[] = useMemo(
    () => [
      { text: t('areas'), value: 'areas', triggerCss, disabled: isOpportunityCollection },
      { text: t('opportunities'), value: 'opportunities', triggerCss }
    ],
    [isOpportunityCollection, t]
  )

  const [trendLineOpen, setTrendLineOpen] = useState(false)
  const onOpenTrendLineChange = (open: boolean) => {
    if (open) {
      track('areas_and_opps_show_trendline')
    }
    setTrendLineOpen(open)
  }

  const isEmpty = !basicAreasLoading && basicAreas.length === 0

  if (isEmpty) {
    return (
      <PageContentScroll>
        <HomeEmptyState onFreeExplorationClick={onFreeExplorationClick} />
      </PageContentScroll>
    )
  }

  return (
    <PageContentScroll>
      <FlexContainer alignItems="center" justifyContent="spaceBetween">
        <CollectionSelect />
        <FlexContainer alignItems="center" gap="micro">
          {isManager && !isOpportunityCollection && (
            <Link to="/exploration">
              <Button
                css={{ fontWeight: '$regular' }}
                onClick={onFreeExplorationClick}
                size="small"
                variant="link"
              >
                <span>{t('createNewArea')}</span>
                <Plus size={16} weight="bold" />
              </Button>
            </Link>
          )}
          <DateFilter
            appliedPeriod={datePeriod}
            appliedRange={dateRange}
            key={JSON.stringify(dateRange)}
            setDateRange={onDateFilterChange}
            size="small"
            triggerCss={{ pr: 0, minWidth: 140, bc: 'transparent' }}
            triggerVariant="link"
          />
        </FlexContainer>
      </FlexContainer>

      {!isOpportunityCollection && <OverallMetrics />}
      <Divider line="solid" orientation="horizontal" />

      <CollectionContentWrapper
        onOpenTrendLineChange={onOpenTrendLineChange}
        tabs={tabs}
        trendLineOpen={trendLineOpen}
        unmappedArea={unmappedArea}
      >
        <>
          <CollapsibleContentAnimated css={{ overflow: trendLineOpen ? 'visible' : 'hidden' }}>
            <OverallMetricsTrendline />
          </CollapsibleContentAnimated>
          <TabsContent asChild value="areas">
            <AllAreasTable />
          </TabsContent>
          <TabsContent asChild value="opportunities">
            <AllOpportunitiesTable />
          </TabsContent>
        </>
      </CollectionContentWrapper>
    </PageContentScroll>
  )
}

export default AreasAndOpportunitiesPage
