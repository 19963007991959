import AreaOfInterestList from '../area-of-interest/AreaOfInterestList/AreaOfInterestList'

export const TableSkeletonRows = () => (
  <>
    {Array.from({ length: 8 }).map((_, index) => (
      <AreaOfInterestList.Row key={index}>
        <AreaOfInterestList.LongCellContainer>
          <AreaOfInterestList.Bone width={200} />
        </AreaOfInterestList.LongCellContainer>
        <AreaOfInterestList.CellContainer>
          <AreaOfInterestList.Bone width={60} />
        </AreaOfInterestList.CellContainer>
        <AreaOfInterestList.CellContainer>
          <AreaOfInterestList.Bone width={70} />
        </AreaOfInterestList.CellContainer>
        <AreaOfInterestList.CellContainer>
          <AreaOfInterestList.Bone width={70} />
        </AreaOfInterestList.CellContainer>
        <AreaOfInterestList.CellContainer>
          <AreaOfInterestList.Bone width={30} />
        </AreaOfInterestList.CellContainer>
        <AreaOfInterestList.ShortCellContainer>
          <AreaOfInterestList.Bone width={30} />
        </AreaOfInterestList.ShortCellContainer>
      </AreaOfInterestList.Row>
    ))}
  </>
)

const TableSkeleton = () => (
  <AreaOfInterestList.Root css={{ width: '100%' }}>
    <AreaOfInterestList.Header>
      <AreaOfInterestList.LongCellContainer>
        <AreaOfInterestList.Bone width={50} />
      </AreaOfInterestList.LongCellContainer>
      <AreaOfInterestList.CellContainer>
        <AreaOfInterestList.Bone width={60} />
      </AreaOfInterestList.CellContainer>
      <AreaOfInterestList.CellContainer>
        <AreaOfInterestList.Bone width={90} />
      </AreaOfInterestList.CellContainer>
      <AreaOfInterestList.CellContainer>
        <AreaOfInterestList.Bone width={90} />
      </AreaOfInterestList.CellContainer>
      <AreaOfInterestList.CellContainer>
        <AreaOfInterestList.Bone width={100} />
      </AreaOfInterestList.CellContainer>
      <AreaOfInterestList.ShortCellContainer>
        <span />
      </AreaOfInterestList.ShortCellContainer>
    </AreaOfInterestList.Header>
    <TableSkeletonRows />
  </AreaOfInterestList.Root>
)

export default TableSkeleton
