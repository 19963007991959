import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import InviteForm from '@/components/molecules/invite/InviteForm'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import UserService from '@/services/UserService'
import { InvitedAccountData, InviteData } from '@/types/auth'
import Redirect from '@/components/molecules/redirect'
import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'
import useToastMessageStore from '@/store/useToastMessageStore'
import { useAuth0 } from '@auth0/auth0-react'
import { useUserStore } from '@/store'
import { AUTH_CLIENT } from '@/config'
import { Content, Illustration, InviteContainer, Logo, MainContainer, RightSide } from './styles'
import BirdsIllustration from '@/assets/drawings/birds_invite.png'
import BirdieLogo from '@/assets/birdie_logo.svg'
import useSegment from '@/hooks/useSegment'
import useLogging from '@/hooks/useLogging'
import { useTranslation, Trans } from 'react-i18next'
import { AxiosError } from 'axios'

function InvitePage() {
  const { track } = useSegment()

  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [isSending, setIsSending] = useState(false)
  const [inviteData, setInviteData] = useState<InviteData | undefined>()

  const addToast = useToastMessageStore(state => state.addToast)
  const setCurrentUser = useUserStore(state => state.setCurrentUser)

  const { logout, isAuthenticated, isLoading: isLoadingAuth0 } = useAuth0()

  const { logException } = useLogging({ context: 'invite' })

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    // if is authenticated, logout first
    if (isAuthenticated) {
      setCurrentUser(null)
      localStorage.removeItem('access_token')
      logout({ returnTo: window.location.href, client_id: AUTH_CLIENT })
    }
  }, [isAuthenticated, isLoadingAuth0])

  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  // biome-ignore lint/correctness/useExhaustiveDependencies: should happen once
  useEffect(() => {
    const hash = searchParams.get('invite_hash')
    const checkHash = async () => {
      if (!hash) {
        navigate('/')
        return
      }

      setIsLoading(true)
      const [error, data] = await UserService.getInviteByHash(hash)

      if (error) {
        const errorInstance = error.errorInstance
        if (errorInstance instanceof AxiosError) {
          const errorMessage = errorInstance.response?.data?.message ?? 'Unknown error'
          setErrorMessage(errorMessage)
          setIsLoading(false)
          return
        }

        setErrorMessage(error.message ?? 'Unknown error')
        setIsLoading(false)
        return
      }

      setInviteData(data)
      setIsLoading(false)
    }

    checkHash()
  }, [])

  const onCreate = async (accountData: InvitedAccountData) => {
    if (isSending || !inviteData) return

    try {
      setIsSending(true)
      const hash = searchParams.get('invite_hash')

      if (!hash) {
        addToast({
          id: 'create-failed',
          text: t('invalidInvite'),
          open: true,
          status: 'error'
        })
      }

      const response = await UserService.createAccount(
        inviteData.inviteeId || '',
        {
          name: accountData.firstName,
          lastname: accountData.lastName,
          password: accountData.password,
          password_confirmation: accountData.passwordConfirmation
        },
        hash as string
      )

      track('invitation_user_signup', {
        signup_date: new Date(Date.now()).toISOString(),
        user_id: inviteData.inviteeId,
        email: accountData.email,
        name: `${accountData.firstName} ${accountData.lastName}`,
        user_role: response.function,
        user_case: response.use_case,
        feedback_type: [],
        organization_id: response.organization_id,
        company_name: inviteData.organizationName
      })

      setIsSending(false)
      addToast({
        id: 'create-success',
        text: t('accountCreatedSuccessfully'),
        open: true,
        status: 'success',
        onClose: () => {
          navigate('/')
        }
      })
    } catch (error) {
      console.error(error)

      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      const { response } = error as any
      if (response && typeof response.data?.details === 'object') {
        const message = response.data?.details.message

        if (typeof message === 'string' && message.includes('PasswordStrengthError')) {
          addToast({
            id: 'create-failed',
            text: message,
            open: true,
            status: 'error'
          })

          setIsSending(false)
          return 'invalidPassword'
        }
      }

      setIsSending(false)
      const message = 'Failed to create account. Try again later.'
      addToast({
        id: 'create-failed',
        text: message,
        open: true,
        status: 'error'
      })
      logException(error, { message })
    }
  }

  // need to wait for auth0 to check session
  if (isLoading || isAuthenticated || isLoadingAuth0) return <Redirect />

  // if (!inviteData) return null

  return (
    <InviteContainer>
      <MainContainer>
        {errorMessage ? (
          <Content>
            <Logo alt="Birdie logo" src={BirdieLogo} />

            <Text as="p" css={{ maxWidth: 464, mb: '$micro', mt: '$xl' }}>
              {errorMessage}
            </Text>
          </Content>
        ) : (
          <Content>
            <Logo alt="Birdie logo" src={BirdieLogo} />

            <FlexContainer css={{ mt: '$xl', width: '100%' }} direction="column">
              <Text as="h1" css={{ maxWidth: 464, mb: '$micro' }} typeface="titleRegularSmall">
                <Trans
                  i18nKey="invitationMessage"
                  values={{
                    inviterName: inviteData?.inviterName,
                    organizationName: inviteData?.organizationName
                  }}
                />
              </Text>
              <Text as="p" css={{ mb: '$md' }} typeface="subtitleRegularXXXS">
                {t('letsCreateYourAccountBelow')}
              </Text>
              <InviteForm
                inviteeEmail={inviteData?.inviteeEmail ?? 'placeholder@email.com'}
                isSending={isSending}
                onCreate={onCreate}
              />
            </FlexContainer>
          </Content>
        )}
      </MainContainer>
      <RightSide>
        <Illustration
          alt="Illustration of two birds, with a speech balloon and a bulblight."
          src={BirdsIllustration}
        />
        <h2>{t('fosteringMoreCollaborativeProductStrategiesWithBirdie')}</h2>
        <p>
          {t('centralizeCustomerFeedbackToCreateBetterProductsExperiencesAndMarketingCampaigns')}
        </p>
      </RightSide>
      <ToastMessagesProvider />
    </InviteContainer>
  )
}

export default InvitePage
