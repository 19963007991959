import type { CollectionRequests } from '@/types/collection'
import RequestHandler, { type RequestReturnType } from './RequestHandlers/NewRequest'

export default class CollectionService {
  static PREFIX = '/collections'

  static async search(
    params: CollectionRequests.SearchParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<CollectionRequests.SearchResponse>> {
    return await RequestHandler.get<CollectionRequests.SearchResponse>(this.PREFIX, params, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async create(
    payload: CollectionRequests.CreatePayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<CollectionRequests.CreateResponse>> {
    return await RequestHandler.post<CollectionRequests.CreateResponse>(this.PREFIX, payload, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async getContent(
    collectionId: string,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<CollectionRequests.GetContentResponse>> {
    return await RequestHandler.get<CollectionRequests.GetContentResponse>(
      `${this.PREFIX}/${collectionId}`,
      undefined,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async update(
    collectionId: string,
    payload: CollectionRequests.UpdatePayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<CollectionRequests.UpdateResponse>> {
    return await RequestHandler.put<CollectionRequests.UpdateResponse>(
      `${this.PREFIX}/${collectionId}`,
      payload,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async remove(
    collectionId: string,
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<void>> {
    return await RequestHandler.del<void>(`${this.PREFIX}/${collectionId}`, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async upinsertFilters(
    collectionId: string,
    payload: CollectionRequests.UpinsertFiltersPayload,
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<void>> {
    return await RequestHandler.put<void>(`${this.PREFIX}/${collectionId}/filters`, payload, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async removeFilter(
    collectionId: string,
    filterId: string,
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<void>> {
    return await RequestHandler.del<void>(`${this.PREFIX}/${collectionId}/filters/${filterId}`, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async favorite(
    collectionId: string,
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<void>> {
    return await RequestHandler.put<void>(`${this.PREFIX}/${collectionId}/favorite`, undefined, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }

  static async unfavorite(
    collectionId: string,
    options?: { signal?: AbortSignal }
  ): Promise<RequestReturnType<void>> {
    return await RequestHandler.del<void>(`${this.PREFIX}/${collectionId}/favorite`, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
  }
}
