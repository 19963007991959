import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog'
import Divider from '@/components/atoms/divider'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Switch from '@/components/atoms/switch/Switch'
import Text from '@/components/atoms/text'
import Tooltip from '@/components/atoms/tooltip'
import useSavedFiltersStore from '@/store/useSavedFiltersStore'
import { BellSimple, Question } from '@phosphor-icons/react'
import { useState } from 'react'
import { shallow } from 'zustand/shallow'
import { useTranslation } from 'react-i18next'

interface SaveFiltersDialogProps {
  onSaveFilters: (enableNotifications: boolean) => void
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

const SaveFiltersDialog = ({ onSaveFilters, open, onOpenChange }: SaveFiltersDialogProps) => {
  const filterName = useSavedFiltersStore(state => state.filterName, shallow)
  const setFilterName = useSavedFiltersStore(state => state.setFilterName)
  const { t } = useTranslation()

  const [enableNotifications, setEnableNotifications] = useState(false)

  const onKeyUp: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onSaveFilters(enableNotifications)
    }
  }

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    onSaveFilters(enableNotifications)
  }

  return (
    <Dialog
      title={t('saveFilters')}
      hiddenTitle
      align="center"
      closeIcon
      onOpenChange={onOpenChange}
      open={open}
    >
      <FlexContainer direction="column" gap="xs">
        <Text as="h1" typeface="titleBoldXXS">
          {t('saveFilters')}
        </Text>
        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {'nameTheSetOfFilters'}
          </Text>
          <Input
            onChange={e => setFilterName(e.currentTarget.value)}
            onKeyUp={onKeyUp}
            value={filterName}
          />
        </FlexContainer>
        <Divider line="solid" />
        <FlexContainer alignItems="center" gap="nano">
          <BellSimple />
          <Text typeface="subtitleRegularXXXS">{t('enableNotificationsForThisSetOfFilters')}</Text>
          <Tooltip
            css={{ maxWidth: 325, textAlign: 'center', wordWrap: 'normal' }}
            side="top"
            text={t(
              'byDefaultYouWillReceiveNotificationsOnYourEmailWeeklyYouCanCustomizeThatOnSettings'
            )}
            variant="small"
          >
            <Question weight="fill" />
          </Tooltip>
          <Switch
            checked={enableNotifications}
            css={{ ml: 'auto' }}
            onCheckedChange={setEnableNotifications}
          />
        </FlexContainer>
        <Divider line="solid" />
        <Button fullWidth onClick={onClick} size="small">
          {t('saveFilters')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default SaveFiltersDialog
