import OpportunityService from '@/services/OpportunityService'
import { AllMetricsKey } from '@/types/metrics'
import { OpportunityRequests } from '@/types/opportunity'
import { OpportunityItemWithMetrics } from '@/types/opportunity/Opportunity'
import { stringToDate } from '@/utils/date'
import { ONGOING_STATUS, OPPORTUNITY_METRICS_SUPPORTED_NAMES } from '@/utils/opportunityUtils'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useBaseMetricFilters from '../feedback/new/useBaseMetricsFilters'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'
import { allMetricItems } from '@/utils/metrics'
import useLogging from '../useLogging'
import useOpportunityFetchMetrics from './useOpportunityFetchMetrics'
import { queryClient } from '@/plugins/reactQueryClient'

interface Params {
  enabled: boolean
  collectionId?: string
  areaId?: string
  searchText?: string
  limit?: number
  metric?: AllMetricsKey
  onlyOngoing?: boolean
  useAppliedFilters?: boolean
  useReviewCompetitors?: boolean
  sortDirection?: 'desc' | 'asc'
}

const useOpportunitiesSortedByMetric = (
  {
    enabled = true,
    collectionId,
    searchText,
    areaId,
    limit,
    metric = 'count',
    onlyOngoing,
    useAppliedFilters,
    useReviewCompetitors,
    sortDirection = 'desc'
  }: Params = {
    enabled: true
  }
) => {
  const { logException } = useLogging({ context: 'useOpportunitiesSortedByMetric' })

  const { areas, isLoading: isLoadingAreas } = useBasicAreaOfInterestQuery({
    enabled: true,
    collectionId
  })

  const { fetchOppsSortingMetricsMutation } = useOpportunityFetchMetrics()

  const { fetchSortingMetricsMutate, isSortingMetricsLoading } = useMemo(() => {
    const { mutate: fetchSortingMetricsMutate, isLoading: isSortingMetricsLoading } =
      fetchOppsSortingMetricsMutation

    return { fetchSortingMetricsMutate, isSortingMetricsLoading }
  }, [fetchOppsSortingMetricsMutation])

  const { data: baseFiltersData } = useBaseMetricFilters({
    useAppliedFilters,
    useReviewCompetitors
  })

  const baseQueryParams = useMemo(() => {
    return baseFiltersData as FeedbackListQueryParams | undefined
  }, [baseFiltersData])

  const metricItem = useMemo(() => allMetricItems[metric], [metric])

  const queryKey = useMemo(
    () => [
      'fetch-opportunities-to-sort',
      {
        collectionId,
        searchText,
        areaId,
        limit,
        onlyOngoing,
        areas,
        useAppliedFilters,
        useReviewCompetitors,
        baseQueryParams: useAppliedFilters ? baseQueryParams : undefined
      }
    ],
    [
      collectionId,
      searchText,
      areaId,
      limit,
      onlyOngoing,
      areas,
      baseQueryParams,
      useReviewCompetitors,
      useAppliedFilters
    ]
  )

  const queryFn = async () => {
    const params: OpportunityRequests.SearchOpportunityParams = {
      limit: limit ?? 1000,
      name: searchText
    }

    if (areaId) {
      params.area_id = [areaId]
    }

    if (collectionId) {
      params.collection_id = collectionId
    }

    const [error, response] = await OpportunityService.searchOpportunities(params)
    if (error) {
      logException(error)
      throw error
    }

    const data: OpportunityItemWithMetrics[] = (response.opportunities ?? []).map(item => ({
      id: item.opportunity_id,
      filterId: item.filter_id,
      name: item.name,
      status: item.opportunity_status_id,
      description: item.description,
      createdAt: stringToDate(item.created_at),
      new: item.new ?? false,
      createdBy: item.created_by,
      relations: item.areas,
      metrics: [],
      opportunityCount: 0,
      area: item.areas.length === 1 ? areas.find(area => area.id === item.areas[0]) : undefined,
      initiatives: item.initiatives,
      pinnedFeedback: item.pinned_feedback ?? []
    }))

    const parsedOpps = onlyOngoing
      ? data.filter(opportunity => ONGOING_STATUS.includes(opportunity.status))
      : data

    if (OPPORTUNITY_METRICS_SUPPORTED_NAMES.includes(metricItem.metric.name)) {
      fetchSortingMetricsMutate(
        {
          opps: parsedOpps,
          sortDirection: sortDirection ?? 'desc',
          customSortingMetric: metricItem.metric,
          currentFilterContext: useAppliedFilters ? baseQueryParams?.context : undefined
        },
        {
          onSuccess: async sortedOpps => {
            queryClient.setQueryData<OpportunityItemWithMetrics[]>(queryKey, () => sortedOpps)
          }
        }
      )
    }

    return parsedOpps
  }

  const isEnabled = enabled && areas.length > 0

  const { data: opportunityList, isLoading: isLoadingFetching } = useQuery({
    queryKey,
    queryFn,
    enabled: isEnabled
  })

  const opportunities: OpportunityItemWithMetrics[] = useMemo(() => {
    let list: OpportunityItemWithMetrics[] = []

    if (!opportunityList) return list

    list = opportunityList

    return list
  }, [opportunityList])

  return {
    opportunities,
    isLoading: !isEnabled ? false : isLoadingFetching || isSortingMetricsLoading || isLoadingAreas
  }
}

export default useOpportunitiesSortedByMetric
