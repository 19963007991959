import OpportunityService from '@/services/OpportunityService'
import { OpportunityRequests } from '@/types/opportunity'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { mapOpportunity } from './useOpportunitiesQuery'

interface Params {
  enabled: boolean
  collectionId?: string
  areaId?: string
  searchText?: string
  limit?: number
}

const useBasicOpportunitiesQuery = (
  { enabled = true, collectionId, searchText, areaId, limit }: Params = { enabled: true }
) => {
  const queryFn = async () => {
    const params: OpportunityRequests.SearchOpportunityParams = {
      limit: limit ?? 1000,
      name: searchText
    }

    if (areaId) {
      params.area_id = [areaId]
    }

    if (collectionId) {
      params.collection_id = collectionId
    }

    const [error, response] = await OpportunityService.searchOpportunities(params)
    if (error) throw error
    return response?.opportunities ?? []
  }

  const { data, ...query } = useQuery({
    queryKey: ['opportunities', collectionId, searchText, areaId, limit],
    queryFn,
    enabled
  })

  const opportunities: OpportunityItem[] = useMemo(() => data?.map(mapOpportunity) ?? [], [data])

  return { opportunities, ...query }
}

export default useBasicOpportunitiesQuery
