import { styled } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useMemo } from 'react'
import AccessDeniedIllustration from '@/assets/drawings/access_denied_birds.png'

const ContentContainer = styled(FlexContainer, {
  paddingTop: '$xs',
  width: '100vw',
  overflow: 'hidden',
  pl: 'calc($xs + $navigationBarWidth)',
  pr: '$xs',
  gap: '$xs',
  height: '100vh',
  bc: '$brandPrimaryLight',
  flexDirection: 'column',
  alignItems: 'center'
})

const AccessDenied = () => {
  const { t } = useTranslation()
  const { state } = useLocation()

  const errorMessage = useMemo(() => {
    if (state && typeof state.errorMessage === 'string') {
      return (state.errorMessage as string) || null
    }
    return null
  }, [state])

  return (
    <ContentContainer>
      <Text color="brandPrimaryPure" fontWeight="bold" fontSize="lg" css={{ lineHeight: '50px' }}>
        {t('accessDenied')}
      </Text>
      <Text color="neutralLowPure" fontWeight="regular" fontSize="xxs">
        {errorMessage || (
          <>
            It looks like you're trying to connect from an invalid IP network.{' '}
            <b>Please switch to the company IP network</b> to access this service.
          </>
        )}
      </Text>

      <img src={AccessDeniedIllustration} width={485} height={313} />
    </ContentContainer>
  )
}

export default AccessDenied
