import { useMemo, useState } from 'react'
import { DatePeriod, Period } from '@/types/periods'
import { getDateRangeFromNow } from '@/utils/date'
import useUser from './useUser'
import { useTranslation } from 'react-i18next'

function usePeriods() {
  const { currentUserPlan } = useUser()
  const { t } = useTranslation()

  const [selectedPeriod, setSelectedPeriod] = useState<Period>('3months')

  const preDefinedPeriodsMap = new Map<Period, DatePeriod>([
    [
      'allTime',
      {
        name: t('allTime')
      }
    ],
    ['1day', { name: t('yesterday'), range: getDateRangeFromNow('1day') }],
    [
      '7days',
      {
        name: t('last7Days'),
        range: getDateRangeFromNow('7days')
      }
    ],
    [
      '30days',
      {
        name: t('last30Days'),
        range: getDateRangeFromNow('30days')
      }
    ],
    [
      '3months',
      {
        name: t('last3Months'),
        range: getDateRangeFromNow('3months')
      }
    ],
    [
      '6months',
      {
        name: t('last6Months'),
        range: getDateRangeFromNow('6months')
      }
    ],
    [
      'custom',
      {
        name: t('custom')
      }
    ]
  ])

  const preDefinedPeriods = useMemo(
    () =>
      [...preDefinedPeriodsMap.entries()]
        .filter(([key]) => (currentUserPlan === 'opportunity' ? key !== 'allTime' : true))
        .map(([key, value]) => ({
          value: key,
          text: value.name
        })),
    [preDefinedPeriodsMap, currentUserPlan]
  )

  const getPeriod = (key: Period) => preDefinedPeriodsMap.get(key) as DatePeriod

  return {
    preDefinedPeriods,
    getPeriod,
    selectedPeriod,
    setSelectedPeriod
  }
}

export default usePeriods
