import { InitiativeRequests } from '@/types/initiatives'
import RequestHandler, { RequestReturnType } from './RequestHandlers/NewRequest'

export default class InitiativesService {
  static PREFIX = '/initiative'

  static async search(
    params: InitiativeRequests.SearchInitiativesParams,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<InitiativeRequests.SearchInitiativesResponse>> {
    return await RequestHandler.get<InitiativeRequests.SearchInitiativesResponse>(
      this.PREFIX,
      params,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async create(
    payload: InitiativeRequests.CreateInitiativePayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<InitiativeRequests.CreateInitiativeResponse>> {
    return await RequestHandler.post<InitiativeRequests.CreateInitiativeResponse>(
      this.PREFIX,
      payload,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async update(
    initiativeId: string,
    payload: InitiativeRequests.UpdateInitiativePayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<InitiativeRequests.UpdateInitiativeResponse>> {
    return await RequestHandler.put<InitiativeRequests.UpdateInitiativeResponse>(
      `${this.PREFIX}/${initiativeId}`,
      payload,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async deleteInitiative(
    initiativeId: string,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<void>> {
    const [error] = await RequestHandler.del<void>(`${this.PREFIX}/${initiativeId}`, {
      signal: options?.signal,
      paramsSerializer: {
        indexes: null
      }
    })
    if (error) return [error, undefined]
    return [undefined, undefined]
  }

  static async upsertOpportunities(
    initiativeId: string,
    payload: InitiativeRequests.UpsertOpportunitiesPayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<void>> {
    const [error] = await RequestHandler.put<void>(
      `${this.PREFIX}/${initiativeId}/opportunity`,
      payload,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
    if (error) return [error, undefined]
    return [undefined, undefined]
  }

  static async upsertAssignee(
    initiativeId: string,
    payload: InitiativeRequests.UpsertAssigneePayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<void>> {
    const [error] = await RequestHandler.put<void>(
      `${this.PREFIX}/${initiativeId}/assignee`,
      payload,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
    if (error) return [error, undefined]
    return [undefined, undefined]
  }

  static async upsertFeedback(
    initiativeId: string,
    payload: InitiativeRequests.UpsertFeedbackPayload,
    options?: {
      signal?: AbortSignal
    }
  ): Promise<RequestReturnType<void>> {
    const [error] = await RequestHandler.put<void>(
      `${this.PREFIX}/${initiativeId}/feedback`,
      payload,
      {
        signal: options?.signal,
        paramsSerializer: {
          indexes: null
        }
      }
    )
    if (error) return [error, undefined]
    return [undefined, undefined]
  }
}
