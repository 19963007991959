import { Star } from '@phosphor-icons/react'
import i18n from '../../plugins/i18n/i18n'

import { sourceToMetrics } from './metrics'
import {
  integerFormatter,
  percentageFormatter,
  percentageScoreTransformer,
  scoreFormatter
} from './formatters'
import { MetricItem, ReviewMetricKey } from '@/types/metrics'

export const REVIEW_METRICS_KEYS: ReviewMetricKey[] = [
  'review',
  'review_count',
  'review_share',
  'review_avg_rating',
  'review_unsatisfied_count',
  'review_neutral_count',
  'review_satisfied_count',
  'review_share_unsatisfied_over_reviews_with_comments',
  'review_share_over_area',
  'review_share_unsatisfied_over_area_reviews_with_comments'
]

// not supported by the endpoints yet
// const unsatisfiedFilter = { 'review.rating': [0, 1, 2] }
// const neutralsFilter = { 'review.rating': [3] }
// const satisfiedFilter = { 'review.rating': [4, 5] }

const unsatisfiedFilter = { 'review.rating.lte': '2' }
const neutralsFilter = { 'review.rating.gte': '3', 'review.rating.lte': '3' }
const satisfiedFilter = { 'review.rating.gte': '4' }

export const reviewTimeseries: Record<ReviewMetricKey, MetricItem> = {
  review: {
    label: i18n.t('score'),
    selectedLabel: i18n.t('reviewsScore'),
    icon: Star,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: sourceToMetrics.review.name,
      label: sourceToMetrics.review.label
    }
  },
  review_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('reviewsPercentCount'),
    icon: Star,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `${sourceToMetrics.review.label}_share`,
      filter: { kind: 'review' }
    }
  },
  review_count: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('reviewsCount'),
    icon: Star,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `${sourceToMetrics.review.label}_count`,
      filter: { kind: 'review' }
    }
  },
  review_avg_rating: {
    label: i18n.t('avgRating'),
    selectedLabel: i18n.t('reviewsAvgRating'),
    icon: Star,
    formatter: integerFormatter,
    numberTransformer: (value: number) => Number(value.toPrecision(2)),
    metric: {
      name: 'review_avg_rating',
      label: 'Review avg rating'
    }
  },
  review_unsatisfied_count: {
    label: i18n.t('unsatisfiedCount'),
    selectedLabel: i18n.t('reviewUnsatisfiedCount'),
    icon: Star,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'review_unsatisfied_count',
      filter: { kind: 'review', ...unsatisfiedFilter }
    }
  },
  review_neutral_count: {
    label: i18n.t('neutralsCount'),
    selectedLabel: i18n.t('reviewNeutralsCount'),
    icon: Star,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'review_neutral_count',
      filter: {
        kind: 'review',
        ...neutralsFilter
      }
    }
  },
  review_satisfied_count: {
    label: i18n.t('satisfiedCount'),
    selectedLabel: i18n.t('reviewSatisfiedCount'),
    icon: Star,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'review_satisfied_count',
      filter: {
        kind: 'review',
        ...satisfiedFilter
      }
    }
  },
  review_share_unsatisfied_over_reviews_with_comments: {
    label: i18n.t('unsatisfiedShareOverAllComments'),
    selectedLabel: i18n.t('reviewUnsatisfiedShareOverAllComments'),
    icon: Star,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'review_unsatisfied_over_reviews_with_comments',
      filter: { kind: 'review', ...unsatisfiedFilter, has_signal: 'true' },
      share_filter: { kind: 'review', has_signal: 'true' }
    }
  },
  review_share_over_area: {
    label: i18n.t('percentCountOverArea'),
    selectedLabel: i18n.t('reviewPercentCountOverArea'),
    icon: Star,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `${sourceToMetrics.review.label}_area_share`,
      filter: { kind: 'review' }
    }
  },
  review_share_unsatisfied_over_area_reviews_with_comments: {
    label: i18n.t('unsatisfiedShareOverAreaComments'),
    selectedLabel: i18n.t('reviewUnsatisfiedShareOverAreaComments'),
    icon: Star,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'review_unsatisfied_over_reviews_areas_with_comments',
      filter: { kind: 'review', ...unsatisfiedFilter, has_signal: 'true' },
      share_filter: { kind: 'review', has_signal: 'true' }
    }
  }
}
