import OpportunityService from '@/services/OpportunityService'
import { parseRawFeedback } from '@/utils/feedback'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

const usePinnedFeedback = (opportunityId?: string) => {
  const queryFn = async () => {
    if (!opportunityId) return []

    const [error, response] = await OpportunityService.pinnedFeedback(opportunityId)
    if (error) throw error
    return response.feedbacks.map(parseRawFeedback)
  }

  const { data, ...query } = useQuery({
    queryKey: ['pinned-feedback', opportunityId],
    queryFn
  })

  const pinnedFeedback = useMemo(() => data ?? [], [data])

  return {
    pinnedFeedback,
    ...query
  }
}

export default usePinnedFeedback
