import { CheckedState } from '@radix-ui/react-checkbox'
import Checkbox from '@/components/atoms/checkbox'
import { useTranslation } from 'react-i18next'

export interface SentimentFilterContentProps {
  isChecked: (value: string) => boolean
  selectOption: (checked: CheckedState, option: string) => void
}

const SimpleSentimentFilterContent = ({ isChecked, selectOption }: SentimentFilterContentProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Checkbox
        checked={isChecked('-1')}
        defaultChecked={isChecked('-1')}
        onCheckedChange={checked => selectOption(checked, '-1')}
        text={t('negative')}
        value="-1"
      />
      <Checkbox
        checked={isChecked('0')}
        defaultChecked={isChecked('0')}
        onCheckedChange={checked => selectOption(checked, '0')}
        text={t('neutral')}
        value="0"
      />
      <Checkbox
        checked={isChecked('1')}
        defaultChecked={isChecked('1')}
        onCheckedChange={checked => selectOption(checked, '1')}
        text={t('positive')}
        value="1"
      />
    </>
  )
}

export default SimpleSentimentFilterContent
