// reference: https://codesandbox.io/s/objective-shape-8r4utm?file=/src/RangeCalendar.js

import { useCallback, useMemo, useRef } from 'react'
import { useRangeCalendarState } from '@react-stately/calendar'
import { useRangeCalendar } from '@react-aria/calendar'
import { useLocale, useDateFormatter } from '@react-aria/i18n'
import { CalendarDate, createCalendar, getLocalTimeZone } from '@internationalized/date'
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons'

import type { AriaRangeCalendarProps } from '@react-aria/calendar'
import type { DateValue } from '@react-types/calendar'

import { CalendarGrid } from './CalendarGrid'
import { styled } from '@/theme'
import CalendarButton from '../date-picker/CalendarButton'
import moment from 'moment'

const Flex = styled('div', {
  display: 'flex',
  // alignItems: 'center',
  userSelect: 'none',
  fontSize: 14,
  gap: 40,
  height: '280px',
  color: '$dark',

  h2: {
    fontSize: 14,
    textAlign: 'center',
    fontWeight: 300,
    fontStyle: 'normal',
    padding: 0,
    margin: 0,
    lineHeight: '20px'
  }
})

const Column = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%',
  gap: 16
})

interface RangeCalendarProps extends AriaRangeCalendarProps<DateValue> {
  maxLength?: number
}

export function RangeCalendar({ maxLength = 0, ...props }: RangeCalendarProps) {
  const { locale } = useLocale()

  const state = useRangeCalendarState({
    ...props,

    visibleDuration: { months: 2 },
    locale,
    createCalendar
  })

  const isCellUnavailable = useCallback(
    (date: CalendarDate) => {
      if (state.anchorDate && maxLength > 0) {
        const momentStart = moment(state.anchorDate.toDate(getLocalTimeZone()))
        const momentEnd = moment(date.toDate(getLocalTimeZone()))

        const diff = momentEnd.diff(momentStart, 'days')

        const isMoreThanMaxLength = Math.abs(diff) > maxLength

        return isMoreThanMaxLength || state.isCellUnavailable(date)
      }

      return state.isCellUnavailable(date)
    },
    [state, maxLength]
  )

  const newState = useMemo(() => ({ ...state, isCellUnavailable }), [state, isCellUnavailable])

  const ref = useRef(null)
  const { calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(props, newState, ref)

  const monthDateFormatter = useDateFormatter({
    month: 'long',
    year: 'numeric',
    timeZone: newState.timeZone
  })

  const month = useMemo(
    () => monthDateFormatter.format(newState.visibleRange.start.toDate(newState.timeZone)),
    [newState, monthDateFormatter]
  )

  const nextMonth = useMemo(
    () =>
      monthDateFormatter.format(
        newState.visibleRange.start.add({ months: 1 }).toDate(newState.timeZone)
      ),
    [newState, monthDateFormatter]
  )

  return (
    <Flex {...calendarProps} ref={ref}>
      <Flex css={{ gap: 16 }}>
        <CalendarButton {...prevButtonProps}>
          <ChevronLeftIcon height={20} width={20} />
        </CalendarButton>
        <Column>
          <h2>{month}</h2>
          <CalendarGrid state={newState} />
        </Column>
      </Flex>

      <Flex css={{ gap: 16 }}>
        <Column>
          <h2>{nextMonth}</h2>
          <CalendarGrid offset={{ months: 1 }} state={newState} />
        </Column>
        <CalendarButton {...nextButtonProps}>
          <ChevronRightIcon height={20} width={20} />
        </CalendarButton>
      </Flex>
    </Flex>
  )
}
