import Checkbox, { CheckboxState } from '@/components/atoms/checkbox'
import Dialog from '@/components/atoms/dialog'
import { DialogTitleDefault } from '@/components/atoms/dialog/Dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useBasicOpportunitiesQuery from '@/hooks/opportunity/useBasicOpportunitiesQuery'
import { InitiativeItem } from '@/types/initiatives/Initiatives'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Search from '../../search'
import Bone from '@/components/atoms/bone'
import Button from '@/components/atoms/button'
import Divider from '@/components/atoms/divider'

const SKELETON_COUNT = 5
const Skeleton = () => {
  return (
    <>
      {Array.from({ length: SKELETON_COUNT }).map((_, index) => (
        <Bone css={{ height: 16, width: '100%' }} key={index} />
      ))}
    </>
  )
}

interface Props {
  initiative: InitiativeItem
  onSave: (opportunityIds: string[]) => void
  open: boolean
  onOpenChange: (open: boolean) => void
}

const AssociateOppToInitiativeDialog = ({ initiative, onSave, open, onOpenChange }: Props) => {
  const { t } = useTranslation()

  const [checkedOpportunities, setCheckedOpportunities] = useState<string[]>(
    initiative.opportunities
  )

  const { opportunities: allOpportunities, isLoading } = useBasicOpportunitiesQuery()
  const initiativeOpportunities = useMemo(() => {
    if (!allOpportunities) return []
    return allOpportunities.filter(opportunity => initiative.opportunities.includes(opportunity.id))
  }, [initiative, allOpportunities])

  const checkboxList = useMemo(() => {
    const firstOpportunities = initiativeOpportunities.map(opportunity => ({
      name: opportunity.name,
      id: opportunity.id
    }))

    const otherOpportunities = allOpportunities
      .filter(
        opportunity =>
          !firstOpportunities.map(opportunity => opportunity.id).includes(opportunity.id)
      )
      .map(opportunity => ({
        name: opportunity.name,
        id: opportunity.id
      }))

    return [...firstOpportunities, ...otherOpportunities]
  }, [initiativeOpportunities, allOpportunities])

  const [searchInput, setSearchInput] = useState('')
  const filteredCheckboxList = useMemo(() => {
    if (!searchInput) return checkboxList
    return checkboxList.filter(opportunity =>
      opportunity.name.toLowerCase().includes(searchInput.toLowerCase())
    )
  }, [searchInput, checkboxList])

  const onCheckedChange = (checkedState: CheckboxState, id: string) => {
    if (checkedState) {
      setCheckedOpportunities(prev => [...prev, id])
    } else {
      setCheckedOpportunities(prev => prev.filter(opportunity => opportunity !== id))
    }
  }
  const isChecked = (id: string) => checkedOpportunities.includes(id)

  const onSaveClick = () => {
    onSave(checkedOpportunities)
    onOpenChange(false)
  }

  const isSaveDisabled = useMemo(() => {
    const hasChanges = checkedOpportunities.some(id => !initiative.opportunities.includes(id))
    return checkedOpportunities.length === 0 || !hasChanges || isLoading
  }, [checkedOpportunities, initiative, isLoading])

  return (
    <Dialog align="center" closeIcon modal onOpenChange={onOpenChange} open={open}>
      <FlexContainer direction="column" gap="xs">
        <DialogTitleDefault>{t('associateMoreOpportunities')}</DialogTitleDefault>
        <FlexContainer alignItems="center" justifyContent="spaceBetween">
          <Text as="p" fontSize="xxxs">
            {t('opportunitiesOnInitiative')}
          </Text>

          <Search
            clearable
            expandOnFocus
            onChange={e => setSearchInput(e.currentTarget.value)}
            onClear={() => setSearchInput('')}
            size="small"
            small
            value={searchInput}
          />
        </FlexContainer>

        {isLoading ? (
          <Skeleton />
        ) : (
          <FlexContainer css={{ overflowY: 'auto', maxHeight: 350 }} direction="column" gap="xxs">
            {filteredCheckboxList.map(checkbox => (
              <Checkbox
                checked={isChecked(checkbox.id)}
                key={checkbox.id}
                onCheckedChange={checked => onCheckedChange(checked, checkbox.id)}
                text={checkbox.name}
                value={`collection-${checkbox.id}`}
              />
            ))}
          </FlexContainer>
        )}

        <Divider line="solid" orientation="horizontal" />

        <Button disabled={isSaveDisabled} fullWidth onClick={onSaveClick} size="small">
          {t('save')}
        </Button>
      </FlexContainer>
    </Dialog>
  )
}

export default AssociateOppToInitiativeDialog
