import PageErrorBoundary from '@/components/atoms/error-boundary/PageErrorBoundary'
import AuthMiddleware from './auth'
import { Layout, TopBarProps } from '@/components/molecules/layout'

interface Props extends TopBarProps {
  children?: React.ReactNode
}

const PageWrapper = ({ children, ...props }: Props) => {
  return (
    <AuthMiddleware>
      <PageErrorBoundary fullWidth>{children ?? <Layout {...props} />}</PageErrorBoundary>
    </AuthMiddleware>
  )
}

export default PageWrapper
