import { SavedFilterContentAdvanced } from '@/types/filters/Filters'
import { useMemo } from 'react'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { shallow } from 'zustand/shallow'
import { endDateParam, startDateParam } from '@/utils/date'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import {
  advancedFilterToContent,
  isAdvancedContent,
  mergeAdvancedFilters,
  mergeAreasToAdvancedFilterContent,
  searchParamsToAdvancedFilterContent
} from '@/utils/advancedFilter'
import { AdvancedFilterContent, FilterNode } from '@/types/filters/AdvancedFilters'
import { getParamsFromFilterContent } from '@/utils/filters'
import useAdvancedFilters from '../advancedFilters/useAdvancedFilters'
import FiltersService from '@/services/FiltersService'
import { MetricsRequests } from '@/types/metrics'
import MetricsService from '@/services/MetricsService'
import { useQuery } from '@tanstack/react-query'
import useOpportunityStore from '@/store/useOpportunityStore'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'

interface Params {
  enabled?: boolean
}

const useOppRelatedAreas = ({ enabled = true }: Params = { enabled: true }) => {
  const opportunity = useOpportunityStore(state => state.currentOpportunity)

  const { areas, isLoading: isLoadingAreas } = useBasicAreaOfInterestQuery()

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const oppAreas = useMemo(
    () =>
      (opportunity?.relations
        .map(id => areas.find(area => area.id === id))
        .filter(Boolean) as BaseInterestArea[]) ?? [],

    [opportunity, areas]
  )

  const { filters: advancedFilters, isFetchingContext } = useAdvancedFilters()

  const areasMergedFilter = useMemo(() => {
    if (oppAreas.length === 1) {
      return oppAreas[0].content[0]?.values.filter as AdvancedFilterContent
    }

    return mergeAreasToAdvancedFilterContent(oppAreas)
  }, [oppAreas])

  const filteredAdvancedFilters: FilterNode = useMemo(() => {
    return {
      operator: '$and',
      value: (advancedFilters.value as FilterNode[]).filter(node => !node.isFromArea)
    }
  }, [advancedFilters])

  const queryFn = async () => {
    if (!opportunity) return []

    let startDate: string | undefined
    let endDate: string | undefined
    if (datePeriod !== 'allTime' && dateRange) {
      startDate = startDateParam(dateRange.start)
      endDate = endDateParam(dateRange.end)
    }

    const multipleContextFilters = oppAreas.map(area => {
      const areaContent = area.content
      let areaFilter: AdvancedFilterContent
      if (isAdvancedContent(areaContent)) {
        areaFilter = (areaContent[0] as SavedFilterContentAdvanced).values.filter
      } else {
        const params = getParamsFromFilterContent(areaContent) as FeedbackListQueryParams
        areaFilter = searchParamsToAdvancedFilterContent(params)
      }

      const appliedFilter: AdvancedFilterContent = advancedFilterToContent(filteredAdvancedFilters)
      const filterToMerge = []
      if (areaFilter.$and && areaFilter.$and.length > 0) {
        filterToMerge.push(areaFilter)
      } else {
        filterToMerge.push(areasMergedFilter)
      }
      if (appliedFilter.$and && appliedFilter.$and.length > 0) {
        filterToMerge.push(appliedFilter)
      }
      const mergedFilter = mergeAdvancedFilters(filterToMerge, '$and')
      return {
        identifier: area.id,
        filter: mergedFilter
      }
    })

    const [multipleContextError, multipleContextResponse] =
      await FiltersService.multipleAdvancedFilterContext({
        filters: multipleContextFilters
      })

    if (multipleContextError) throw multipleContextError

    const filterList: FeedbackListQueryParams[] = multipleContextResponse.map(({ context }) => ({
      context,
      opportunity_id: opportunity.id
    }))

    const metricsPayload: MetricsRequests.MetricsPayload = {
      filter_list: filterList,
      metric_list: [
        {
          name: 'feedback_count',
          label: 'feedback_count'
        }
      ],
      posted_at_gte: startDate,
      posted_at_lt: endDate
    }

    const [metricsError, metricsResponse] = await MetricsService.metrics(metricsPayload)
    if (metricsError) throw metricsError

    return metricsResponse
  }

  const { data, ...query } = useQuery({
    queryKey: [
      'related-areas',
      opportunity?.id,
      oppAreas,
      filteredAdvancedFilters,
      dateRange,
      datePeriod
    ],
    queryFn,
    enabled: enabled && opportunity && !isLoadingAreas && !isFetchingContext
  })

  const totalCount = useMemo(
    () => data?.reduce((acc, value) => acc + value[0].current_value, 0) ?? 0,
    [data]
  )

  const relatedAreas = useMemo(() => {
    return oppAreas
      .map((area, index) => {
        const count = data?.[index]?.[0].current_value ?? 0
        const percentage = (count / totalCount) * 100

        return {
          id: area.id,
          name: area.name,
          count,
          percentage
        }
      })
      .sort((a, b) => b.count - a.count)
  }, [oppAreas, data, totalCount])

  const getOriginalArea = (id: string) => {
    return oppAreas.find(area => area.id === id)
  }

  return { relatedAreas, ...query, getOriginalArea, totalCount }
}

export default useOppRelatedAreas
