import { styled } from '@/theme'
import { slideAndFadeOpen } from '@/theme/animations'
import { CaretDown } from '@phosphor-icons/react'
import * as Popover from '@radix-ui/react-popover'
import * as ScrollArea from '@radix-ui/react-scroll-area'

const Root = Popover.Root

const StyledTrigger = styled(Popover.Trigger, {
  all: 'unset',
  width: 'fit-content',
  borderRadius: 8,
  padding: '$micro $xxxs',
  gap: '$micro',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  bc: '$neutralHighLight',
  fontSize: '$xxxs',
  height: 32,
  boxSizing: 'border-box',
  cursor: 'pointer',
  outline: '1px solid $neutralHighPure',

  '&:hover': {
    bc: '$neutralHighMedium'
  },

  '&:disabled': {
    opacity: 0.5,
    pointerEvents: 'none'
  },

  '.caret': {
    transition: 'transform .2s cubic-bezier(0.65, 0, 0.35, 1)'
  },

  '&[data-state="open"]': {
    '.caret': {
      transform: 'rotate(180deg)'
    },
    outline: '2px solid $brandPrimaryPure'
  },

  '&[data-state="closed"]': {
    '.caret': {
      transform: 'rotate(0deg)'
    },
    outline: '1px solid $neutralHighPure'
  }
})

const TriggerContainer = styled('div', {
  display: 'flex',
  gap: '$micro',
  alignItems: 'center',
  boxSizing: 'border-box',
  borderRadius: 8,
  bAll: '$neutralHighPure',
  // padding: '3px $xxs',
  userSelect: 'none'
})

const Trigger = ({ children, ...props }: Popover.PopoverTriggerProps) => {
  return (
    <StyledTrigger {...props}>
      <TriggerContainer>
        {children}
        <CaretDown className="caret" size={12} />
      </TriggerContainer>
    </StyledTrigger>
  )
}

const Count = styled('span', {
  size: 17,
  borderRadius: 60,
  bc: '$brandPrimaryPure',
  color: '$neutralHighLight',
  fontSize: '$micro',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

const StyledContent = styled(Popover.Content, {
  display: 'flex',
  flexDirection: 'column',
  minWidth: 'var(--radix-select-trigger-width)',
  maxWidth: 'var(--radix-select-content-available-width)',
  // width: 250,
  boxSizing: 'border-box',
  maxHeight: 'var(--radix-select-content-available-height)',
  minHeight: 32,

  color: '$neutralLowPure',

  borderRadius: 16,
  bAll: '$neutralHighPure',
  background: '$white',
  overflow: 'hidden',
  zIndex: 99999,
  // position: 'relative',
  boxShadow: '$soft',

  ...slideAndFadeOpen
})

const Content = ({ children, ...props }: Popover.PopoverContentProps) => {
  return (
    <Popover.Portal>
      <StyledContent align="start" sideOffset={5} {...props}>
        {children}
      </StyledContent>
    </Popover.Portal>
  )
}

const ScrollAreaRoot = styled(ScrollArea.Root, {
  overflow: 'hidden',
  position: 'relative'
})

const CheckboxSelectPrimitive = {
  Root,
  Trigger,
  Count,
  Content,
  ScrollAreaRoot
}

export default CheckboxSelectPrimitive
