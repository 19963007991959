import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { CalendarBlank, User, UserPlus, Target, Link, PencilSimple } from '@phosphor-icons/react'
import { colors } from '@/theme/colors'
// import DateFilter from '../filters/DateFilter'
// import { useFiltersStore } from '@/store'
// import { shallow } from 'zustand/shallow'
// import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { useCallback, useMemo, useState } from 'react'
import { DateValue, parseDate } from '@internationalized/date'
import DatePicker from '@/components/atoms/date-picker'
import { useTranslation } from 'react-i18next'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import { useUserStore } from '@/store'
import { shallow } from 'zustand/shallow'
import Select, { SelectOption } from '@/components/atoms/select'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import { InitiativeStatus } from '@/types/initiatives/Initiatives'
import Input from '@/components/atoms/input'
import InitiativeDoneDialog from './modals/InitiativeDoneDialog'
import { initiativeStatusOptions } from './utils/iniativeStatus'
import UserCell from '../user-cell/UserCell'
import IconButton from '@/components/atoms/icon-button'
import { styled } from '@/theme'

const LinkContainer = styled(FlexContainer, {
  height: 40,
  gap: '$micro',
  alignItems: 'center',
  '.edit-icon': {
    opacity: 0
  },

  '&:hover': {
    '.edit-icon': {
      opacity: 1
    }
  }
})

const TaskLink = styled('a', {
  ml: '$xxs',
  cursor: 'pointer',
  fontSize: '$xxxs',
  textDecoration: 'underline !important',
  color: '$brandPrimaryMedium !important'
})

const OverviewDetailField = () => {
  const { t } = useTranslation()
  const currentInitiative = useInitiativeStore(state => state.currentInitiative, shallow)

  const {
    updateInitiative,
    isUpdateInitiativeLoading,
    assigneeInitiative,
    isAssigneeInitiativeLoading
  } = useInitiatives({
    enabled: false,
    useCurrentInitiative: true
  })

  // TODO: fix the displayDueDate and onDueDateChange
  const displayDueDate = useMemo(() => {
    if (!currentInitiative?.dueDate) return

    const isoString = new Date(currentInitiative.dueDate).toISOString()
    const parsedDate = parseDate(isoString.slice(0, 10))

    return parsedDate
  }, [currentInitiative])

  const onDueDateChange = useCallback(
    (dueDate: DateValue) => {
      if (currentInitiative?.id) {
        const isoString = dueDate.toString().slice(0, 10)
        updateInitiative({
          dueDate: isoString,
          initiativeId: currentInitiative.id
        })
      }
    },
    [currentInitiative, updateInitiative]
  )

  const users = useUserStore(state => state.users, shallow)

  const displayInitiativeOwner = useMemo(() => {
    if (!currentInitiative?.owner) return

    const initiativeOwner = users.find(user => user.user_id === currentInitiative?.owner)

    return initiativeOwner?.user_id
  }, [currentInitiative, users])

  const onOwnerChange = useCallback(
    (owner: string) => {
      if (currentInitiative?.id) {
        updateInitiative({
          initiativeId: currentInitiative.id,
          owner
        })
      }
    },
    [currentInitiative, updateInitiative]
  )

  const userOptions: SelectOption<string>[] = useMemo(
    () =>
      users
        .filter(user => !user.first_access)
        .map(user => ({
          text: user.name,
          value: user.user_id,
          customContent: <UserCell smallIcon userId={user.user_id} />
          // icon: <UserIcon user={user} />
        })),
    [users]
  )

  const displayAssignees = useMemo(() => {
    if (!currentInitiative?.assignees) return

    const initiativeAssignees = currentInitiative.assignees.map(assigneeId =>
      users.find(user => user.user_id === assigneeId)
    )

    if (!initiativeAssignees) return
    return initiativeAssignees.map(assignee => assignee?.user_id)
  }, [currentInitiative, users])

  // TODO: fix the onOwnerChange

  const onAssigneeChange = useCallback(
    (assignee: string) => {
      if (currentInitiative?.id) {
        assigneeInitiative({
          initiativeId: currentInitiative.id,
          assigneeId: assignee
        })
      }
    },
    [currentInitiative, assigneeInitiative]
  )

  const [initiativeDoneOpen, setInitiativeDoneOpen] = useState(false)

  const onInitiativeStatusChange = useCallback(
    (status: InitiativeStatus) => {
      if (currentInitiative?.id) {
        if (status === 'done') {
          setInitiativeDoneOpen(true)
          return
        }

        updateInitiative({
          initiativeId: currentInitiative.id,
          status
        })
      }
    },
    [currentInitiative, updateInitiative]
  )

  const [taskLink, setTaskLink] = useState(currentInitiative?.taskLink ?? '')

  const onTaskLinkChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTaskLink(e.target.value)
  }, [])

  const [taskLinkInputOpen, setTaskLinkInputOpen] = useState(false)
  const isTaskLinkInputVisible = useMemo(
    () => taskLinkInputOpen || !currentInitiative?.taskLink,
    [taskLinkInputOpen, currentInitiative]
  )

  const onTaskLinkEditClick = useCallback(() => {
    setTaskLinkInputOpen(true)
    setTaskLink(currentInitiative?.taskLink ?? '')
  }, [currentInitiative])

  const initiativeTaskLink = useMemo(() => {
    return currentInitiative?.taskLink
  }, [currentInitiative])

  const onKeyPressEnter = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && currentInitiative?.id) {
        updateInitiative({
          initiativeId: currentInitiative.id,
          taskLink
        })

        setTaskLinkInputOpen(false)
      }

      if (e.key === 'Escape') {
        setTaskLink(currentInitiative?.taskLink ?? '')
        setTaskLinkInputOpen(false)
      }
    },
    [currentInitiative, updateInitiative, taskLink]
  )

  const confirmTaskLink = useCallback(() => {
    if (!currentInitiative?.id) return
    updateInitiative({
      initiativeId: currentInitiative.id,
      taskLink
    })

    setTaskLinkInputOpen(false)
  }, [currentInitiative, updateInitiative, taskLink])

  const isLoading = isAssigneeInitiativeLoading || isUpdateInitiativeLoading

  return (
    <>
      {/* Not for this delivery */}

      {/* <FlexContainer alignItems="center" gap="xxs">
        <FlexContainer alignItems="center" gap="xxxs" css={{ width: '40%' }}>
          <CalendarBlank color={colors.neutralLowLight} size={20} />

          <Text fontWeight="regular" fontSize="xxxs" color="neutralLowLight">
            {t('interval')}
          </Text>
        </FlexContainer>

        <FlexContainer fullWidth>
          <DateFilter
            appliedPeriod={datePeriod}
            appliedRange={dateRange}
            setDateRange={setDateRange}
            // key={JSON.stringify(dateRange)}
            // onApply={onApply}
            // onCalendarClose={onCalendarClose}
            // onChangePeriod={onChangePeriod}
            // onOpen={onOpen}
            // onSelectAllTime={onSelectAllTime}
            // onSelectCustom={onSelectCustom}
            triggerCss={{ width: '60%' }}
          />
        </FlexContainer>
      </FlexContainer> */}

      <FlexContainer alignItems="center" gap="xxs">
        <FlexContainer alignItems="center" gap="xxxs" css={{ width: '40%' }}>
          <CalendarBlank color={colors.neutralLowLight} size={20} />
          <Text fontWeight="regular" fontSize="xxxs" color="neutralLowLight">
            {t('dueDate')}
          </Text>
        </FlexContainer>
        <FlexContainer fullWidth>
          <DatePicker
            isDisabled={isLoading}
            onChange={onDueDateChange}
            value={displayDueDate}
            triggerCss={{ width: '60%' }}
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer alignItems="center" gap="xxs">
        <FlexContainer alignItems="center" gap="xxxs" css={{ width: '40%' }}>
          <User color={colors.neutralLowLight} size={20} />
          <Text fontWeight="regular" fontSize="xxxs" color="neutralLowLight">
            {t('owner')}
          </Text>
        </FlexContainer>
        <FlexContainer fullWidth>
          <Select
            placeholder="Select an owner"
            disabled={isLoading}
            onValueChange={onOwnerChange}
            options={userOptions}
            value={displayInitiativeOwner}
            width="60%"
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer alignItems="center" gap="xxs">
        <FlexContainer alignItems="center" gap="xxxs" css={{ width: '40%' }}>
          <UserPlus color={colors.neutralLowLight} size={20} />
          <Text fontWeight="regular" fontSize="xxxs" color="neutralLowLight">
            {t('assignTo')}
          </Text>
        </FlexContainer>
        <FlexContainer fullWidth>
          <Select
            placeholder="Choose an assignee"
            disabled={isLoading}
            onValueChange={onAssigneeChange}
            options={userOptions}
            value={displayAssignees && displayAssignees[0]}
            width="60%"
          />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer alignItems="center" gap="xxs">
        <FlexContainer alignItems="center" gap="xxxs" css={{ width: '40%' }}>
          <Target color={colors.neutralLowLight} size={20} />
          <Text fontWeight="regular" fontSize="xxxs" color="neutralLowLight">
            {t('status')}
          </Text>
        </FlexContainer>
        <FlexContainer fullWidth>
          <Select
            placeholder="Choose status"
            disabled={isLoading}
            onValueChange={onInitiativeStatusChange}
            value={currentInitiative?.status}
            options={initiativeStatusOptions}
            width="60%"
          />{' '}
        </FlexContainer>
      </FlexContainer>

      <FlexContainer alignItems="center" gap="xxs">
        <FlexContainer alignItems="center" gap="xxxs" css={{ width: '40%' }}>
          <Link color={colors.neutralLowLight} size={20} />
          <Text fontWeight="regular" fontSize="xxxs" color="neutralLowLight">
            {t('taskLink')}
          </Text>
        </FlexContainer>
        <FlexContainer fullWidth>
          {isTaskLinkInputVisible ? (
            <Input
              disabled={isLoading}
              value={taskLink}
              onChange={onTaskLinkChange}
              onKeyDown={onKeyPressEnter}
              style={{ width: '60%' }}
              placeholder="Enter task link"
              onBlur={confirmTaskLink}
            />
          ) : (
            <LinkContainer>
              <TaskLink target="_blank" href={initiativeTaskLink}>
                {initiativeTaskLink}
              </TaskLink>
              <IconButton className="edit-icon" onClick={onTaskLinkEditClick}>
                <PencilSimple />
              </IconButton>
            </LinkContainer>
          )}
        </FlexContainer>
      </FlexContainer>

      {initiativeDoneOpen && currentInitiative && (
        <InitiativeDoneDialog
          initiative={currentInitiative}
          open={initiativeDoneOpen}
          onOpenChange={setInitiativeDoneOpen}
        />
      )}
    </>
  )
}

export default OverviewDetailField
