import { CSS, styled } from '@/theme'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '../scroll-area'
import { ReactNode } from 'react'
import FlexContainer from '@/components/atoms/flex-container'

const ContentScrollArea = styled(ScrollAreaRoot, {
  overflow: 'hidden',
  bottom: 0,
  height: '100%',
  width: '100%',
  boxSizing: 'border-box'
})

const ContentContainer = styled(FlexContainer, {
  paddingTop: '$xs',
  width: '100vw',
  overflow: 'hidden',
  pl: 'calc($xs + $navigationBarWidth)',
  pr: '$xs',
  gap: '$sm',
  height: '100%',
  flexDirection: 'column',

  a: {
    textDecoration: 'none'
  }
})

const PageContentScroll = ({ children, css = {} }: { children: ReactNode; css?: CSS }) => {
  return (
    <ContentScrollArea>
      <ScrollAreaViewport css={{ display: 'flex !important', justifyContent: 'center' }}>
        <ContentContainer css={css}>{children}</ContentContainer>
      </ScrollAreaViewport>
      <ScrollAreaScrollbar orientation="horizontal">
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>

      <ScrollAreaScrollbar>
        <ScrollAreaThumb />
      </ScrollAreaScrollbar>
    </ContentScrollArea>
  )
}

export default PageContentScroll
