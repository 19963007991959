import useUser from '@/hooks/useUser'
import { styled } from '@/theme'
import { InitiativeItem } from '@/types/initiatives/Initiatives'
import { dateShortMonthFormat } from '@/utils/date'
import moment from 'moment'
import { useMemo } from 'react'
import { UserIcon } from '../user-cell/UserCell'

const Chip = styled('div', {
  borderRadius: 8,
  height: 24,
  padding: '$nano $xxxs',
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  gap: '$nano',
  fontSize: '$micro',
  width: 'fit-content',

  variants: {
    color: {
      primary: {
        backgroundColor: '$brandPrimaryLight',
        color: '$brandPrimaryPure',
        '.muted': {
          color: '$brandPrimaryMedium'
        }
      },
      red: {
        backgroundColor: '$feedbackNegativeLight',
        color: '$feedbackNegativePure',
        '.muted': {
          color: '$feedbackNegativeMedium'
        }
      }
    }
  },

  defaultVariants: {
    color: 'primary'
  }
})

export const DueDateChip = ({ date }: { date: Date }) => {
  const isRed = moment(date).diff(moment(), 'days') < 7
  return <Chip color={isRed ? 'red' : 'primary'}>{dateShortMonthFormat(date)}</Chip>
}

export const DueDateChipWithUsers = ({ initiative }: { initiative: InitiativeItem }) => {
  const { getUserById } = useUser()
  const owner = useMemo(() => getUserById(initiative.owner), [initiative.owner, getUserById])
  const assignee = useMemo(
    () => getUserById(initiative.assignees[0]),
    [initiative.assignees, getUserById]
  )

  const date = initiative.dueDate
  const isRed = useMemo(() => moment(date).diff(moment(), 'days') < 7, [date])
  if (!date) return

  return (
    <Chip color={isRed ? 'red' : 'primary'}>
      <span className="muted">Due date:</span>
      <span>{dateShortMonthFormat(date)}</span>
      {owner && <UserIcon user={owner} />}
      {assignee && <UserIcon user={assignee} />}
    </Chip>
  )
}
