import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import InitiativesFilters from '@/components/molecules/initiatives/InitiativesFilters'
import InitiativesTable from '@/components/molecules/initiatives/table/InitiativesTable'
import PageContentScroll from '@/components/molecules/layout/PageContentScroll'
import ToastMessagesProvider from '@/components/molecules/ToastMessagesProvider'

const AllInitiativesPage = () => {
  return (
    <PageContentScroll>
      <FlexContainer justifyContent="spaceBetween">
        <Text as="h1" fontWeight="bold" fontSize="xs">
          Initiatives
        </Text>
        <InitiativesFilters />
      </FlexContainer>

      <InitiativesTable />
      <ToastMessagesProvider />
    </PageContentScroll>
  )
}

export default AllInitiativesPage
