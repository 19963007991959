import useEnumAdvancedFilter from '@/hooks/advancedFilters/useEnumAdvancedFilter'
import { getFeedbackKindPrettyName } from '@/utils/feedback'
import { useState } from 'react'
import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { Stack } from '@phosphor-icons/react'
import StringFilterContent from '../../FiltersSidebar/StringGenericFilter/StringFilterContentNew'
import { useTranslation } from 'react-i18next'

const SourceSubMenu = () => {
  const [open, setOpen] = useState(false)
  const { apply, selectOption, isChecked, options, isLoading, isDisabled } = useEnumAdvancedFilter({
    name: 'kind',
    path: 'kind.name',
    enableQuery: open
  })
  const { t } = useTranslation()

  const textFormatter = getFeedbackKindPrettyName

  return (
    <FilterSubMenu
      icon={<Stack />}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      title={t('source')}
      isDisabled={isDisabled}
    >
      <StringFilterContent
        isChecked={isChecked}
        options={options}
        selectOption={selectOption}
        textFormatter={textFormatter}
      />
    </FilterSubMenu>
  )
}

export default SourceSubMenu
