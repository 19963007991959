import Button from '@/components/atoms/button'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useTranslation } from 'react-i18next'
import { AssociatedOpportunitiesGrid } from './styles'
import AssociatedOpportunity from './AssociatedOpportunity'
import { useMemo, useState } from 'react'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import AssociateOppToInitiativeDialog from './modals/AssociateOppToInitiativeDialog'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import useBasicOpportunitiesQuery from '@/hooks/opportunity/useBasicOpportunitiesQuery'

const AssociatedOpportunityModule = () => {
  const { t } = useTranslation()

  const currentInitiative = useInitiativeStore(state => state.currentInitiative)

  const [isAddOpportunityModalOpen, setIsAddOpportunityModalOpen] = useState(false)
  const onAddOpportunityClick = () => {
    setIsAddOpportunityModalOpen(true)
  }

  const { associateOpportunities } = useInitiatives({ enabled: false })
  const onSaveInitiative = (opportunityIds: string[]) => {
    if (!currentInitiative) return
    associateOpportunities({ initiativeId: currentInitiative?.id, opportunityIds })
  }

  const { opportunities: allOpportunities } = useBasicOpportunitiesQuery()

  const initiativeOpportunities = useMemo(() => {
    if (!allOpportunities) return []
    return allOpportunities.filter(opportunity =>
      currentInitiative?.opportunities.includes(opportunity.id)
    )
  }, [currentInitiative, allOpportunities])

  if (!currentInitiative) return null

  return (
    <>
      <FlexContainer direction="column" gap="sm">
        <FlexContainer justifyContent="spaceBetween" alignItems="center">
          <Text
            as="h2"
            color="brandPrimaryPure"
            css={{ fontSize: '$xs', lineHeight: 1.25, fontWeight: '$light' }}
          >
            {t('associatedOpportunity')}
          </Text>
          <Button
            text
            onClick={onAddOpportunityClick}
            size="small"
            variant="flat-bordered"
            css={{ border: 'none', fontWeight: '$regular' }}
          >
            {t('addOpportunity')}
          </Button>
        </FlexContainer>
        <AssociatedOpportunitiesGrid>
          {initiativeOpportunities &&
            initiativeOpportunities.map(opportunity => {
              return <AssociatedOpportunity {...opportunity} key={opportunity.id} />
            })}
        </AssociatedOpportunitiesGrid>
      </FlexContainer>

      {isAddOpportunityModalOpen && (
        <AssociateOppToInitiativeDialog
          initiative={currentInitiative}
          open={isAddOpportunityModalOpen}
          onOpenChange={setIsAddOpportunityModalOpen}
          onSave={onSaveInitiative}
        />
      )}
    </>
  )
}

export default AssociatedOpportunityModule
