import { navigationBarWidth, styled, topBarHeight, topBarHomeHeight } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'

export const PageContainer = styled(FlexContainer, {
  height: '100%',
  flexDirection: 'column',

  variants: {
    opportunityPlan: {
      true: {
        ml: '$navigationBarWidth'
      }
    }
  }
})

export const ExploreContainer = styled('div', {
  display: 'flex',
  pr: 0,
  boxSizing: 'border-box',
  height: '100%',
  flexGrow: 1,
  overflow: 'hidden'
})

export const ContentContainer = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
})

export const MainContainer = styled('main', {
  width: `calc(100vw - ${navigationBarWidth}px)`,
  ml: navigationBarWidth,

  variants: {
    opportunityPlan: {
      true: {
        width: '100vw',
        boxSizing: 'border-box',
        ml: 0,
        height: `calc(100vh - ${topBarHomeHeight}px)`,
        // bc: '$brandPrimaryLight',
        mt: topBarHomeHeight
      },
      false: {
        height: `calc(100vh - ${topBarHeight}px)`,
        mt: topBarHeight
      }
    },
    clearBackground: {
      true: {
        bc: '$neutralHighLight'
      },
      false: {
        bc: '$brandPrimaryLight'
      }
    }
  },
  defaultVariants: {
    opportunityPlan: false,
    clearBackground: false
  }
})
