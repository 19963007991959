import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { RadioItem } from '@/components/atoms/radio-group'
import FlexContainer from '@/components/atoms/flex-container'
import { CollapsibleContentAnimated, CollapsibleRoot } from '@/components/atoms/collapsible'
import Slider from '@/components/atoms/slider/Slider'
import { NumericRangeOption } from '@/types/filters/Filters'
import { CSS } from '@/theme'
import Input from '@/components/atoms/input'

const sliderCSS: CSS = {
  padding: '$xxxs 0'
}

export interface NumericFilterContentProps {
  onOptionChange: (value: string) => void
  onRangeChange: (range: [number, number]) => void
  onValueChange: (range: [number]) => void
  selectedOption: string
  isRangeOpen: boolean
  rangeText: string
  rangeValue: [number, number]
  isExactValueOpen: boolean
  exactValueText: string
  exactValue: [number]
  min: number
  max: number
  labels: Record<NumericRangeOption, string>
}

const NumericFilterContent = ({
  onOptionChange,
  onRangeChange,
  onValueChange,
  selectedOption,
  isRangeOpen,
  rangeValue,
  isExactValueOpen,
  exactValue,
  exactValueText,
  min,
  max,
  labels
}: NumericFilterContentProps) => {
  const onExactValueInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value
    if (e.target.value === '') {
      value = min
    } else {
      value = Number(e.target.value)
    }

    if (value >= min && value <= max) {
      onValueChange([value])
    }
  }

  const onRangeMinValueInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value >= min && value <= max) {
      const newMinValue = value <= rangeValue[1] ? value : rangeValue[1]
      onRangeChange([newMinValue, rangeValue[1]])
    }
  }

  const onRangeMaxValueInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value >= min && value <= max) {
      const newMaxValue = value >= rangeValue[0] ? value : rangeValue[0]
      onRangeChange([rangeValue[0], newMaxValue])
    }
  }

  return (
    <RadioGroupPrimitive.Root onValueChange={onOptionChange} value={selectedOption}>
      <RadioItem css={{ mb: 2 }} text={labels.all} value="all" />
      <FlexContainer justifyContent="spaceBetween">
        <RadioItem text={labels.between} value="between" />
        {isRangeOpen && (
          <FlexContainer gap="nano" alignItems="center" justifyContent="spaceBetween">
            <Input value={rangeValue[0]} onChange={onRangeMinValueInputChange} numericFilter />
            -
            <Input value={rangeValue[1]} onChange={onRangeMaxValueInputChange} numericFilter />
          </FlexContainer>
        )}
      </FlexContainer>
      <CollapsibleRoot open={isRangeOpen}>
        <CollapsibleContentAnimated>
          <Slider
            css={sliderCSS}
            max={max}
            min={min}
            onValueChange={onRangeChange}
            value={rangeValue}
          />
        </CollapsibleContentAnimated>
      </CollapsibleRoot>
      <FlexContainer justifyContent="spaceBetween">
        <RadioItem text={labels.is} value="is" />
        {isExactValueOpen && (
          <Input
            value={exactValueText}
            type="number"
            onChange={onExactValueInputChange}
            numericFilter
          />
        )}
      </FlexContainer>
      <CollapsibleRoot open={isExactValueOpen}>
        <CollapsibleContentAnimated>
          <Slider
            css={sliderCSS}
            max={max}
            min={min}
            onValueChange={onValueChange}
            range={false}
            value={exactValue}
          />
        </CollapsibleContentAnimated>
      </CollapsibleRoot>
      <RadioItem text={labels.null} value="null" />
    </RadioGroupPrimitive.Root>
  )
}

export default NumericFilterContent
