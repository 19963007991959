import FeedbackService from '@/services/FeedbackService'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

interface Params {
  enabled?: boolean
}

const useSourcesQuery = ({ enabled = true }: Params = { enabled: true }) => {
  const sourcesQueryFn = async () => {
    const [error, response] = await FeedbackService.getFeedbackFieldValues({
      type: 'enum',
      fieldName: 'kind'
    })
    if (error) throw error
    return response
  }

  const { data, ...query } = useQuery({
    queryKey: ['sources'],
    queryFn: sourcesQueryFn,
    enabled
  })

  const values = useMemo(() => {
    return data?.values ?? []
  }, [data])

  return {
    data,
    values,
    ...query
  }
}

export default useSourcesQuery
