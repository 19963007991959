import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import OpportunityService from '@/services/OpportunityService'
import FeedbackService from '@/services/FeedbackService'
import { NewFeedback } from '@/types/feedbacks/Feedback'
import { useQuery } from '@tanstack/react-query'
import useLogging from '@/hooks/useLogging'
import useToastMessageStore from '@/store/useToastMessageStore'
import FlexContainer from '@/components/atoms/flex-container'
import { lazy, Suspense, useState } from 'react'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import { mapOpportunity } from '@/hooks/opportunity/useOpportunitiesQuery'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import { parseRawFeedback } from '@/utils/feedback'
import { BaseRequestReturnType } from '@/services/RequestHandlers/BaseRequestHandler'
import { EvidenceFeedback } from './types'
import InitiativeFeedbackList from './InitiativeFeedbackList'

const FeedbackDetails = lazy(
  () => import('../../feed/new/feedback/feedback-details/FeedbackDetails')
)
const FeedbackConversation = lazy(
  () => import('../../feed/new/feedback/feedback-conversation/FeedbackConversation')
)
const AllOpportunityFeedback = lazy(() => import('../modals/AllOpportunityFeedback'))

const PinnedEvidenceModule = () => {
  const { logException } = useLogging({ context: 'initiative-pinned-feedback' })
  const addErrorToast = useToastMessageStore(state => state.addErrorToast)

  const currentInitiative = useInitiativeStore(state => state.currentInitiative)

  const queryFn = async () => {
    const opportunities = currentInitiative?.opportunities
    if (!opportunities) return

    const opportunitiesRequest = OpportunityService.searchOpportunities({
      opportunity_id: opportunities,
      limit: 10
    })

    const feedbackRequests = opportunities?.map(
      async (opportunity): Promise<BaseRequestReturnType<EvidenceFeedback>> => {
        const [pinnedFeedbackError, pinnedFeedbackData] =
          await OpportunityService.pinnedFeedback(opportunity)
        if (pinnedFeedbackError) {
          return [pinnedFeedbackError, undefined]
        }

        if (pinnedFeedbackData.feedbacks.length > 0) {
          return [
            undefined,
            {
              type: 'pinned',
              feedbacks: pinnedFeedbackData.feedbacks.map(parseRawFeedback)
            } as EvidenceFeedback
          ]
        }

        const [feedbackError, feedbackData] = await FeedbackService.getFeedbackList({
          label: [`opportunity:${opportunity}:true`],
          sort: '-posted_at',
          per_page: 2
        })
        if (feedbackError) {
          return [feedbackError, undefined]
        }

        return [
          undefined,
          {
            type: 'recent',
            feedbacks: feedbackData.feedbacks
          } as EvidenceFeedback
        ]
      }
    )

    const [opportunitiesResponse, ...feedbackResponses] = await Promise.all([
      opportunitiesRequest,
      ...feedbackRequests
    ])
    const [opportunitiesError, opportunitiesData] = opportunitiesResponse
    if (opportunitiesError) {
      console.error(opportunitiesError)
      const message = 'Failed to fetch initiative opportunities.'
      addErrorToast({ text: message })
      logException(opportunitiesError, { message })
      throw opportunitiesError
    }

    const feedbackData: Record<string, EvidenceFeedback> = {}
    for (const [index, feedbackResponse] of feedbackResponses.entries()) {
      const [error, data] = feedbackResponse
      if (error) {
        console.error(error)
        const message = 'Failed to fetch initiative opportunities feedback.'
        addErrorToast({ text: message })
        logException(error, { message })
        throw error
      }

      feedbackData[opportunities[index]] = data
    }

    const pinnedFeedback: (OpportunityItem & { feedback: NewFeedback[] })[] = []
    const recentFeedback: (OpportunityItem & { feedback: NewFeedback[] })[] = []

    opportunitiesData.opportunities.forEach(opportunity => {
      const evidence = feedbackData[opportunity.opportunity_id]
      if (evidence.type === 'pinned') {
        pinnedFeedback.push({
          ...mapOpportunity(opportunity),
          feedback: evidence.feedbacks
        })
      } else {
        recentFeedback.push({
          ...mapOpportunity(opportunity),
          feedback: evidence.feedbacks
        })
      }
    })

    return {
      pinned: pinnedFeedback,
      recent: recentFeedback
    }
  }

  const { data } = useQuery({
    queryKey: ['pinned-evidence', currentInitiative?.id],
    queryFn,
    retry: 1,
    enabled: !!currentInitiative?.opportunities?.length
  })

  const [allFeedbackOpen, setAllFeedbackOpen] = useState<OpportunityItem | null>(null)
  const onSeeAllClick = (opportunity: OpportunityItem) => {
    setAllFeedbackOpen(opportunity)
  }
  const onAllFeedbackModalClose = () => {
    setAllFeedbackOpen(null)
  }

  const [messagesOpen, setMessagesOpen] = useState<NewFeedback | null>(null)
  const onMessagesClick = (feedback: NewFeedback) => {
    setMessagesOpen(feedback)
  }
  function onMessagesModalClose() {
    setMessagesOpen(null)
  }

  const [detailsOpen, setDetailsOpen] = useState<NewFeedback | null>(null)

  const { pinFeedback, unpinFeedback } = useOpportunityMutations()
  const onPinFeedback = (
    feedback: NewFeedback,
    opportunity: OpportunityItem,
    isPinned: boolean
  ) => {
    if (isPinned) {
      pinFeedback({ feedbackList: [feedback], opportunityId: opportunity.id })
    } else {
      unpinFeedback({ feedbackId: feedback.id, opportunityId: opportunity.id })
    }
  }

  const isSingleOpportunity = currentInitiative?.opportunities?.length === 1

  if (!data) return null

  return (
    <FlexContainer direction="column" gap="md">
      <InitiativeFeedbackList
        type="pinned"
        opportunitiesWithFeedback={data.pinned}
        isSingleOpportunity={isSingleOpportunity}
        onSeeAllClick={onSeeAllClick}
        onMessagesClick={onMessagesClick}
        onPinFeedbackClick={onPinFeedback}
        onDetailsClick={setDetailsOpen}
      />

      <InitiativeFeedbackList
        type="recent"
        opportunitiesWithFeedback={data.recent}
        isSingleOpportunity={isSingleOpportunity}
        onSeeAllClick={onSeeAllClick}
        onMessagesClick={onMessagesClick}
        onPinFeedbackClick={onPinFeedback}
        onDetailsClick={setDetailsOpen}
      />

      <Suspense>
        <AllOpportunityFeedback opportunity={allFeedbackOpen} onClose={onAllFeedbackModalClose} />
      </Suspense>

      <Suspense>
        <FeedbackDetails feedback={detailsOpen} onClose={() => setDetailsOpen(null)} />
      </Suspense>

      <Suspense>
        {messagesOpen && (
          <FeedbackConversation feedback={messagesOpen} onOpenChange={onMessagesModalClose} />
        )}
      </Suspense>
    </FlexContainer>
  )
}

export default PinnedEvidenceModule
