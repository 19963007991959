import { styled } from '@/theme'
import { ArrowRight, IconContext } from '@phosphor-icons/react'
import { LinkFromTo } from '../links'

const StyledUl = styled('ul', {
  display: 'flex',
  gap: '$nano',
  listStyle: 'none',
  alignItems: 'center',
  alignContent: 'center'
})

type RootProps = React.ComponentProps<'nav'>
const Root = ({ children, ...props }: RootProps) => {
  return (
    <nav {...props}>
      <StyledUl>
        <IconContext.Provider value={{ size: 16 }}>{children}</IconContext.Provider>
      </StyledUl>
    </nav>
  )
}

const StyledLi = styled('li', {
  fontSize: '$xxxs',

  '&:hover': {
    textDecoration: 'underline'
  },

  variants: {
    active: {
      true: {
        cursor: 'default',
        color: '$brandPrimaryPure',
        fontWeight: '$light',
        '&:hover': {
          textDecoration: 'none'
        }
      },
      false: {
        cursor: 'pointer',
        color: '$brandPrimaryMedium',
        fontWeight: '$light'
      }
    },
    divider: {
      true: {
        height: '$xxs',
        cursor: 'default',
        '&:hover': {
          textDecoration: 'none'
        }
      }
    }
  },

  defaultVariants: {
    active: false
  }
})

const StyledLink = styled(LinkFromTo, {
  all: 'inherit',
  display: 'flex',
  gap: '$nano',
  alignItems: 'center'
})

type ItemProps = React.ComponentProps<typeof StyledLi> & { to?: string }
const Item = ({ children, to, ...props }: ItemProps) => {
  return (
    <StyledLi {...props}>
      <StyledLink to={to ?? ''}>{children}</StyledLink>
    </StyledLi>
  )
}

const Divider = () => (
  <StyledLi divider>
    <ArrowRight />
  </StyledLi>
)

const Breadcrumb = {
  Root,
  Item,
  Divider
}

export default Breadcrumb
