import { useFiltersStore } from '@/store'
import { NumericRangeOption } from '@/types/filters/Filters'
import {
  CustomerRecordType,
  CustomerRecordsNumberFieldValue
} from '@/types/manage-customers/CustomerRecordsFilters'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import useCustomerRecordsFilterQuery from '../common/useCustomerRecordsFilterQuery'

interface Params {
  filterKey: string
  type: string
  name: string
  enabled: boolean
  recordType: CustomerRecordType
}

const useCustomerRecordsNumericFilter = ({
  filterKey,
  type,
  name,
  enabled,
  recordType
}: Params) => {
  const defaultSetParams = { name, type, key: filterKey }

  const { data, isLoading } = useCustomerRecordsFilterQuery[recordType]({ filterKey, enabled })

  const { min, max } = useMemo(() => {
    const defaultRange = { min: 0, max: 10 }
    if (!data) return defaultRange
    const [error, result] = data
    if (error) return defaultRange

    if (result.type === 'number') {
      const resultFilter = result as CustomerRecordsNumberFieldValue
      return { min: resultFilter.min, max: resultFilter.max }
    }
    return defaultRange
  }, [data])

  const store = {
    accounts: {
      numericFilters: useFiltersStore(state => state.accountNumericFilters, shallow),
      setNumericFilter: useFiltersStore(state => state.setAccountNumericFilter),
      setNumericOption: useFiltersStore(state => state.setAccountNumericOption)
    },
    customerUsers: {
      numericFilters: useFiltersStore(state => state.usersNumericFilters, shallow),
      setNumericFilter: useFiltersStore(state => state.setUsersNumericFilter),
      setNumericOption: useFiltersStore(state => state.setUsersNumericOption)
    }
  }

  const numericFilters = store[recordType].numericFilters
  const setNumericFilter = store[recordType].setNumericFilter
  const setNumericOption = store[recordType].setNumericOption

  const storedFilter = useMemo(
    () => numericFilters.find(filter => filter.key === filterKey),
    [numericFilters, filterKey]
  )

  const selectedOption = storedFilter?.draftOption ?? 'all'

  function onOptionChange(value: string) {
    const option = value as NumericRangeOption
    setNumericOption({ ...defaultSetParams, option })
    if (option === 'null') {
      setNumericFilter({ ...defaultSetParams, value: null })
    } else if (option === 'all') {
      setNumericFilter({ ...defaultSetParams, value: null })
    } else if (option === 'is') {
      const range = storedFilter?.draftValue ?? [min, max]
      setNumericFilter({ ...defaultSetParams, value: range })
    } else {
      let range = storedFilter?.draftValue ?? [min, max]
      if (range[0] === range[1]) {
        const value = range[0] === max ? max - 1 : range[0]
        range = [value, value + 1]
      }
      setNumericFilter({ ...defaultSetParams, value: range })
    }
  }

  const rangeValue = storedFilter?.draftValue ?? [min, max]
  const exactValue = [storedFilter?.draftValue?.[0] ?? min] as [number]

  function onRangeChange(range: [number, number]) {
    setNumericFilter({ ...defaultSetParams, value: range })
  }

  function onValueChange(range: [number]) {
    const [value] = range
    setNumericFilter({ ...defaultSetParams, value: [value, value] })
  }

  const onApply = () => {
    setNumericOption({ ...defaultSetParams, option: storedFilter?.draftOption ?? 'all' }, true)
    setNumericFilter({ ...defaultSetParams, value: storedFilter?.draftValue ?? null }, true)
  }

  const isDisabled = useMemo(
    () =>
      JSON.stringify(storedFilter?.draftValue) === JSON.stringify(storedFilter?.value) &&
      storedFilter?.draftOption === storedFilter?.option,
    [storedFilter]
  )

  const isRangeOpen = selectedOption === 'between'
  const isExactValueOpen = selectedOption === 'is'

  const rangeText = `${rangeValue[0]} to ${rangeValue[1]}`
  const exactValueText = exactValue.toString()

  return {
    rangeValue,
    exactValue,
    selectedOption,
    isRangeOpen,
    isExactValueOpen,
    rangeText,
    exactValueText,
    min,
    max,
    isDisabled,
    isLoading,
    onOptionChange,
    onRangeChange,
    onValueChange,
    onApply
  }
}

export default useCustomerRecordsNumericFilter
