import useMetricsTableColumns from '@/hooks/metrics/useMetricsTableColumns'
import useHiddenMetricsStore from '@/store/useHiddenMetricsStore'
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnSort,
  getCoreRowModel,
  getSortedRowModel,
  OnChangeFn,
  SortingState,
  Table,
  useReactTable
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import TableSkeleton from '../skeleton/TableSkeleton'
import TableV2, { getCheckboxState } from '@/components/atoms/table-v2/TableV2'
import useAllOpportunitiesQuery from '@/hooks/opportunity/useAllOpportunitiesQuery'
import OpportunityNameCell from './cells/OpportunityNameCell'
import OpportunityStatusCell from './cells/OpportunityStatusCell'
import { ArrowDown, ArrowUp } from '@phosphor-icons/react'
import { colors } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import Button from '@/components/atoms/button'
import useSegment from '@/hooks/useSegment'
import useAreaOfInterest from '@/hooks/areaOfInterest/useAreaOfInterest'
import { getHeaderCss } from './opportunityTableUtils'
import { ProgressBar } from '@/components/atoms/progress-bar'
import { getParamsFromFilterContent } from '@/utils/filters'
import { LoadStep } from '@/store/useHomeStore'
import Checkbox from '@/components/atoms/checkbox'
import SaveCollectionBar from '../collections/SaveCollectionBar'
import useCollections from '@/hooks/collections/useCollections'
import SaveCollectionDialog from '../collections/SaveCollectionDialog'
import { ONGOING_STATUS, OPPORTUNITY_STATUS_OPTIONS, StatusFilter } from '@/utils/opportunityUtils'
import Select from '@/components/atoms/select'
import useUser from '@/hooks/useUser'
import useOpportunityMutations from '@/hooks/opportunity/useOpportunityMutations'
import { useTranslation, Trans } from 'react-i18next'
import OpportunityOptions from '../exploration/Opportunities/OpportunityTable/OpportunityOptions'
import useRemovingItemsStore from '@/store/useRemoveItemsStore'
import useToastMessageStore from '@/store/useToastMessageStore'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { MoveOppToSuccessMessage } from '../exploration/Opportunities/OpportunitiesList/OpportunitiesList.styles'
import RenameOpportunityDialog from '../exploration/Opportunities/OpportunitiesList/RenameOpportunityDialog'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import {
  OpportunityItem,
  OpportunityItemWithMetrics,
  OpportunityStatus
} from '@/types/opportunity/Opportunity'

const ProgressText = ({ step }: { step: LoadStep }) => {
  if (step === 'loading')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="loadingOpportunityList">
          <em>Loading</em> your opportunity list...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 'ordering')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="orderingAllOpportunities">
          <em>Ordering</em> all opportunities...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  if (step === 'calculating')
    return (
      <ProgressBar.TextParagraph>
        <Trans i18nKey="calculatingAllMetrics">
          <em>Calculating</em> all metrics...
        </Trans>
      </ProgressBar.TextParagraph>
    )

  return <></>
}

const AllOpportunitiesTable = () => {
  const { track } = useSegment()
  const { t } = useTranslation()

  const [sorting, setSorting] = useState<ColumnSort[]>([{ id: 'count:count', desc: true }])
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([
    { id: 'name', value: true }
  ])
  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({})

  const selectedOpportunityIds = useMemo(
    () => Object.keys(rowSelection).map(rowId => rowId.split('&&')[0]),
    [rowSelection]
  )

  const hiddenMetrics = useHiddenMetricsStore(state => state.hiddenMetrics)

  const { setArea } = useAreaOfInterest()
  const setCurrentArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)

  const { applyOpportunityFilters } = useAdvancedFilters()

  const onOpportunityClick = useCallback(
    (opportunity: OpportunityItemWithMetrics) => {
      track('opportunity_access', {
        opportunity_name: opportunity.name,
        from: 'home_all_opportunities'
      })

      if (opportunity.area) {
        setCurrentArea(opportunity.area)
      }

      applyOpportunityFilters({
        opportunity,
        areaIds: opportunity.area ? [opportunity.area.id] : [],
        clearAllFilters: true
      })
    },
    [applyOpportunityFilters, track, setCurrentArea]
  )

  useEffect(() => {
    setSorting(prevSorting => {
      const [sortingColumn] = prevSorting
      if (!sortingColumn) return prevSorting
      if (sortingColumn.id !== 'name' && hiddenMetrics.includes(sortingColumn.id)) {
        if (sortingColumn.id === 'count') {
          return [{ id: 'name', desc: true }]
        }
        return [{ id: 'count:count', desc: true }]
      }
      return prevSorting
    })
  }, [hiddenMetrics])

  const { metricColumns, columnVisibility, setColumnVisibility } =
    useMetricsTableColumns<OpportunityItemWithMetrics>({
      filterFn: item => ({
        ...getParamsFromFilterContent(item.area?.content ?? []),
        context: item.area?.context,
        opportunity_id: item.id
      })
    })

  const toggleSelectAll = useCallback((table: Table<OpportunityItemWithMetrics>) => {
    table.toggleAllRowsSelected()
  }, [])

  const { createCollection } = useCollections({ enabled: false })

  const { updateOpportunity, isUpdatingOpportunity, moveOpportunity, isMovingOpportunity } =
    useOpportunityMutations()

  const onStatusChange = useCallback(
    (opportunity: OpportunityItemWithMetrics) => (newStatus: OpportunityStatus) => {
      if (opportunity.status === newStatus || !newStatus.length) return
      updateOpportunity({
        id: opportunity.id,
        name: opportunity.name,
        status: newStatus
      })

      track('opportunity_change_status', {
        opportunity_name: opportunity.name,
        opportunity_status: newStatus
      })
    },
    [track, updateOpportunity]
  )

  const addLoadingToast = useToastMessageStore(state => state.addLoadingToast)
  const addSuccessToast = useToastMessageStore(state => state.addSuccessToast)
  const removeToast = useToastMessageStore(state => state.removeToast)
  const { pushRemovingItem } = useRemovingItemsStore()

  const onConfirmMoveTo = useCallback(
    (opportunity: OpportunityItemWithMetrics) => (newArea: BaseInterestArea) => {
      const removeLoadingToast = addLoadingToast({ text: t('movingOpportunity') })
      pushRemovingItem(opportunity.id)

      moveOpportunity(
        {
          opportunity,
          currentAreaId: opportunity.relations[0] ?? undefined,
          newAreaId: newArea.id
        },
        {
          onSettled: () => removeLoadingToast(),
          onSuccess: () => {
            const toastId = `opp-move-toast-${opportunity.id}`
            track('areas_and_opps_move_opportunity_to', {
              to: newArea.name,
              from: opportunity.relations[0] ?? '',
              name: opportunity.name
            })
            addSuccessToast({
              id: toastId,
              children: (
                <MoveOppToSuccessMessage
                  areaName={newArea.name}
                  onClick={() => {
                    setArea(newArea)
                    removeToast(toastId)
                  }}
                />
              ),
              duration: 4000
            })
          }
        }
      )
    },
    [
      addLoadingToast,
      pushRemovingItem,
      t,
      addSuccessToast,
      removeToast,
      setArea,
      moveOpportunity,
      track
    ]
  )

  const [opportunityToRename, setOportunityToRename] = useState<
    OpportunityItemWithMetrics | undefined
  >()

  const onConfirmRenameOpportunity = useCallback(
    (newName: string) => {
      if (!opportunityToRename) return
      if (opportunityToRename.name === newName || !newName.length) return

      updateOpportunity({
        id: opportunityToRename.id,
        name: newName.trim(),
        status: opportunityToRename.status
      })
    },
    [opportunityToRename, updateOpportunity]
  )

  const { userPermissions } = useUser()
  const isManager = userPermissions.opportunities.includes('manager')

  const opportunityColumns: ColumnDef<OpportunityItemWithMetrics>[] = useMemo(() => {
    const columns: ColumnDef<OpportunityItemWithMetrics>[] = [
      {
        accessorKey: 'name',
        id: 'name',
        header: ({ table }) => (
          <FlexContainer alignItems="center" css={{ ml: 21 }} gap="nano">
            <Checkbox
              checked={getCheckboxState(table)}
              onCheckedChange={() => toggleSelectAll(table)}
              onClick={e => e.stopPropagation()}
              value="all-opportunities-checkbox"
            />
            <span>Opportunities</span>
          </FlexContainer>
        ),
        enableHiding: false,
        minSize: 400,
        sortDescFirst: true,
        cell: ({ row }) => (
          <OpportunityNameCell
            checkboxDisabled={!row.getCanSelect()}
            checked={row.getIsSelected()}
            data={row.original}
            onCheckedChange={row.getToggleSelectedHandler()}
            onOpportunityClick={onOpportunityClick}
            showCheckbox
          />
        ),
        footer: ({ column }) => column.id
      },
      ...metricColumns,
      {
        accessorKey: 'status',
        id: 'status',
        header: 'Status',
        minSize: 120,
        maxSize: 175,
        enableSorting: true,
        filterFn: (row, _, filterValue) => {
          const status = filterValue as StatusFilter

          if (status === 'ongoing') {
            return ONGOING_STATUS.includes(row.original.status)
          }

          if (status === 'dismissed') {
            return row.original.status === OpportunityStatus.Dismissed
          }

          return true
        },
        cell: ({ row }) => {
          const opportunity = row.original

          const isDraft = opportunity.status === OpportunityStatus.Draft
          if (isDraft || !isManager) {
            return <OpportunityStatusCell status={opportunity.status} />
          }

          return (
            <FlexContainer css={{ padding: '$xxs' }}>
              <Select
                defaultValue={opportunity.status}
                disabled={isUpdatingOpportunity || isMovingOpportunity}
                onValueChange={onStatusChange(opportunity)}
                options={OPPORTUNITY_STATUS_OPTIONS}
                small
                value={opportunity.status}
                width={140}
              />
            </FlexContainer>
          )
        },
        footer: ({ column }) => column.id
      }
    ]

    if (isManager) {
      columns.push({
        id: 'options',
        minSize: 40,
        maxSize: 40,
        enableSorting: false,
        cell: data => {
          return (
            <OpportunityOptions
              data={data.row.original}
              onConfirmMoveTo={onConfirmMoveTo(data.row.original)}
              onRename={() => setOportunityToRename(data.row.original)}
            />
          )
        }
      })
    }

    return columns
  }, [
    metricColumns,
    isUpdatingOpportunity,
    isMovingOpportunity,
    onStatusChange,
    isManager,
    onOpportunityClick,
    toggleSelectAll,
    onConfirmMoveTo
  ])

  const getRowId = useCallback((row: OpportunityItem) => {
    return row.id + '&&' + row.filterId + '&&' + row.relations.length
  }, [])

  const {
    opportunities,
    isLoading,
    isMetricsLoading,
    isSortingMetricsLoading,
    hasMore,
    loadNextPage,
    loadStep,
    progress,
    fetchSortingMetrics,
    allOpportunities
  } = useAllOpportunitiesQuery({
    sortColumn: sorting[0]?.id,
    sortDirection: sorting[0]?.desc ? 'desc' : 'asc'
  })

  const onSetSorting: OnChangeFn<SortingState> = useCallback(
    data => {
      fetchSortingMetrics(allOpportunities)
      setSorting(data)
    },
    [fetchSortingMetrics, allOpportunities]
  )

  const isLoadMoreDisabled = isMetricsLoading || isSortingMetricsLoading
  const isSomethingLoading = isLoading || isMetricsLoading || isSortingMetricsLoading

  const table = useReactTable<OpportunityItemWithMetrics>({
    data: opportunities,
    columns: opportunityColumns,
    manualFiltering: true,
    manualSorting: true,
    getRowId,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: onSetSorting,
    enableColumnResizing: false,
    enableHiding: true,
    enableRowSelection: true,
    enableSorting: !isSomethingLoading,
    onRowSelectionChange: setRowSelection,
    state: {
      columnVisibility,
      columnFilters,
      sorting,
      rowSelection,
      columnPinning: {
        left: ['name'],
        right: ['status', 'options']
      }
    }
  })

  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false)

  const onSaveCollectionBarClick = () => {
    setIsSaveModalOpen(true)
  }

  const onCancelBarClick = () => {
    setRowSelection({})
  }

  const onSaveCollection = useCallback(
    async (name: string, ids: string[]) => {
      const filterIds = allOpportunities
        .filter(opp => ids.includes(opp.id))
        .map(opp => opp.filterId)
      createCollection({
        name,
        filterIds,
        description: '',
        type: 'opportunity',
        visibility: 'private'
      })

      setRowSelection({})
    },
    [createCollection, allOpportunities]
  )

  const headerCss = useCallback(getHeaderCss, [])

  const renderFooter = () => {
    if (hasMore) {
      return (
        <FlexContainer
          alignItems="center"
          css={{ mb: selectedOpportunityIds.length > 0 ? 64 : 0 }}
          fullWidth
          justifyContent="center"
        >
          <Button disabled={isLoadMoreDisabled} onClick={loadNextPage} variant="link">
            <ArrowDown />
            <span>{t('loadMore')}</span>
          </Button>
        </FlexContainer>
      )
    }
  }

  return (
    <FlexContainer
      css={{ maxHeight: 'max(63.5vh, 500px)', pb: '$xxs' }}
      direction="column"
      gap="xxxs"
    >
      <ProgressBar.Container direction="column" fullWidth gap="micro" visible={isSomethingLoading}>
        <ProgressText step={loadStep} />
        <ProgressBar.Bar max={100} value={progress} />
      </ProgressBar.Container>

      {isLoading ? (
        <TableSkeleton />
      ) : (
        <TableV2
          enableSort
          footer={renderFooter()}
          sortIndicator={<ArrowUp color={colors.neutralLowPure} size={16} />}
          table={table}
          thContainerProps={headerCss}
          // renderSubComponent={su}
        />
      )}

      {!!opportunityToRename && (
        <RenameOpportunityDialog
          onClose={() => setOportunityToRename(undefined)}
          onSave={onConfirmRenameOpportunity}
          opportunityName={opportunityToRename.name}
        />
      )}

      <SaveCollectionBar
        filterIds={selectedOpportunityIds}
        onCancel={onCancelBarClick}
        onSave={onSaveCollectionBarClick}
      />
      <SaveCollectionDialog
        filterIds={selectedOpportunityIds}
        onOpenChange={setIsSaveModalOpen}
        onSave={onSaveCollection}
        open={isSaveModalOpen}
        type="opportunity"
      />
    </FlexContainer>
  )
}

export default AllOpportunitiesTable
