import { InitiativeItem } from '@/types/initiatives/Initiatives'
import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

interface InitiativeState {
  currentInitiative: InitiativeItem | undefined
  setCurrentInitiative: (data: InitiativeItem | undefined) => void
}

const useInitiativeStore = createWithEqualityFn<InitiativeState>(
  set => ({
    currentInitiative: undefined,
    setCurrentInitiative: data => set({ currentInitiative: data })
  }),
  shallow
)

export default useInitiativeStore
