import { Fragment, useMemo } from 'react'
import { EvidenceFeedbackType, OpportunityWithFeedback } from './types'
import { useTranslation } from 'react-i18next'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import Button from '@/components/atoms/button'
import FeedbackItem from '../../feed/new/feedback/feedback-item/FeedbackItem'
import { NewFeedback } from '@/types/feedbacks/Feedback'
import { OpportunityItem } from '@/types/opportunity/Opportunity'

interface Props {
  type: EvidenceFeedbackType
  opportunitiesWithFeedback: OpportunityWithFeedback[]
  isSingleOpportunity: boolean
  onSeeAllClick: (opportunity: OpportunityWithFeedback) => void
  onMessagesClick: (feedback: NewFeedback) => void
  onPinFeedbackClick: (
    feedback: NewFeedback,
    opportunity: OpportunityItem,
    isPinned: boolean
  ) => void
  onDetailsClick: (feedbacck: NewFeedback) => void
}

const InitiativeFeedbackList = ({
  type,
  opportunitiesWithFeedback,
  isSingleOpportunity,
  onSeeAllClick,
  onMessagesClick,
  onPinFeedbackClick,
  onDetailsClick
}: Props) => {
  const { t } = useTranslation()

  const title = useMemo(
    () => (type === 'pinned' ? t('voiceOfCustomerPinnedEvidence') : t('voiceOfCustomerMostRecent')),
    [type, t]
  )

  if (opportunitiesWithFeedback.length === 0) return null

  if (opportunitiesWithFeedback.every(opportunity => opportunity.feedback.length === 0)) return null

  return (
    <FlexContainer direction="column" gap="sm">
      <FlexContainer alignItems="center" justifyContent="spaceBetween">
        <Text
          as="h2"
          color="brandPrimaryPure"
          css={{ fontSize: '$xs', lineHeight: 1.25, fontWeight: '$light' }}
        >
          {title}
        </Text>
        {isSingleOpportunity && (
          <Button
            onClick={() => onSeeAllClick(opportunitiesWithFeedback[0])}
            size="small"
            variant="link"
          >
            {t('seeAll')}
          </Button>
        )}
      </FlexContainer>

      <FlexContainer direction="column" gap="xl">
        {opportunitiesWithFeedback.map(opportunity => (
          <>
            {opportunity.feedback.length > 0 ? (
              <FlexContainer direction="column" key={opportunity.id}>
                {!isSingleOpportunity && (
                  <FlexContainer justifyContent="spaceBetween" alignItems="center">
                    <Text color="brandPrimaryMedium" fontSize="xxxs" fontWeight="light">
                      {opportunity.name}
                    </Text>

                    <Button
                      text
                      onClick={() => onSeeAllClick(opportunity)}
                      size="small"
                      variant="flat-bordered"
                      css={{ border: 'none', fontWeight: '$regular' }}
                    >
                      {t('seeAll')}
                    </Button>
                  </FlexContainer>
                )}

                <FlexContainer direction="column" gap="xs">
                  {opportunity.feedback.map(feedback => (
                    <FeedbackItem
                      key={feedback.id + opportunity.id}
                      {...feedback}
                      showPin
                      isPinned={type === 'pinned'}
                      onMessagesClick={() => onMessagesClick(feedback)}
                      onPinToggle={isPinned => onPinFeedbackClick(feedback, opportunity, isPinned)}
                      onSelectFeedback={() => onDetailsClick(feedback)}
                    />
                  ))}
                </FlexContainer>
              </FlexContainer>
            ) : (
              <Fragment key={opportunity.id} />
            )}
          </>
        ))}
      </FlexContainer>
    </FlexContainer>
  )
}

export default InitiativeFeedbackList
