import { styled } from '@/theme'
import { InitiativeStatus } from '@/types/initiatives/Initiatives'

const StatusChip = styled('span', {
  padding: '4px 12px',
  borderRadius: 8,
  fontSize: '$micro',

  variants: {
    status: {
      todo: {
        background: '$feedbackNegativeLight',
        color: '$feedbackNegativePure'
      },
      doing: {
        background: '$feedbackInformativeLight',
        color: '$feedbackInformativePure'
      },
      done: {
        background: '$feedbackPositiveLight',
        color: '$feedbackPositivePure'
      },
      default: {
        background: '$neutralHighMedium',
        color: '$neutralLowMedium'
      }
    }
  },

  defaultVariants: {
    status: 'default'
  }
})

interface Props {
  status: InitiativeStatus
}

const statusDisplayMap: Record<InitiativeStatus, string> = {
  todo: 'To do',
  doing: 'Doing',
  done: 'Done'
}

const InitiativeStatusChip = ({ status }: Props) => {
  return <StatusChip status={status}>{statusDisplayMap[status]}</StatusChip>
}

export default InitiativeStatusChip
