import { useTranslation } from 'react-i18next'
import OrganizationStyles from './OrganizationSettings.styles'
import FlexContainer from '@/components/atoms/flex-container'
import type { DateValue } from '@react-types/calendar'
import { ActiveUsersByDateConfigItem } from '@/types/organization/Organization'
import { useMemo } from 'react'
import { styled } from '@/theme'
import Dialog from '@/components/atoms/dialog'
import ActiveUserRow from './ActiveUserRow'

const Container = styled(FlexContainer, {
  flexDirection: 'column',
  width: 500,
  br: '$micro',
  height: '100%',
  gap: '$xs',
  padding: '$xxs'
})

const ScrollContainer = styled(FlexContainer, {
  flex: 1,
  overflowY: 'auto',
  flexDirection: 'column',
  minHeight: 200,
  width: '100%',
  height: '100%',
  gap: '$xxs'
})

interface Props {
  allActiveUsersByDateList: ActiveUsersByDateConfigItem[]
  sortedByDate: ActiveUsersByDateConfigItem[]
  open: boolean
  isDateUnavailable: (date: DateValue) => boolean
  setOpen: (open: boolean) => void
  onChangeItemDate: (currentKey: string | null, newDate: DateValue) => void
  onChangeItemValue: (key: string | null, value: string) => void
  onDeleteEntry: (key: string | null) => void
}

const ActiveUsersHistoryModal = ({
  sortedByDate,
  open,
  isDateUnavailable,
  setOpen,
  onChangeItemDate,
  onChangeItemValue,
  onDeleteEntry
}: Props) => {
  const { t } = useTranslation()

  const historyByYear = useMemo(() => {
    let list: { year: number; items: ActiveUsersByDateConfigItem[] }[] = []

    sortedByDate.forEach(item => {
      if (!item.date) return
      const year = item.date.year

      const yearSection = list.find(section => section.year === year)
      if (!yearSection) {
        list = [...list, { year, items: [item] }]
      } else {
        const items = [...yearSection.items, item]
        list = list.map(section => (section.year === year ? { ...section, items } : section))
      }
    })

    return list
  }, [sortedByDate])

  return (
    <Dialog
      align="right"
      closeIcon
      contentProps={{ css: { width: 500, minWidth: 500 } }}
      onOpenChange={setOpen}
      open={open}
      useFullHeight
      width="fixed"
      title={t('activeUsersHistory')}
      hiddenTitle
    >
      <Container>
        <OrganizationStyles.ColText>{t('activeUsersHistory')}</OrganizationStyles.ColText>

        <ScrollContainer>
          <FlexContainer direction="column" gap="xs">
            {!historyByYear.length && (
              <FlexContainer alignItems="center" justifyContent="center">
                <OrganizationStyles.ColText description css={{ textAlign: 'center' }}>
                  {t('noResults')}
                </OrganizationStyles.ColText>
              </FlexContainer>
            )}
            {historyByYear.map(section => (
              <OrganizationStyles.Card gap="xxs" key={section.year}>
                <OrganizationStyles.ColText description>{section.year}</OrganizationStyles.ColText>

                <FlexContainer gap="xxs" fullWidth alignItems="center" direction={'column'}>
                  {section.items.map(item => (
                    <ActiveUserRow
                      item={item}
                      key={item.key}
                      canAddNewEntry={false}
                      isDateUnavailable={isDateUnavailable}
                      showDeleteButton={item.key !== null}
                      onChangeItemDate={onChangeItemDate}
                      onChangeItemValue={onChangeItemValue}
                      onDeleteEntry={onDeleteEntry}
                    />
                  ))}
                </FlexContainer>
              </OrganizationStyles.Card>
            ))}
          </FlexContainer>
        </ScrollContainer>
      </Container>
    </Dialog>
  )
}

export default ActiveUsersHistoryModal
