import { styled } from '@/theme'

const ChipContainer = styled('div', {
  padding: '$nano $xxxs',
  fontSize: '$xxxs',
  fontWeight: '$light',
  background: '$neutralHighMedium',
  borderRadius: 8,
  color: '$neutralLowPure',
  overflow: 'hidden',
  lineBreak: 'anywhere',
  maxWidth: 320,

  '.name': {
    color: '$neutralLowLight'
  }
})

interface Props {
  name: string
  value: string
}

const IdChip = ({ name, value }: Props) => {
  return (
    <ChipContainer>
      <span className="name">{name}:</span> {value}
    </ChipContainer>
  )
}

export default IdChip
