import { FilterComponentProps } from './types'
import i18n from '../../../../../plugins/i18n/i18n'
import { filterToSentiment, Sentiment, sentimentToFilter } from '@/utils/sentiments'
import Select, { SelectOption } from '@/components/atoms/select'
import { useTranslation } from 'react-i18next'

const sentimentOptions: SelectOption<Sentiment>[] = [
  {
    value: Sentiment.Negative,
    text: i18n.t('negative')
  },
  {
    value: Sentiment.MostlyNegative,
    text: i18n.t('mostlyNegative')
  },
  {
    value: Sentiment.MostlyNeutral,
    text: i18n.t('mostlyNeutral')
  },
  {
    value: Sentiment.MostlyPositive,
    text: i18n.t('mostlyPositive')
  },
  {
    value: Sentiment.Positive,
    text: i18n.t('positive')
  }
]

const SentimentFilterRow = ({ filter, onFilterChange }: FilterComponentProps) => {
  const { t } = useTranslation()
  const sentimentValue = filterToSentiment(filter)

  const onValueChange = (value: Sentiment) => {
    onFilterChange(sentimentToFilter(value))
  }

  return (
    <Select
      onValueChange={onValueChange}
      options={sentimentOptions}
      small
      value={sentimentValue}
      width="100%"
      placeholder={t('selectSentimentLabel')}
    />
  )
}

export default SentimentFilterRow
