import { Megaphone } from '@phosphor-icons/react'
import {
  integerFormatter,
  percentageFormatter,
  percentageScoreTransformer,
  scoreFormatter
} from './formatters'
import { MetricItem, TicketsSatisfactionMetricKey } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

export const TICKET_NPS_METRIC_KEYS: TicketsSatisfactionMetricKey[] = [
  'tnps',
  'ticket_nps_score',
  'ticket_nps_share'
]
export const TICKET_CSAT_METRIC_KEYS: TicketsSatisfactionMetricKey[] = [
  'tcsat',
  'ticket_csat_score',
  'ticket_csat_share'
]

export const TICKET_SATISFACTION_METRICS_KEYS: TicketsSatisfactionMetricKey[] = [
  ...TICKET_CSAT_METRIC_KEYS,
  ...TICKET_NPS_METRIC_KEYS
]

/** they are show inside support tickets metrics */
export const ticketsSatisfactionMetricItems: Record<TicketsSatisfactionMetricKey, MetricItem> = {
  tnps: {
    label: i18n.t('tnpsCount'),
    selectedLabel: i18n.t('ticketTnpsCount'),
    icon: Megaphone,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `tnps_count`,
      filter: { 'support_ticket.survey_type': 'nps' }
    }
  },
  tcsat: {
    label: i18n.t('tcsatCount'),
    selectedLabel: i18n.t('ticketTcsatCount'),
    icon: Megaphone,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `tcsat_count`,
      filter: { 'support_ticket.survey_type': 'csat' }
    }
  },
  ticket_nps_score: {
    label: i18n.t('tnpsScore'),
    selectedLabel: i18n.t('ticketNpsScore'),
    icon: Megaphone,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'tnps_score',
      label: 'tnps_score'
    }
  },
  ticket_csat_score: {
    label: i18n.t('tcsatScore'),
    selectedLabel: i18n.t('ticketCsatScore'),
    icon: Megaphone,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'tcsat_score',
      label: 'tcsat_score'
    }
  },
  ticket_csat_share: {
    label: i18n.t('tcsatShare'),
    selectedLabel: i18n.t('ticketTcsatShare'),
    icon: Megaphone,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `ticket_csat_share`,
      filter: { 'support_ticket.survey_type': 'csat' }
    }
  },
  ticket_nps_share: {
    label: i18n.t('tnpsShare'),
    selectedLabel: i18n.t('ticketTnpsShare'),
    icon: Megaphone,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `ticket_nps_share`,
      filter: { 'support_ticket.survey_type': 'nps' }
    }
  }
}
