import { SelectOption } from '@/components/atoms/select'
import { ChartType } from '@/types/dashboard'
import { TimeSeriesIntervalOption } from '@/types/time-series/TimeSeries'
import { ChartBar, ChartLine, Equalizer, Table } from '@phosphor-icons/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useSegment from '../useSegment'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import { shallow } from 'zustand/shallow'
import { IntervalOption } from '@/types/analytics/Analytics'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { getDifferenceInDays } from '@/utils/date'
import i18n from '../../plugins/i18n/i18n'
import useOpportunityStore from '@/store/useOpportunityStore'
import { useLocation } from 'react-router-dom'

const useTimeSeriesOptions = () => {
  const { track } = useSegment()

  const currentInterestArea = useCurrentInterestAreaStore(
    state => state.currentInterestArea,
    shallow
  )
  const currentOpportunity = useOpportunityStore(state => state.currentOpportunity, shallow)

  const { pathname } = useLocation()
  const isExplorationPage = pathname === '/exploration'

  const [interval, setInterval] = useState<TimeSeriesIntervalOption>(TimeSeriesIntervalOption.Week)
  const intervalOptions: SelectOption<TimeSeriesIntervalOption>[] = useMemo(
    () => [
      { text: i18n.t('day'), value: TimeSeriesIntervalOption.Day },
      { text: i18n.t('week'), value: TimeSeriesIntervalOption.Week },
      { text: i18n.t('month'), value: TimeSeriesIntervalOption.Month },
      // { text: 'Quarter', value: TimeSeriesIntervalOption.Quarter },
      { text: i18n.t('year'), value: TimeSeriesIntervalOption.Year }
    ],
    []
  )
  const onIntervalChange = useCallback(
    (value: string) => {
      setInterval(value as TimeSeriesIntervalOption)

      if (currentOpportunity) {
        track('opportunity_trendline_period', { period: value })
      } else if (currentInterestArea) {
        track('area_trendline_period', { period: value })
      } else if (isExplorationPage) {
        track('exploration_trendline_period', { period: value })
      } else {
        track('area_and_opps_trendline_period', { period: value })
      }
    },
    [currentOpportunity, isExplorationPage, currentInterestArea, track]
  )

  const { dateRange } = useDateFilterStore(state => ({
    dateRange: state.dateRange
  }))

  useEffect(() => {
    const differenceInDays = dateRange ? getDifferenceInDays(dateRange) : 0
    const isLargeInterval = !dateRange || differenceInDays > 93
    const isSmallInterval = dateRange ? differenceInDays <= 8 : false

    isSmallInterval && onIntervalChange(TimeSeriesIntervalOption.Day)
    isLargeInterval && onIntervalChange(TimeSeriesIntervalOption.Month)
  }, [dateRange, onIntervalChange])

  const [chartType, setChartType] = useState<ChartType>('line')
  const chartTypeOptions: SelectOption<ChartType>[] = [
    { text: i18n.t('line'), value: 'line', icon: <ChartLine /> },
    { text: i18n.t('bar'), value: 'bar', icon: <ChartBar /> },
    { text: i18n.t('stackedBar'), value: 'stacked-bar', icon: <Equalizer weight="fill" /> },
    { text: i18n.t('table'), value: 'table', icon: <Table /> }
  ]

  const onChartTypeChange = useCallback(
    (value: string) => {
      setChartType(value as ChartType)

      if (currentOpportunity) {
        track('opportunity_trendline_vis_type', { chart_type: value })
      } else if (currentInterestArea) {
        track('area_trendline_vis_type', { chart_type: value })
      } else if (isExplorationPage) {
        track('exploration_trendline_vis_type', { chart_type: value })
      } else {
        track('area_and_opps_trendline_vis_type', { chart_type: value })
      }
    },
    [currentOpportunity, isExplorationPage, currentInterestArea, track]
  )

  return {
    interval,
    intervalOptions,
    onIntervalChange,

    chartType,
    chartTypeOptions,
    onChartTypeChange
  }
}

export default useTimeSeriesOptions

export const mapTimeSeriesIntervalToAnalyticsInterval: Record<
  TimeSeriesIntervalOption,
  IntervalOption
> = {
  [TimeSeriesIntervalOption.Day]: IntervalOption.Day,
  [TimeSeriesIntervalOption.Week]: IntervalOption.Week,
  [TimeSeriesIntervalOption.Month]: IntervalOption.Month,
  [TimeSeriesIntervalOption.Quarter]: IntervalOption.Quarter,
  [TimeSeriesIntervalOption.Year]: IntervalOption.Year
}
