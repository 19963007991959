import { OpportunityStatusResponse } from '@/types/opportunity/OpportunityRequests'
import RequestHandler from './RequestHandlers/NewRequest'
import { OpportunityRequests } from '@/types/opportunity'
import { AxiosRequestConfig } from 'axios'

export default class OpportunityService {
  static PREFIX = '/opportunity'

  static async getStatusList() {
    return await RequestHandler.get<OpportunityStatusResponse>(`${this.PREFIX}/status`)
  }

  static async searchOpportunities(
    params: OpportunityRequests.SearchOpportunityParams,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.get<OpportunityRequests.SearchOpportunityResponse>(
      `${this.PREFIX}`,
      params,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async createOpportunity(
    payload: OpportunityRequests.CreateOpportunityPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.post<OpportunityRequests.CreateOpportunityResponse>(
      this.PREFIX,
      payload,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async getOpportunity(opportunityId: string, config?: AxiosRequestConfig<unknown>) {
    return await RequestHandler.get<OpportunityRequests.GetOpportunityResponse>(
      `${this.PREFIX}/${opportunityId}`,
      undefined,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async updateOpportunity(
    opportunityId: string,
    payload: OpportunityRequests.UpdateOpportunityPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.put<OpportunityRequests.UpdateOpportunityResponse>(
      `${this.PREFIX}/${opportunityId}`,
      payload,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async removeOpportunity(opportunityId: string, config?: AxiosRequestConfig<unknown>) {
    return await RequestHandler.del<void>(`${this.PREFIX}/${opportunityId}`, undefined, config)
  }

  static async getDescriptionSummary(
    payload: OpportunityRequests.OpportunityDescriptionSummaryPayload,
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.get<OpportunityRequests.OpportunityDescriptionSummaryResponse>(
      `${this.PREFIX}/summary`,
      payload,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async linkOpportunityToAreas(
    params: {
      opportunityId: string
      areaIds: string[]
    },
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.put<void>(
      `${this.PREFIX}/${params.opportunityId}/areas`,
      {
        area_ids: params.areaIds,
        sync: true
      },
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async unlinkAreaFromOpportunity(
    params: {
      opportunityId: string
      areaId: string
    },
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.del<void>(
      `${this.PREFIX}/${params.opportunityId}/area/${params.areaId}`,
      undefined,
      undefined,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async insertFeedback(
    params: {
      opportunityId: string
      feedbackIds: string[]
    },
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.put<void>(
      `${this.PREFIX}/${params.opportunityId}/feedbacks`,
      {
        feedback_ids: params.feedbackIds
      },
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async removeFeedback(
    params: {
      opportunityId: string
      feedbackId: string
    },
    config?: AxiosRequestConfig<unknown>
  ) {
    return await RequestHandler.del<void>(
      `${this.PREFIX}/${params.opportunityId}/feedback/${params.feedbackId}`,
      undefined,
      undefined,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }

  static async pinnedFeedback(opportunityId: string, config?: AxiosRequestConfig<unknown>) {
    return await RequestHandler.get<OpportunityRequests.PinnedFeedbackResponse>(
      `${this.PREFIX}/${opportunityId}/pinned_feedbacks`,
      undefined,
      {
        ...config,
        paramsSerializer: {
          indexes: null
        }
      }
    )
  }
}
