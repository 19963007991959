import useLetterCase from '@/hooks/useLetterCase'
import { FilterString } from '@/types/filters/Filters'
import {
  SimpleSentimentFilterContent,
  useSentimentSelection
} from '../../FiltersSidebar/SentimentFilter'
import { FilterItemDropdown } from '../FilterItem'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'
import { getSentimentName } from '@/utils/sentiments'

interface AppliedSentimentFiltersEdit {
  filter: FilterString
}

function AppliedSentimentFiltersEdit(props: AppliedSentimentFiltersEdit) {
  const { capitalizeFirst } = useLetterCase()
  const { t } = useTranslation()
  const { isChecked, selectOption, hasChanges, apply } = useSentimentSelection()

  function getTooltipText(filter: FilterString) {
    const selectedOptions = filter.selected
      .map(option => `<b>${capitalizeFirst(getSentimentName(Number(option)))}</b>`)
      .join(t('or'))

    return i18n.t('isSelectedOptions', { selectedOptions })
  }

  return (
    <FilterItemDropdown
      content={props.filter.selected.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={<SimpleSentimentFilterContent isChecked={isChecked} selectOption={selectOption} />}
      onApply={apply}
      title={t('sentiment')}
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default AppliedSentimentFiltersEdit
