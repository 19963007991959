import FilterSubMenu from '../../FiltersSidebar/AddFilterMenu/FilterSubMenu'
import { SmileyWink } from '@phosphor-icons/react'
import { SentimentFilterContent } from '../../FiltersSidebar/SentimentFilter'
import useSentimentFilter from '@/hooks/advancedFilters/useSentimentFilter'

const SentimentSubMenu = () => {
  const { apply, selectedOption, setSelectedOption, isDisabled } = useSentimentFilter({})

  return (
    <FilterSubMenu icon={<SmileyWink />} onApply={apply} title="Sentiment" isDisabled={isDisabled}>
      <SentimentFilterContent value={selectedOption} onValueChange={setSelectedOption} />
    </FilterSubMenu>
  )
}

export default SentimentSubMenu
