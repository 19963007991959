import Breadcrumb from '@/components/atoms/breadcrumb'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { dateShortMonthFormat } from '@/utils/date'
import { useUserStore } from '@/store'
import { shallow } from 'zustand/shallow'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import RenameInitiativeDialog from './RenameInitiativeDialog'
import { useInitiatives } from '@/hooks/initiatives/useInitiatives'
import useUser from '@/hooks/useUser'
import IconButton from '@/components/atoms/icon-button'
import { PencilSimple } from '@phosphor-icons/react'

const InitiativeHeader = () => {
  const { t } = useTranslation()
  const currentInitiative = useInitiativeStore(state => state.currentInitiative)
  const { userPermissions } = useUser()
  const isManager = userPermissions.opportunities.includes('manager')

  const initiativeCreatedAt = useMemo(() => {
    const createdAt = currentInitiative?.createdAt

    if (!createdAt || !(createdAt instanceof Date)) return
    const createdAtDate = `${t('createdAt')} ${dateShortMonthFormat(createdAt)} `

    return createdAtDate
  }, [currentInitiative, t])

  const users = useUserStore(state => state.users, shallow)

  const initiativeCreatedBy = useMemo(() => {
    const createdByID = currentInitiative?.createdBy
    const createdByUser = users.find(user => user.user_id === createdByID)

    if (!createdByUser) return

    if (createdByUser.email.includes('@birdie')) {
      return `| ${t('suggestedByBirdie')}`
    }

    const creatorName = createdByUser?.name
    const createdByName = `| ${t('createdBy')} ${creatorName} `

    return createdByName
  }, [currentInitiative, users, t])

  const [isRenameOpen, setIsRenameOpen] = useState(false)
  const { updateInitiative } = useInitiatives({
    enabled: false
  })
  const onConfirmRename = (name: string) => {
    if (!currentInitiative) return
    if (currentInitiative.name === name || !name.length) return

    updateInitiative({
      initiativeId: currentInitiative.id,
      name
    })
  }

  return (
    <>
      <FlexContainer>
        <FlexContainer direction="column" gap="micro">
          <Breadcrumb.Root>
            <Breadcrumb.Item to="/initiatives">
              <span>{t('initiatives')}</span>
            </Breadcrumb.Item>
            <Breadcrumb.Divider />

            <Breadcrumb.Item active>{currentInitiative?.name}</Breadcrumb.Item>
          </Breadcrumb.Root>

          <FlexContainer alignItems="center" gap="micro">
            <Text color="brandPrimaryPure" fontSize="md" fontWeight="light">
              {currentInitiative?.name}
            </Text>
            {isManager && (
              <IconButton onClick={() => setIsRenameOpen(true)}>
                <PencilSimple size={16} />
              </IconButton>
            )}
          </FlexContainer>

          <Text color="brandPrimaryMedium" fontSize="micro" fontWeight="light">
            {initiativeCreatedAt} {initiativeCreatedBy}
          </Text>
        </FlexContainer>
      </FlexContainer>

      {!!isRenameOpen && (
        <RenameInitiativeDialog
          onClose={() => setIsRenameOpen(false)}
          onSave={onConfirmRename}
          initiativeName={currentInitiative?.name}
        />
      )}
    </>
  )
}

export default InitiativeHeader
