import Dialog from '@/components/atoms/dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import useReasons from '@/hooks/useReasons'
import { CSS, styled } from '@/theme'
import { Reason } from '@/types/reasons'
import { Lightning } from '@phosphor-icons/react'
import { lazy, Suspense, useState, useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import {
  ScrollAreaRoot,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '../scroll-area'
import ReasonCard from './ReasonCard'
import ReasonDetails from './ReasonDetails'
import { useReasonsFeedbackStore } from '@/store/useFeedbackStore'
import { Feedback } from '@/types/feedbacks'
import useKeywordStore from '@/store/useKeywordsStore'
import { useFeedbacks } from '@/hooks/feedback/useFeedbacks'
import useSegment from '@/hooks/useSegment'
import FeedbackDetails from '../feedback-details'
import Button from '@/components/atoms/button'
import { useTranslation } from 'react-i18next'

const FeedbackThread = lazy(() => import('../feed/feedbacks/FeedbackThread'))

const ReasonsContainer = styled('div', {
  display: 'flex',
  width: '100%',
  height: '100%',
  flexGrow: 2,
  overflow: 'hidden',
  boxSizing: 'border-box',
  gap: '$xxxs'
})

const ReasonsScrollArea = styled(ScrollAreaRoot, {
  minHeight: 1
})

const cardCSS: CSS = {
  mx: '$sm',
  my: '$nano',

  '&:first-child': {
    mt: '$xxs'
  },
  '&:last-child': {
    mb: '$sm'
  }
}

const mapClusterNames = (value: string, index: number, array: string[]) => {
  if (array.length === 1) {
    return <strong>{value}</strong>
  }

  if (index === array.length - 2) {
    return (
      <>
        <strong>{value}</strong> and{' '}
      </>
    )
  }

  if (index === array.length - 1) {
    return <strong>{value}</strong>
  }

  return (
    <>
      <strong>{value}</strong>,{' '}
    </>
  )
}

interface ReasonsAreaProps {
  onClose: () => void
}

const ReasonsArea = ({ onClose }: ReasonsAreaProps) => {
  const { track } = useSegment()
  const { t } = useTranslation()

  const {
    reasons,
    filteredReasons,
    totalFrequency,
    filteredFrequency,
    selectedReason,
    selectReason,
    keywordHashes,
    isLoading,
    isLoadingChart,
    trendSeries,
    cancelRequest
  } = useReasons()

  const { reset } = useFeedbacks({
    archived: false,
    store: 'reasons'
  })

  const clusters = useKeywordStore(state => state.data, shallow)
  const { selectedFeedback } = useReasonsFeedbackStore(
    state => ({
      selectedFeedback: state.selectedFeedback
    }),
    shallow
  )
  const setSelectedFeedback = useReasonsFeedbackStore(state => state.setSelectedFeedback)

  const [feedbackThreadOpen, setFeedbackThreadOpen] = useState<Feedback | null>(null)

  const onOpenChange = (open: boolean) => {
    if (open) return

    onClose()
    cancelRequest()
    setShowAll(false)
    selectReason(null)
    setSelectedFeedback(null)
  }

  const onReasonClick = (reason: Reason, index: number) => {
    selectReason(reason)

    if (reason.id !== selectedReason?.id) {
      track('explore_reason_view_detail', { reason_index: index })
      reset()
      setSelectedFeedback(null)
    }
  }

  const clusterNames = useMemo(
    () =>
      clusters
        .filter(cluster =>
          cluster.keywordList.some(keyword => keywordHashes.includes(keyword.keywordHash))
        )
        .map(cluster => cluster.cluster),
    [clusters, keywordHashes]
  )
  const hasFeedback = !!selectedFeedback || !!feedbackThreadOpen

  const [showAll, setShowAll] = useState(false)
  const isShowAllButtonVisible = !showAll && !(isLoading && reasons.length === 0)
  const onShowAllClick = () => {
    setShowAll(true)
  }

  function renderHeader() {
    if (showAll) {
      const showingAllFindingsText = t('showingAllFindings', {
        count: reasons.length,
        clusterNames: clusterNames.map(mapClusterNames)
      })

      const totalFeedbackText = t('totalFeedback', {
        totalFrequency
      })
      return (
        <>
          <Text typeface="subtitleRegularXXS">{showingAllFindingsText}</Text>
          <Text color="neutralLowLight" typeface="paragraphRegularMicro">
            {totalFeedbackText}
          </Text>
        </>
      )
    }
    const topFindingsText = t('topFindings', {
      count: filteredReasons.length,
      clusterNames: clusterNames.map(mapClusterNames)
    })

    const totalFeedbackFromText = t('totalFeedbackFrom', {
      filteredFrequency,
      totalFrequency
    })
    return (
      <>
        <Text typeface="subtitleRegularXXS">{topFindingsText}</Text>
        <Text color="neutralLowLight" typeface="paragraphRegularMicro">
          {totalFeedbackFromText}
        </Text>
      </>
    )
  }

  return (
    <Dialog
      align="right"
      closeIcon={!hasFeedback}
      contentProps={{
        css: { right: hasFeedback ? 'calc(min(calc(30% - 32px), 486px) + 2 * $xxs)' : '$xxs' }
      }}
      enableInteractionsOutside={false}
      modal={false}
      noBackdrop={false}
      onOpenChange={onOpenChange}
      open={!!keywordHashes.length}
      useFullHeight
      width={selectedReason ? 'large' : 'regular'}
      title="Findings"
      hiddenTitle
    >
      <ReasonsContainer>
        <FlexContainer
          css={{
            maxWidth: 'min(calc(30vw - 32px), 486px)',
            minWidth: 'min(calc(30vw - 32px), 486px)'
          }}
          direction="column"
          gap="nano"
        >
          <FlexContainer css={{ padding: '$sm', pb: '$nano' }} gap="nano">
            <Lightning weight="fill" />
            <FlexContainer css={{ flex: 1 }} direction="column" gap="nano">
              {isLoading ? (
                <Text typeface="subtitleRegularXXS">{t('loadingFindings')}</Text>
              ) : (
                renderHeader()
              )}
            </FlexContainer>
          </FlexContainer>

          <ReasonsScrollArea>
            <ScrollAreaViewport>
              <FlexContainer direction="column" gap="xxxs">
                {(showAll ? reasons : filteredReasons).map((reason, index) => (
                  <ReasonCard
                    key={reason.id}
                    {...reason}
                    css={cardCSS}
                    isSelected={selectedReason?.id === reason.id}
                    onClick={() => onReasonClick(reason, index)}
                  />
                ))}
              </FlexContainer>
            </ScrollAreaViewport>
            <ScrollAreaScrollbar orientation="vertical">
              <ScrollAreaThumb />
            </ScrollAreaScrollbar>
          </ReasonsScrollArea>
          {isShowAllButtonVisible && (
            <Button css={{ pl: '$sm', pb: '$sm' }} onClick={onShowAllClick} variant="link">
              {t('showAllFindings')}
            </Button>
          )}
        </FlexContainer>
        {selectedReason && (
          <ReasonDetails
            {...selectedReason}
            isLoadingChart={isLoadingChart}
            keywordHashes={keywordHashes}
            onOpenThread={setFeedbackThreadOpen}
            trendSeries={trendSeries}
          />
        )}
      </ReasonsContainer>
      <FeedbackDetails interactOutside store="reasons" />

      <Suspense>
        {feedbackThreadOpen && (
          <FeedbackThread
            feedback={feedbackThreadOpen}
            onOpenChange={() => setFeedbackThreadOpen(null)}
            ticketId={feedbackThreadOpen?.ticket?.id ?? ''}
          />
        )}
      </Suspense>
    </Dialog>
  )
}

export default ReasonsArea
