import FieldsService from '@/services/FieldsService'
import { useQuery } from '@tanstack/react-query'

const useFieldsQuery = () => {
  const queryFn = async () => {
    const [error, response] = await FieldsService.fields('feedback_fields')
    if (error) {
      throw error
    }
    return response
  }

  const query = useQuery({
    queryKey: ['feed-filters'],
    queryFn
  })

  return query
}

export default useFieldsQuery
