import FlexContainer from '@/components/atoms/flex-container'
import Text from '@/components/atoms/text'
import { AssociatedOpportunityCard } from './styles'
import { OpportunityItem } from '@/types/opportunity/Opportunity'
import { opportunityStatusLabel } from '@/utils/opportunityUtils'
import { MetricsRequests } from '@/types/metrics'
import MetricsService from '@/services/MetricsService'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import useBasicAreaOfInterestQuery from '@/hooks/areaOfInterest/useBasicAreaOfInterestQuery'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { shallow } from 'zustand/shallow'
import { endDateParam, startDateParam } from '@/utils/date'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import useSegment from '@/hooks/useSegment'
import useNavitagateTo from '@/hooks/useNavigateTo'
import shortUUID from 'short-uuid'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { BaseInterestArea } from '@/types/area/AreaOfInterest'
import { useCurrentInterestAreaStore } from '@/store/useAreaOfInterestStore'
import AreaService from '@/services/AreaService'
import { mapArea } from '@/utils/areaOfInterest'
import useLogging from '@/hooks/useLogging'
import { mergeAreasToAdvancedFilterContent } from '@/utils/advancedFilter'

const AssociatedOpportunity = (opportunity: OpportunityItem) => {
  const { logException } = useLogging({ context: 'associated-opportunity' })
  const { t } = useTranslation()

  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  let startDate: string | undefined
  let endDate: string | undefined
  if (datePeriod !== 'allTime' && dateRange) {
    startDate = startDateParam(dateRange.start)
    endDate = endDateParam(dateRange.end)
  }

  const { areas: areasData } = useBasicAreaOfInterestQuery()
  const { getFilterContext } = useAdvancedFilters()

  const queryFn = async () => {
    let areas = areasData
    if (areas.length === 0) {
      const [error, response] = await AreaService.searchAreas({
        limit: 300,
        page: 1,
        transform: true
      })

      if (error) {
        logException(error, { message: 'Failed to fetch areas' })
        return
      }

      areas = response.areas.map(mapArea)
    }

    areas = areas.filter(area => opportunity.relations?.includes(area.id))

    // const filterNode: FilterNode
    const areasContent = mergeAreasToAdvancedFilterContent(areas)
    const context = await getFilterContext(areasContent)

    const filterList: FeedbackListQueryParams[] = [
      {
        context,
        opportunity_id: opportunity.id
      }
    ]

    const metricsPayload: MetricsRequests.MetricsPayload = {
      filter_list: filterList,
      metric_list: [
        {
          name: 'feedback_count',
          label: 'feedback_count',
          include_previous_value: false
        }
      ],
      posted_at_gte: startDate,
      posted_at_lt: endDate
    }

    const [error, response] = await MetricsService.opportunitiesMetrics(metricsPayload)

    if (error) throw error
    return response?.[0][0] ?? []
  }

  const queryKey = useMemo(() => ['initiative-opportunity', opportunity.id], [opportunity.id])

  const { data } = useQuery({
    queryKey,
    queryFn,
    refetchOnMount: false
  })

  const { areas } = useBasicAreaOfInterestQuery({
    opportunityId: opportunity.id,
    enabled: true
  })

  const firstArea = areas.find(area => area.id === opportunity.relations[0])

  const areaName = useMemo(() => {
    const nAreas = opportunity.relations.length
    if (nAreas === 0) return ''
    if (nAreas === 1) {
      const area = areas.find(area => area.id === opportunity.relations[0])
      return area?.name
    }
    return `${t('multipleAreas')} (${nAreas})`
  }, [opportunity.relations, t, areas])

  const { track } = useSegment()
  const { navigateTo: navigate } = useNavitagateTo()
  const { applyOpportunityFilters } = useAdvancedFilters()
  const translator = useMemo(() => shortUUID(), [])
  const setCurrentArea = useCurrentInterestAreaStore(state => state.setCurrentInterestArea)

  const onOpportunityClick = useCallback(
    (opportunity: OpportunityItem, area?: BaseInterestArea) => {
      track('opportunity_access', { opportunity_name: opportunity.name, from: 'initiative' })
      if (area) {
        setCurrentArea(area)
      }

      applyOpportunityFilters({ opportunity, clearAllFilters: true })

      const shortOpportunityId = translator.fromUUID(opportunity.id)
      navigate(`/opportunity/${shortOpportunityId}`)
    },
    [track, setCurrentArea, applyOpportunityFilters, translator, navigate]
  )

  const onClick = useCallback(() => {
    onOpportunityClick(opportunity, firstArea)
  }, [opportunity, firstArea, onOpportunityClick])

  return (
    <>
      <AssociatedOpportunityCard onClick={onClick}>
        <FlexContainer gap="sm" alignItems="center" justifyContent="spaceBetween">
          <Text fontSize="micro" fontWeight="regular">
            {areaName}
          </Text>
          <FlexContainer gap="micro" alignItems="center">
            <Text fontSize="micro" fontWeight="regular">
              Status
            </Text>
            <Text fontSize="md">·</Text>
            <Text fontSize="micro" fontWeight="regular">
              {opportunityStatusLabel[opportunity.status]}
            </Text>
          </FlexContainer>
        </FlexContainer>
        <Text fontSize="xxxs" color="brandPrimaryPure" fontWeight="light">
          {opportunity.name}
        </Text>
        <FlexContainer gap="nano" direction="column">
          <Text lineHeight="sm" fontSize="micro" fontWeight="regular">
            {t('overallCount')}
          </Text>
          <Text lineHeight="sm" fontSize="xxxs" fontWeight="regular" color="brandPrimaryPure">
            {data?.current_value}
          </Text>
        </FlexContainer>
      </AssociatedOpportunityCard>
    </>
  )
}

export default AssociatedOpportunity
