import {
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaViewport
} from '@/components/molecules/scroll-area'
import Checkbox, { CheckboxState } from '../checkbox'
import FlexContainer from '../flex-container'
import CheckboxSelectPrimitive from './CheckboxSelectPrimitive'
import { SelectOption } from './types'

export interface CheckboxSelectProps {
  options: SelectOption[]
  checked: string[]
  onChange: (value: string[]) => void
  disabled?: boolean
  showCount?: boolean
  label: string
}

const CheckboxSelect = ({
  options,
  checked,
  onChange,
  label,
  disabled = false,
  showCount = true
}: CheckboxSelectProps) => {
  const onCheckedChange = (isChecked: CheckboxState, value: string) => {
    if (isChecked) {
      onChange([...checked, value])
      return
    }

    onChange(checked.filter(item => item !== value))
  }

  const isChecked = (value: string) => checked.includes(value)

  return (
    <CheckboxSelectPrimitive.Root>
      <CheckboxSelectPrimitive.Trigger asChild disabled={disabled}>
        <>
          <span>{label}</span>
          {showCount && checked.length > 0 && (
            <CheckboxSelectPrimitive.Count>{checked.length}</CheckboxSelectPrimitive.Count>
          )}
        </>
      </CheckboxSelectPrimitive.Trigger>
      <CheckboxSelectPrimitive.Content>
        <CheckboxSelectPrimitive.ScrollAreaRoot>
          <ScrollAreaViewport css={{ maxHeight: 400 }}>
            <FlexContainer direction="column" gap="micro" css={{ padding: '$xxs' }}>
              {options.map(option => (
                <Checkbox
                  checked={isChecked(option.value)}
                  key={option.value}
                  onCheckedChange={value => onCheckedChange(value, option.value)}
                  value={option.value}
                  text={option.text}
                  icon={option.icon}
                  disabled={option.disabled}
                  customContent={option.customContent}
                />
              ))}
            </FlexContainer>
          </ScrollAreaViewport>
          <ScrollAreaScrollbar orientation="vertical">
            <ScrollAreaThumb />
          </ScrollAreaScrollbar>
        </CheckboxSelectPrimitive.ScrollAreaRoot>
      </CheckboxSelectPrimitive.Content>
    </CheckboxSelectPrimitive.Root>
  )
}

export default CheckboxSelect
