import FlexContainer from '@/components/atoms/flex-container'
import { LinkFromTo } from '@/components/atoms/links'
import { styled } from '@/theme'
import { InitiativeItem } from '@/types/initiatives/Initiatives'
import { useMemo } from 'react'
import shortUUID from 'short-uuid'

const NameContainer = styled(FlexContainer, {
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  width: '100%',
  position: 'relative',
  color: '$neutralLowPure',
  fontSize: '$micro'
})

interface Props {
  data: InitiativeItem
  onClick: (initiative: InitiativeItem) => void
}

const InitiativeNameCell = ({ data, onClick }: Props) => {
  const translator = useMemo(() => shortUUID(), [])

  const shortInitiativeId = useMemo(() => translator.fromUUID(data.id ?? ''), [data.id, translator])

  const toLink = useMemo(() => `/initiatives/${shortInitiativeId}`, [shortInitiativeId])

  return (
    <NameContainer>
      <LinkFromTo onClick={() => onClick(data)} to={toLink}>
        {data.name}
      </LinkFromTo>
    </NameContainer>
  )
}

export default InitiativeNameCell
