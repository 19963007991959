import { ThumbsUp } from '@phosphor-icons/react'
import { sourceToMetrics } from './metrics'
import {
  integerFormatter,
  npsPercentageFormater,
  percentageFormatter,
  percentageScoreTransformer,
  scoreFormatter
} from './formatters'
import type { MetricItem, NPSMetricKey } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'

export const NPS_METRICS_KEYS: NPSMetricKey[] = [
  'nps',
  'nps_count',
  'nps_share',
  'nps_impact',
  'nps_detractors_count',
  'nps_promoters_count',
  'nps_neutrals_count',
  'nps_detractors_share',
  'nps_promoters_share',
  'nps_neutrals_share',
  'nps_detractors_share_responses',
  'nps_promoters_share_responses',
  'nps_neutrals_share_responses',
  'nps_promoters_over_responses_with_comments',
  'nps_neutrals_over_responses_with_comments',
  'nps_detractors_over_responses_with_comments',
  'nps_detractors_over_area_responses',
  'nps_detractors_over_area_responses_with_comments',
  'nps_impact_over_collection'
]

/** METRICS USED ONLY IN TIMESERIES */
export const NPS_TIMESERIES_METRICS_KEYS: NPSMetricKey[] = [
  'nps',
  'nps_count',
  'nps_share',
  'nps_detractors_count',
  'nps_promoters_count',
  'nps_neutrals_count',
  'nps_detractors_share',
  'nps_promoters_share',
  'nps_neutrals_share',
  'nps_detractors_share_responses',
  'nps_promoters_share_responses',
  'nps_neutrals_share_responses',
  'nps_promoters_over_responses_with_comments',
  'nps_neutrals_over_responses_with_comments',
  'nps_detractors_over_responses_with_comments',
  'nps_detractors_over_area_responses',
  'nps_detractors_over_area_responses_with_comments'
]

// not supported by the endpoints yet
// const npsDetractorsFilter = { 'nps.rating': [0, 1, 2, 3, 4, 5, 6] }
// const npsNeutralsFilter = { 'nps.rating': [7, 8] }
// const npsPromotersFilter = { 'nps.rating': [9, 10] }

const npsDetractorsFilter = { 'nps.rating.lte': '6' }
const npsNeutralsFilter = { 'nps.rating.gte': '7', 'nps.rating.lte': '8' }
const npsPromotersFilter = { 'nps.rating.gte': '9' }

export const npsTimeSeries: Record<NPSMetricKey, MetricItem> = {
  nps: {
    label: i18n.t('score'),
    selectedLabel: i18n.t('npsScore'),
    icon: ThumbsUp,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: sourceToMetrics.nps.name,
      label: sourceToMetrics.nps.label
    }
  },
  nps_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('npsPercentCount'),
    icon: ThumbsUp,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'Feedback share of NPS',
      filter: { kind: 'nps' }
    }
  },
  nps_count: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('npsCount'),
    icon: ThumbsUp,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'Feedback count of NPS',
      filter: { kind: 'nps' }
    }
  },
  nps_detractors_share: {
    label: i18n.t('percentDetOverDetractors'),
    selectedLabel: i18n.t('npsPercentDetOverDetractors'),
    abbrLabel: i18n.t('percentDetrOverPercentDetr'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'Feedback share of NPS Detractors',
      filter: { kind: 'nps', ...npsDetractorsFilter }
    }
  },
  nps_promoters_share: {
    label: i18n.t('percentProOverPromoters'),
    selectedLabel: i18n.t('npsPercentProOverPromoters'),
    abbrLabel: i18n.t('percentPromOverProm'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'Feedback share of NPS Promoters',
      filter: { kind: 'nps', ...npsPromotersFilter }
    }
  },
  nps_neutrals_share: {
    label: i18n.t('percentNeuOverNeutrals'),
    selectedLabel: i18n.t('npsPercentNeuOverNeutrals'),
    abbrLabel: i18n.t('percentNeuOverNeu'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'Feedback share of NPS Neutrals',
      filter: { kind: 'nps', ...npsNeutralsFilter }
    }
  },
  nps_detractors_share_responses: {
    label: i18n.t('percentDetOverAllResponses'),
    selectedLabel: i18n.t('npsPercentDetOverAllResponses'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'Feedback share of NPS Det. / All responses',
      filter: { kind: 'nps', ...npsDetractorsFilter },
      share_filter: { kind: 'nps' }
    }
  },
  nps_promoters_share_responses: {
    label: i18n.t('percentProOverAllResponses'),
    selectedLabel: i18n.t('npsPercentProOverAllResponses'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'Feedback share of NPS Pro. / All responses',
      filter: { kind: 'nps', ...npsPromotersFilter },
      share_filter: { kind: 'nps' }
    }
  },
  nps_neutrals_share_responses: {
    label: i18n.t('percentNeuOverAllResponses'),
    selectedLabel: i18n.t('npsPercentNeuOverAllResponses'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'Feedback share of NPS Neu. / All responses',
      filter: { kind: 'nps', ...npsNeutralsFilter },
      share_filter: { kind: 'nps' }
    }
  },
  nps_detractors_count: {
    label: i18n.t('detractorsCount'),
    selectedLabel: i18n.t('npsDetractorsCount'),
    icon: ThumbsUp,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'Feedback count of NPS Detractors',
      filter: { kind: 'nps', ...npsDetractorsFilter }
    }
  },
  nps_promoters_count: {
    label: i18n.t('promotersCount'),
    selectedLabel: i18n.t('npsPromotersCount'),
    icon: ThumbsUp,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'Feedback count of NPS Promoters',
      filter: { kind: 'nps', ...npsPromotersFilter }
    }
  },
  nps_neutrals_count: {
    label: i18n.t('neutralsCount'),
    selectedLabel: i18n.t('npsNeutralsCount'),
    icon: ThumbsUp,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'Feedback count of NPS Neutrals',
      filter: { kind: 'nps', ...npsNeutralsFilter }
    }
  },
  nps_impact: {
    label: i18n.t('potentialImprovement'),
    selectedLabel: i18n.t('npsPotentialImprovement'),
    icon: ThumbsUp,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'nps_impact',
      label: 'nps_impact'
    }
  },
  nps_promoters_over_responses_with_comments: {
    label: i18n.t('promotersOverComments'),
    selectedLabel: i18n.t('npsPromotersOverComments'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'nps_promoters_over_responses_with_comments',
      filter: { kind: 'nps', ...npsPromotersFilter, has_signal: 'true' },
      share_filter: { kind: 'nps', has_signal: 'true' }
    }
  },
  nps_neutrals_over_responses_with_comments: {
    label: i18n.t('neutralsOverComments'),
    selectedLabel: i18n.t('npsNeutralsOverComments'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'nps_neutrals_over_responses_with_comments',
      filter: { kind: 'nps', ...npsNeutralsFilter, has_signal: 'true' },
      share_filter: { kind: 'nps', has_signal: 'true' }
    }
  },
  nps_detractors_over_responses_with_comments: {
    label: i18n.t('detractorsOverComments'),
    selectedLabel: i18n.t('npsDetractorsOverComments'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'nps_detractors_over_responses_with_comments',
      filter: { kind: 'nps', ...npsDetractorsFilter, has_signal: 'true' },
      share_filter: { kind: 'nps', has_signal: 'true' }
    }
  },
  nps_detractors_over_area_responses: {
    label: i18n.t('detractorsOverArea'),
    selectedLabel: i18n.t('npsDetractorsOverArea'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'Feedback share of NPS Det. / All area responses',
      filter: { kind: 'nps', ...npsDetractorsFilter },
      share_filter: { kind: 'nps' }
    }
  },
  nps_detractors_over_area_responses_with_comments: {
    label: i18n.t('detractorsOverAreaComments'),
    selectedLabel: i18n.t('npsDetractorsOverAreaComments'),
    icon: ThumbsUp,
    formatter: npsPercentageFormater,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'nps_detractors_over_area_responses_with_comments',
      filter: { kind: 'nps', ...npsDetractorsFilter, has_signal: 'true' },
      share_filter: { kind: 'nps', has_signal: 'true' }
    }
  },
  nps_impact_over_collection: {
    label: i18n.t('potentialImprovementOverCollection'),
    selectedLabel: i18n.t('npsPotentialImprovementOverCollection'),
    icon: ThumbsUp,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'nps_impact',
      label: 'nps_impact_over_collection'
    }
  }
}
