import { InitiativeStatus } from '@/types/initiatives/Initiatives'
import { createWithEqualityFn } from 'zustand/traditional'

interface InitiativesFiltersState {
  search: string
  setSearch: (search: string) => void

  owner: string[]
  setOwner: (owner: string[]) => void

  assignee: string[]
  setAssignee: (assignee: string[]) => void

  status: InitiativeStatus[]
  setInitiativeStatus: (status: InitiativeStatus[]) => void
}

const useInitiativesFiltersStore = createWithEqualityFn<InitiativesFiltersState>()(set => ({
  search: '',
  setSearch: search => set(() => ({ search })),

  owner: [],
  setOwner: owner => set(() => ({ owner })),

  assignee: [],
  setAssignee: assignee => set(() => ({ assignee })),

  status: [],
  setInitiativeStatus: status => set(() => ({ status }))
}))

export default useInitiativesFiltersStore
