import { FieldsRequests } from '@/types/fields'
import RequestHandler from './RequestHandlers/NewRequest'

export default class FieldsService {
  static PREFIX = '/fields'
  static async fields(
    type: 'feedback_fields' | 'audience_account' | 'audience_user',
    options?: {
      signal?: AbortSignal
    }
  ) {
    return await RequestHandler.get<FieldsRequests.FieldsResponse>(
      `${this.PREFIX}/${type}`,
      undefined,
      {
        signal: options?.signal
      }
    )
  }
}
