import OptionsMenu, { OptionsMenuItem } from '@/components/atoms/options-menu'
import useAccountsFilters from '@/hooks/filters/useAccountsFilters'
import useCustomerUsersFilters from '@/hooks/filters/useCustomerUsersFilters'
import useFeedFilters from '@/hooks/filters/useFeedFilters'
import useUser from '@/hooks/useUser'
import useAccountsStore from '@/store/useAccountsStore'
import useCustomerUsersStore from '@/store/useCustomerUsersStore'
import { FilterOption, FilterType, GenericFilter } from '@/types/filters/AdvancedFilters'
import { FilterItem } from '@/types/filters/Filters'
import {
  Buildings,
  CaretDown,
  ClipboardText,
  HandPalm,
  MagnifyingGlass,
  Smiley,
  Stack,
  UserList,
  UsersThree
} from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import TextFilterRow from './TextFilterRow'
import Text from '@/components/atoms/text'
import { FilterComponentProps } from './types'
import IntentionFilterRow from './IntentionFilterRow'
import SentimentFilterRow from './SentimentFilterRow'
import SourceFilterRow from './SourceFilterRow'
import GenericFilterRow from './GenericFilterRow'
import { DropdownButton } from './styles'
import i18n from '../../../../../plugins/i18n/i18n'
import { prefixFilterName } from '@/utils/filters'
import { sentimentFilterNames } from '@/utils/sentiments'

interface FilterMapItem {
  label: string
  icon: ReactNode
  component: (props: FilterComponentProps) => JSX.Element
  filterItem?: GenericFilter
}

type FilterKey =
  | 'search'
  | 'intention'
  | 'sentiment'
  | 'kind'
  | 'feedbackDetail'
  | 'customField'
  | 'account'
  | 'user'

const filterMap: Record<FilterKey, FilterMapItem> = {
  search: {
    label: i18n.t('search'),
    icon: <MagnifyingGlass />,
    component: TextFilterRow,
    filterItem: {
      name: 'text.feedback',
      type: 'text',
      status: 'valid',
      value: '',
      path: 'text',
      option: 'contains'
    }
  },
  intention: {
    label: i18n.t('feedbackIntention'),
    icon: <HandPalm />,
    component: IntentionFilterRow,
    filterItem: {
      name: 'intention',
      type: 'enum',
      status: 'valid',
      value: [],
      path: 'intention',
      option: 'matches'
    }
  },
  sentiment: {
    label: i18n.t('sentiment'),
    icon: <Smiley />,
    component: SentimentFilterRow,
    filterItem: {
      name: 'negative_ratio',
      type: 'number',
      status: 'valid',
      value: { $eq: 0 },
      path: 'negative_ratio'
    }
  },
  kind: {
    label: i18n.t('source'),
    icon: <Stack />,
    component: SourceFilterRow,
    filterItem: {
      name: 'kind',
      type: 'enum',
      status: 'valid',
      value: [],
      path: 'kind',
      option: 'matches'
    }
  },
  feedbackDetail: {
    label: i18n.t('feedbackDetails'),
    icon: <ClipboardText />,
    component: GenericFilterRow
  },
  customField: { label: i18n.t('customFields'), icon: <UserList />, component: GenericFilterRow },
  account: { label: i18n.t('accounts'), icon: <Buildings />, component: GenericFilterRow },
  user: { label: i18n.t('users'), icon: <UsersThree />, component: GenericFilterRow }
}

const getFilterKey = (filter: GenericFilter): FilterKey => {
  if (filter.name === 'text.feedback') return 'search'
  if (filter.name === 'intention') return 'intention'
  if (sentimentFilterNames.includes(filter.name)) return 'sentiment'
  if (filter.name === 'kind') return 'kind'

  if (filter.name.startsWith('account.')) return 'account'
  if (filter.name.startsWith('user.')) return 'user'
  if (filter.name.startsWith('custom_field')) return 'customField'

  return 'feedbackDetail'
}

const filterKeysWithoutInnerField = ['search', 'intention', 'sentiment', 'kind']
const getFilterFieldName = (filter: GenericFilter) => {
  if (filterKeysWithoutInnerField.includes(getFilterKey(filter))) return null
  const name = filter.name.replace('account.', '').replace('user.', '')
  return name
}

const AdvancedFilterRow = ({ filter, onFilterChange }: FilterComponentProps) => {
  const [selectedFilter, setSelectedFilter] = useState<FilterKey>(getFilterKey(filter))
  const [selectedFieldName, setSelectedFieldName] = useState<string | null>(
    getFilterFieldName(filter)
  )

  const { customFieldsFilters, feedbackDetailsFilters } = useFeedFilters()

  const { userPermissions, currentUser } = useUser()

  const checkHasAccounts = useAccountsStore(state => state.checkHasAccounts)
  const checkHasCustomerUsers = useCustomerUsersStore(state => state.checkHasCustomerUsers)

  const hasCustomerRecordsPermission = userPermissions.source.includes('customer_records')

  useQuery({
    queryKey: ['customer-records-check', { hasCustomerRecordsPermission }],
    queryFn: async () => {
      if (!hasCustomerRecordsPermission) return

      return Promise.all([
        checkHasAccounts(currentUser?.organization_id),
        checkHasCustomerUsers(currentUser?.organization_id)
      ])
    },
    retry: false,
    refetchOnMount: true,
    enabled: hasCustomerRecordsPermission
  })

  const generateFilter = useCallback(
    (filterItem: FilterItem): GenericFilter => {
      const type = (filterItem.type ?? 'enum') as FilterType
      let value: GenericFilter['value'] = []
      let option: FilterOption = 'matches'
      if (type.includes('date')) {
        value = 'new'
      } else if (type.includes('number') || type.includes('money')) {
        value = 'new'
        option = 'range'
      } else if (type.includes('boolean')) {
        value = false
      } else if (type === 'text') {
        value = ''
        option = 'contains'
      } else if (type === 'unique') {
        value = { $eq: '' }
        option = 'matches'
      }

      let prefix = ''
      if (type.includes('accounts')) {
        prefix = 'account.'
      } else if (type.includes('users')) {
        prefix = 'user.'
      }

      return {
        name: prefixFilterName(prefix, filterItem.name),
        path: filterItem.path ?? selectedFilter,
        type,
        status: 'valid',
        value,
        option
      }
    },
    [selectedFilter]
  )

  const generateOptions = useCallback(
    (filterName: FilterKey, fieldList: FilterItem[]) => {
      return fieldList.map(
        (filter): OptionsMenuItem => ({
          text: filter.displayName,
          bold: selectedFieldName === filter.name && selectedFilter === filterName,
          onClick: () => {
            setSelectedFilter(filterName)
            setSelectedFieldName(filter.name)
            onFilterChange(generateFilter(filter))
          }
        })
      )
    },
    [onFilterChange, selectedFilter, selectedFieldName, generateFilter]
  )

  const feedbackDetailOptions = useMemo(
    () => generateOptions('feedbackDetail', feedbackDetailsFilters),
    [feedbackDetailsFilters, generateOptions]
  )

  const customFieldOptions = useMemo(
    () => generateOptions('customField', customFieldsFilters),
    [customFieldsFilters, generateOptions]
  )

  const { fields: accountFields } = useAccountsFilters()
  const accountOptions = useMemo(
    () => generateOptions('account', accountFields),
    [accountFields, generateOptions]
  )

  const { fields: userFields } = useCustomerUsersFilters()
  const userOptions = useMemo(
    () => generateOptions('user', userFields),
    [userFields, generateOptions]
  )

  const filterOptions: OptionsMenuItem[] = useMemo(() => {
    const basicFilters: FilterKey[] = ['search', 'intention', 'sentiment', 'kind']
    const items: OptionsMenuItem[] = basicFilters.map(filter => ({
      text: filterMap[filter].label,
      icon: filterMap[filter].icon,
      bold: selectedFilter === filter,
      onClick: () => {
        setSelectedFilter(filter)
        onFilterChange(filterMap[filter].filterItem as GenericFilter)
      }
    }))

    if (feedbackDetailOptions.length) {
      items.push({
        text: filterMap.feedbackDetail.label,
        icon: filterMap.feedbackDetail.icon,
        options: feedbackDetailOptions,
        bold: selectedFilter === 'feedbackDetail'
      })
    }

    if (customFieldOptions.length) {
      items.push({
        text: filterMap.customField.label,
        icon: filterMap.customField.icon,
        options: customFieldOptions,
        bold: selectedFilter === 'customField'
      })
    }

    if (accountOptions.length) {
      items.push({
        text: filterMap.account.label,
        icon: filterMap.account.icon,
        options: accountOptions,
        bold: selectedFilter === 'account'
      })
    }

    if (userOptions.length) {
      items.push({
        text: filterMap.user.label,
        icon: filterMap.user.icon,
        options: userOptions,
        bold: selectedFilter === 'user'
      })
    }

    return items
  }, [
    feedbackDetailOptions,
    customFieldOptions,
    accountOptions,
    userOptions,
    selectedFilter,
    onFilterChange
  ])

  const Component = filterMap[selectedFilter].component

  const multiFieldsFilters: FilterKey[] = ['feedbackDetail', 'customField', 'account', 'user']
  const fieldDisplayName = useMemo(() => {
    if (selectedFieldName === null) return ''

    let fieldArray = feedbackDetailsFilters
    if (selectedFilter === 'customField') fieldArray = customFieldsFilters
    if (selectedFilter === 'account') fieldArray = accountFields
    if (selectedFilter === 'user') fieldArray = userFields

    return (
      fieldArray.find(field => field.name === selectedFieldName)?.displayName ?? selectedFieldName
    )
  }, [
    selectedFieldName,
    selectedFilter,
    feedbackDetailsFilters,
    customFieldsFilters,
    accountFields,
    userFields
  ])

  const selectedFilterText = useMemo(
    () =>
      multiFieldsFilters.includes(selectedFilter)
        ? fieldDisplayName
        : filterMap[selectedFilter].label,
    [selectedFilter, fieldDisplayName]
  )
  return (
    <>
      <OptionsMenu
        contentProps={{ style: { minWidth: 272 } }}
        customTrigger={
          <DropdownButton variant="white-bordered">
            {filterMap[selectedFilter].icon}
            <Text as="span" className="text" fontSize="xxxs" fontWeight="regular">
              {selectedFilterText}
            </Text>
            <CaretDown className="caret" size={12} />
          </DropdownButton>
        }
        options={filterOptions}
      />
      <Component filter={filter} onFilterChange={onFilterChange} />
    </>
  )
}

export default AdvancedFilterRow
