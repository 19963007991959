import { ChatText } from '@phosphor-icons/react'
import i18n from '../../plugins/i18n/i18n'

import {
  integerFormatter,
  percentageFormatter,
  percentageScoreTransformer,
  scoreFormatter
} from './formatters'
import { MetricItem, OverallMetricKeys } from '@/types/metrics'

export const OVERALL_METRIC_KEYS: OverallMetricKeys[] = [
  'count',
  'feedback_share',
  'share_over_area',
  'impact_score',
  'net_sentiment_score'
]

export const overallTimeseries: Record<OverallMetricKeys, MetricItem> = {
  count: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('overallCount'),
    icon: ChatText,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: 'feedback_count'
    }
  },
  feedback_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('overallPercentCount'),
    icon: ChatText,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'feedback_share',
      filter: {}
    }
  },
  net_sentiment_score: {
    label: i18n.t('sentimentScore'),
    selectedLabel: i18n.t('overallSentimentScore'),
    icon: ChatText,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'net_sentiment_score',
      label: 'Net sentiment score'
    }
  },
  feedback_share_group: {
    label: i18n.t('percentOverFilter'),
    selectedLabel: i18n.t('overallPercentOverFilter'),
    icon: ChatText,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'feedback_share_grouped',
      filter: {}
    }
  },
  impact_score: {
    label: i18n.t('impactScore'),
    selectedLabel: i18n.t('overallImpactScore'),
    icon: ChatText,
    formatter: scoreFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'impact_score',
      label: 'impact_score',
      filter: { kind: ['support_ticket', 'nps'] }
    }
  },
  share_over_area: {
    label: i18n.t('percentCountOverArea'),
    selectedLabel: i18n.t('overallPercentCountOverArea'),
    icon: ChatText,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'feedback_share_over_area',
      filter: {}
    }
  },
  feedback_share_over_collection: {
    label: i18n.t('overallPercentCountOverCollection'),
    selectedLabel: i18n.t('overallPercentCountOverCollection'),
    icon: ChatText,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: 'feedback_share_over_collection',
      filter: {}
    }
  }
}
