import useLetterCase from '@/hooks/useLetterCase'
import { FilterString } from '@/types/filters/Filters'
import {
  SimpleSentimentFilterContent,
  useSentimentSelection
} from '../../FiltersSidebar/SentimentFilter'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { getSentimentName } from '@/utils/sentiments'

interface AppliedSentimentFiltersEdit {
  filter: FilterString
  buttonDisabled?: boolean
}

function AppliedSentimentFiltersEdit(props: AppliedSentimentFiltersEdit) {
  const { capitalizeFirst } = useLetterCase()

  const { isChecked, selectOption, hasChanges, apply } = useSentimentSelection({ newFeed: true })

  function getTooltipText(filter: FilterString) {
    const selectedOptions = filter.selected
      .map(option => `<b>${capitalizeFirst(getSentimentName(Number(option)))}</b>`)
      .join(' or ')

    return `Is ${selectedOptions}`
  }

  return (
    <FilterItemDropdown
      buttonDisabled={props.buttonDisabled}
      content={props.filter.selected.length}
      contentType="count"
      disabled={!hasChanges}
      dropdown={<SimpleSentimentFilterContent isChecked={isChecked} selectOption={selectOption} />}
      onApply={apply}
      title="Sentiment"
      tooltip={getTooltipText(props.filter)}
    />
  )
}

export default AppliedSentimentFiltersEdit
