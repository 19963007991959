import { useMemo, useState } from 'react'
import FilterSubMenu from '../../AddFilterMenu/FilterSubMenu'
import { DateValue, parseDate } from '@internationalized/date'
import RangeCalendar from '@/components/atoms/range-calendar'
import { useFiltersStore } from '@/store'
import { FilterDatetimeValue, FilterItem } from '@/types/filters/Filters'
import useCustomerUsersDateFilter from './useCustomerUsersDateFilter'
import { useFeedFiltersStore } from '@/store/useFiltersStore'
import useAdvancedFilters from '@/hooks/advancedFilters/useAdvancedFilters'
import { startDateParam } from '@/utils/date'
import { DateFilterType } from '@/types/filters/AdvancedFilters'
import { DATE_FILTER_TYPES, prefixFilterName } from '@/utils/filters'

interface Props {
  filter: FilterItem
  newFeed: boolean
}

const CustomerUsersDateFilterSubmenu = ({ filter, newFeed = false }: Props) => {
  const useStore = newFeed ? useFeedFiltersStore : useFiltersStore

  const changeUsersDateFilter = useStore(state => state.changeUsersDateFilter)
  const applyUsers = useStore(state => state.applyUsers)
  const dateFilter = useStore(state =>
    state.usersDateFilters.find(userFilter => userFilter.key === filter.key)
  )

  const [open, setOpen] = useState(false)

  const { fieldRange, isLoading } = useCustomerUsersDateFilter({
    filterKey: filter.key,
    enabled: open
  })

  const onChange = (value: RangeValue<DateValue>) => {
    changeUsersDateFilter({ key: filter.key, value, apply: false })
  }

  const ranges = useMemo(() => {
    if (fieldRange) {
      const startDate = parseDate(fieldRange.startDate.slice(0, 10))
      const endDate = parseDate(fieldRange.endDate.slice(0, 10))
      return { start: startDate, end: endDate } as RangeValue<DateValue>
    }
    return null
  }, [fieldRange])

  const rangeDraftValue = useMemo(() => {
    return dateFilter?.draftSelected
  }, [dateFilter])

  const hasChanges = useMemo(
    () => JSON.stringify(dateFilter?.draftSelected) !== JSON.stringify(dateFilter?.selected),
    [dateFilter]
  )

  const { addFilter } = useAdvancedFilters()
  const [dateRange, setDateRange] = useState<FilterDatetimeValue>(null)

  const advancedApply = () => {
    if (!dateRange) return

    const isDateField = DATE_FILTER_TYPES.includes(filter.type as DateFilterType)
    const start = startDateParam(dateRange?.start, isDateField)
    const end = startDateParam(dateRange?.end, isDateField)

    addFilter({
      type: isDateField ? (filter.type as DateFilterType) : 'users-date',
      name: prefixFilterName('user.', filter.name),
      value: { $gte: start, $lt: end },
      path: filter.path ?? filter.name,
      status: 'valid'
    })
  }

  const isAdvanced = newFeed
  const apply = isAdvanced ? advancedApply : applyUsers
  const onRangeChange = isAdvanced ? setDateRange : onChange
  const rangeValue = isAdvanced ? dateRange : rangeDraftValue

  return (
    <FilterSubMenu
      contentCss={{ width: 'auto' }}
      isDisabled={!hasChanges}
      isLoading={isLoading}
      onApply={apply}
      onOpenChange={setOpen}
      open={open}
      previousTitle="Users"
      title={filter.displayName}
    >
      <RangeCalendar
        defaultValue={ranges}
        onChange={onRangeChange}
        value={rangeValue ?? undefined}
      />
    </FilterSubMenu>
  )
}

export default CustomerUsersDateFilterSubmenu
