import { DateValue } from '@react-aria/calendar'
import useDatetimeFiltersQuery from '../filters/useDatetimeFiltersQuery'
import { useMemo, useState } from 'react'
import { getLocalTimeZone, now, parseDate } from '@internationalized/date'
import { FilterDatetimeValue } from '@/types/filters/Filters'
import { endDateParam, startDateParam } from '@/utils/date'
import useAdvancedFilters from './useAdvancedFilters'
import { DateFilterType } from '@/types/filters/AdvancedFilters'
import { DATE_FILTER_TYPES, prefixFilterName } from '@/utils/filters'

interface Props {
  name: string
  path: string
  enableQuery?: boolean
  initialValue?: FilterDatetimeValue
  index?: number
  type: DateFilterType
}

const useDatetimeAdvancedFilter = ({
  name,
  path,
  enableQuery = true,
  initialValue = null,
  index,
  type
}: Props) => {
  const isCustomerRecord = !['datetime', 'date'].includes(type)
  const { data, isLoading } = useDatetimeFiltersQuery({
    name,
    enabled: enableQuery && !isCustomerRecord
  })

  const minDate: DateValue = useMemo(
    () => parseDate(data?.startDate.split('T')[0] ?? '2000-01-01'),
    [data]
  )
  const maxDate: DateValue = useMemo(
    () => (data ? parseDate(data?.endDate.split('T')[0]) : now(getLocalTimeZone())),
    [data]
  )

  const { addFilter, updateFilter } = useAdvancedFilters()
  const [dateRange, setDateRange] = useState<FilterDatetimeValue>(initialValue)

  let prefix = ''
  if (['accounts-date', 'accounts-datetime'].includes(type)) {
    prefix = 'account.'
  } else if (['users-date', 'users-datetime'].includes(type)) {
    prefix = 'user.'
  }

  const apply = () => {
    if (!dateRange) return

    const isDateField = DATE_FILTER_TYPES.includes(type)
    const start = startDateParam(dateRange.start, isDateField)
    const end = endDateParam(dateRange.end, isDateField)

    addFilter({
      name: prefixFilterName(prefix, name),
      type,
      value: { $gte: start, $lt: end },
      path,
      status: 'valid'
    })
  }

  const update = () => {
    if (index === undefined || !dateRange) return

    const start = startDateParam(dateRange.start)
    const end = endDateParam(dateRange.end)

    updateFilter({
      index,
      filter: {
        name: prefixFilterName(prefix, name),
        type,
        value: { $gte: start, $lt: end },
        path,
        status: 'valid'
      }
    })
  }

  return {
    minDate,
    maxDate,
    dateRange,
    setDateRange,
    apply,
    update,
    isLoading: !isCustomerRecord && isLoading
  }
}

export default useDatetimeAdvancedFilter
