import { SelectOption } from '@/components/atoms/select'
import { capitalizeFirst } from '@/utils/letterCase'
import { OpportunityItemWithMetrics, OpportunityStatus } from '@/types/opportunity/Opportunity'

const toSelectOption = (value: [string, string | undefined]): SelectOption => ({
  text: capitalizeFirst(value[1] ?? ''),
  value: value[0],
  disabled: [
    OpportunityStatus.Draft,
    OpportunityStatus.Processing,
    OpportunityStatus.Quantified
  ].includes(value[0] as OpportunityStatus)
})

export type StatusFilter = 'all' | 'ongoing' | 'dismissed'
export const statusFilterOptions: SelectOption<StatusFilter>[] = [
  { text: 'Ongoing', value: 'ongoing' },
  { text: 'Dismissed', value: 'dismissed' },
  { text: 'All', value: 'all' }
]

export const opportunityStatusLabel: Record<OpportunityStatus, string> = {
  [OpportunityStatus.Draft]: 'Draft',
  [OpportunityStatus.Processing]: 'Processing',
  [OpportunityStatus.Quantified]: 'Quantified',
  [OpportunityStatus.Refined]: 'Refined',
  [OpportunityStatus.Prioritized]: 'Prioritized',
  [OpportunityStatus.Tracking]: 'Tracking',
  [OpportunityStatus.Dismissed]: 'Dismissed'
}

export const OPPORTUNITY_STATUS_OPTIONS = Object.entries(opportunityStatusLabel).map(
  toSelectOption
) as SelectOption<OpportunityStatus>[]

export const ONGOING_STATUS = [
  OpportunityStatus.Processing,
  OpportunityStatus.Quantified,
  OpportunityStatus.Refined,
  OpportunityStatus.Prioritized,
  OpportunityStatus.Tracking
]

export const isOpportunityPrioritized = (opportunity: OpportunityItemWithMetrics) =>
  opportunity.status === OpportunityStatus.Quantified ||
  opportunity.status === OpportunityStatus.Refined ||
  opportunity.status === OpportunityStatus.Prioritized

export const sortByOpportunityStatus = (status: OpportunityStatus) => {
  if (
    [
      OpportunityStatus.Quantified,
      OpportunityStatus.Refined,
      OpportunityStatus.Prioritized
    ].includes(status)
  )
    return 1

  if ([OpportunityStatus.Tracking, OpportunityStatus.Processing].includes(status)) return 0

  return -1
}

export const INVALID_METRIC_TABLE_COLUMNS = ['', 'name', 'opportunityCount', 'status']

/** MetricsService.opportunitiesMetrics actually support only these metric names  */
export const OPPORTUNITY_METRICS_SUPPORTED_NAMES = ['feedback_count', 'feedback_share', 'custom']
