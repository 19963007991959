import {
  FilterDatetime,
  FilterNumeric,
  FilterString,
  SavedFilterContent,
  SavedFilterContentAdvanced
} from '@/types/filters/Filters'
import { capitalizeFirst } from './letterCase'
import { queryClient } from '@/plugins/reactQueryClient'
import { FiltersResponse } from '@/types/filters/FilterRequests'
import { TopicCategory } from '@/types/classification'
import { useClassificationStore } from '@/store'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import { parseDate } from '@internationalized/date'
import { getDateRangeFromNow } from './date'
import { PresetPeriod } from '@/types/periods'
import { intentionFeedQueryParam } from './intention'
import { Intention } from '@/types/reasons'
import { isAdvancedContent } from './advancedFilter'
import { DateFilterType } from '@/types/filters/AdvancedFilters'

export const FEEDBACK_TYPE_KEY = 'feedback_type'
export const NEW_FEEDBACK_TYPE_KEY = 'kind'
export const SENTIMENT_KEY = 'sentiment'

export const FEEDBACK_INTENTION_KEY = 'feedback_keyword_classes'
export const NEW_FEEDBACK_INTENTION_KEY = 'intention'
export const RATING_KEY = 'rating'
export const FEEDBACK_DATA_TYPE_KEY = 'data_type'

export const FEEDBACK_DETAILS_TYPES = [
  'string',
  'integer',
  'number',
  'schema_fields_string',
  'schema_fields_datetime',
  'schema_fields_number',
  'schema_fields_integer'
]

export const CUSTOM_FIELDS_TYPES = [
  'custom_fields_string',
  'custom_fields_datetime',
  'custom_fields_number',
  'custom_fields_integer'
]

export const INSENSITIVE_CASE_FILTERS = [FEEDBACK_INTENTION_KEY, FEEDBACK_TYPE_KEY, SENTIMENT_KEY]

export const FILTER_QUERY_PARAM = 'filter'
export const SAVED_FILTER_NAME_QUERY_PARAM = 'description'

const EXCLUDE_STRING_FILTERS = [FEEDBACK_DATA_TYPE_KEY, FEEDBACK_TYPE_KEY, SENTIMENT_KEY]

export const getFromStringFilters = (stringFilters: FilterString[]) => {
  const regularStringFilters: Record<string, string[]> = {}
  stringFilters
    .filter(filter => filter.type === 'string' && !EXCLUDE_STRING_FILTERS.includes(filter.key))
    .forEach(filter => {
      regularStringFilters[filter.key] =
        filter.key === FEEDBACK_INTENTION_KEY
          ? filter.selected.map(value => capitalizeFirst(value))
          : filter.selected
    })
  const schemaFieldsStringFilters = stringFilters
    .filter(filter => filter.type === 'schema_fields_string' && filter.selected.length > 0)
    .map(filter => ({ key: filter.key, values: filter.selected }))
  const customFieldsStringFilters = stringFilters
    .filter(filter => filter.type === 'custom_fields_string' && filter.selected.length > 0)
    .map(filter => ({ key: filter.key, values: filter.selected }))

  const sentiment =
    stringFilters
      .find(filter => filter.key === SENTIMENT_KEY)
      ?.selected.map(value => Number(value)) ?? []

  const feedbackTypes =
    stringFilters.find(filter => filter.key === FEEDBACK_TYPE_KEY)?.selected ?? []
  const filtersQueryData = queryClient.getQueryData(['filters']) as FiltersResponse | undefined
  const feedbackTypeData = filtersQueryData?.feedback_type ?? []
  const feedbackSourceIds = feedbackTypeData
    .filter(ft => feedbackTypes?.includes(ft.text.toLowerCase()))
    .flatMap(ft => ft.feedback_source_ids)

  const dataType =
    stringFilters.find(filter => filter.key === FEEDBACK_DATA_TYPE_KEY)?.selected ?? []

  return {
    regularStringFilters,
    schemaFieldsStringFilters,
    customFieldsStringFilters,
    sentiment,
    feedbackSourceIds,
    dataType
  }
}

const mapNumberFilter = (item: FilterNumeric) => ({
  key: item.key,
  min: item.value?.[0] ?? undefined,
  max: item.value?.[1] ?? undefined
})

export const getFromNumericFilters = (numericFilters: FilterNumeric[]) => {
  const regularNumericFilters: Record<string, { gte?: number; lte?: number } | null> = {}
  numericFilters
    .filter(filter => filter.type === 'number' || filter.type === 'integer')
    .forEach(filter => {
      if (filter.option === 'all') {
        regularNumericFilters[filter.key] = {}
      } else if (filter.option === 'is') {
        regularNumericFilters[filter.key] = filter.value
          ? { gte: filter.value[0], lte: filter.value[0] }
          : null
      } else {
        regularNumericFilters[filter.key] = filter.value
          ? { gte: filter.value[0], lte: filter.value[1] }
          : null
      }
    })

  const schemaFieldsNumberFilters = numericFilters
    .filter(filter => filter.type === 'schema_fields_number' && filter.option !== 'all')
    .map(mapNumberFilter)
  const schemaFieldsIntegerFilters = numericFilters
    .filter(filter => filter.type === 'schema_fields_integer' && filter.option !== 'all')
    .map(mapNumberFilter)
  const customFieldsNumberFilters = numericFilters
    .filter(filter => filter.type === 'custom_fields_number' && filter.option !== 'all')
    .map(mapNumberFilter)
  const customFieldsIntegerFilters = numericFilters
    .filter(filter => filter.type === 'custom_fields_integer' && filter.option !== 'all')
    .map(mapNumberFilter)

  return {
    regularNumericFilters,
    schemaFieldsNumberFilters,
    schemaFieldsIntegerFilters,
    customFieldsNumberFilters,
    customFieldsIntegerFilters
  }
}

const mapDatetimeFilter = (item: FilterDatetime) => ({
  key: item.key,
  min: item.value ? `${item.value.start}T00:00:00+00:00` : undefined,
  max: item.value ? `${item.value.end}T23:59:59+00:00` : undefined
})

export const getFromDatetimeFilters = (datetimeFilters: FilterDatetime[]) => {
  const schemaFieldsDatetimeFilters = datetimeFilters
    .filter(filter => filter.type === 'schema_fields_datetime')
    .map(mapDatetimeFilter)
  const customFieldsDatetimeFilters = datetimeFilters
    .filter(filter => filter.type === 'custom_fields_datetime')
    .map(mapDatetimeFilter)

  return {
    schemaFieldsDatetimeFilters,
    customFieldsDatetimeFilters
  }
}

interface TopicGroupsParams {
  unclassifiedTopics: TopicCategory[]
  selectedProductAreaThemes: string[]
  selectedProductAreaTopics: string[]
  selectedOtherTopicsThemes: string[]
  selectedOtherTopics: string[]
  selectedUngroupedTopics?: string[]
}

export const getTopicGroups = (params: TopicGroupsParams): string[][] => {
  const {
    unclassifiedTopics,
    selectedProductAreaThemes,
    selectedProductAreaTopics,
    selectedOtherTopics,
    selectedOtherTopicsThemes,
    selectedUngroupedTopics
  } = params

  const topicGroups: string[][] = []

  if (unclassifiedTopics.length > 0) {
    if (unclassifiedTopics.length === 2) {
      return [['none']]
    }
    if (unclassifiedTopics[0] === 'OTHER') {
      topicGroups.push(['none_others'])
    } else {
      topicGroups.push(['none_areas'])
    }
  }

  const unclassifiedTopicGroup = unclassifiedTopics[0] || undefined

  const productAreasTopics: string[] = []
  const otherTopics: string[] = []

  const { productAreaThemes, otherThemes } = useClassificationStore.getState()
  const fullThemes = [...productAreaThemes, ...otherThemes]

  selectedProductAreaThemes.forEach(themeId => {
    const theme = fullThemes.find(fullTheme => fullTheme.themeId === themeId)
    if (theme) {
      productAreasTopics.push(...theme.topics.map(topic => topic.topicId))
    }
  })
  productAreasTopics.push(...selectedProductAreaTopics)

  selectedOtherTopicsThemes.forEach(themeId => {
    const theme = fullThemes.find(fullTheme => fullTheme.themeId === themeId)
    if (theme) {
      otherTopics.push(...theme.topics.map(topic => topic.topicId))
    }
  })
  otherTopics.push(...selectedOtherTopics)

  // empty arrays causes errors on backend, also remove possible duplicates
  if (productAreasTopics.length && unclassifiedTopicGroup !== 'PRODUCT_AREA')
    topicGroups.push([...new Set(productAreasTopics)])
  if (otherTopics.length && unclassifiedTopicGroup !== 'OTHER')
    topicGroups.push([...new Set(otherTopics)])

  if (selectedUngroupedTopics?.length) {
    topicGroups.push([...new Set(selectedUngroupedTopics)])
  }

  return topicGroups
}

export const FEED_FILTERS_BLACKLIST = ['posted_at', 'sentiment', 'intention', 'kind', 'text']

const parseDateParam = (value: string, addDays = 0) =>
  new Date(parseDate(value.slice(0, 10)).add({ days: addDays }).toString()).toISOString()

export const sentimentToFeedQueryParam: Record<string, string> = {
  '-1': 'NEGATIVE',
  '0': 'NEUTRAL',
  '1': 'POSITIVE'
}

export const sentimentQueryParamToSentiment: Record<string, string> = {
  NEGATIVE: '-1',
  NEUTRAL: '0',
  POSITIVE: '1'
}

export const getParamsFromFilterContent = (
  content: SavedFilterContent[] | [SavedFilterContentAdvanced],
  returnAdvanced?: boolean
) => {
  const params: FeedbackListQueryParams & Record<string, ParamValue> = {}

  if (isAdvancedContent(content)) {
    const filter = content[0].values.filter
    return returnAdvanced ? filter : undefined
  }
  ;(content as SavedFilterContent[]).forEach(filter => {
    if (filter.type === null || filter.type === undefined) return

    // if (filter.context) {
    //   params.context = filter.context
    // }

    if (filter.type === 'advanced') return

    if (filter.type === 'date_range') {
      const period = filter.category ?? 'allTime'

      if (period === 'custom') {
        params['posted_at.gte'] = parseDateParam(filter.values[0])
        params['posted_at.lt'] = parseDateParam(filter.values[1], 1)
      } else if (period !== 'allTime') {
        const preDefinedPeriod = getDateRangeFromNow(period as PresetPeriod)
        params['posted_at.gte'] = parseDateParam(preDefinedPeriod.start.toString())
        params['posted_at.lt'] = parseDateParam(preDefinedPeriod.end.toString(), 1)
      }

      return
    }

    if (filter.type === 'search_feedback_text') {
      params['text.feedback'] = filter.values[0]
      return
    }

    if (filter.type === 'search_text') {
      params.text = filter.values[0]
      return
    }

    if (
      filter.type.includes('string') ||
      filter.type.includes('enum') ||
      filter.type.includes('unique')
    ) {
      if (filter.key === 'intention') {
        params.intention = filter.values.map(
          intention => intentionFeedQueryParam[intention as Intention]
        )

        return
      }

      if (filter.key === 'sentiment') {
        params.sentiment = filter.values.map(
          sentiment => sentimentToFeedQueryParam[sentiment as SimpleSentiment]
        )

        return
      }

      params[filter.name] = filter.values
      return
    }

    if (filter.type.includes('number') || filter.type.includes('integer')) {
      const option = filter.category ?? 'all'

      if (option === 'between') {
        params[filter.name + '.gte'] = filter.values[0]
        params[filter.name + '.lte'] = filter.values[1]
        return
      }

      if (option === 'is') {
        params[filter.name] = filter.values[0]
      }

      return
    }

    if (filter.type.includes('datetime') && filter.category !== 'allTime') {
      params[filter.name + '.gte'] = parseDateParam(filter.values[0])
      params[filter.name + '.lt'] = parseDateParam(filter.values[1], 1)
      return
    }

    if (filter.type.includes('boolean') || filter.type.includes('text')) {
      params[filter.name] = filter.values[0]
      return
    }

    if (filter.type.includes('customer-records-accounts')) {
      params['account.' + filter.name] = filter.values
      return
    }

    if (filter.type.includes('customer-records-users')) {
      params['user.' + filter.name] = filter.values
      return
    }

    if (filter.type.includes('account-numeric')) {
      const option = filter.category ?? 'all'

      if (option === 'between') {
        params['account.' + filter.name + '.gte'] = filter.values[0]
        params['account.' + filter.name + '.lte'] = filter.values[1]
        return
      }

      if (option === 'is') {
        params['account.' + filter.name] = filter.values[0]
      }

      return
    }

    if (filter.type.includes('users-numeric')) {
      const option = filter.category ?? 'all'

      if (option === 'between') {
        params['user.' + filter.name + '.gte'] = filter.values[0]
        params['user.' + filter.name + '.lte'] = filter.values[1]
        return
      }

      if (option === 'is') {
        params['user.' + filter.name] = filter.values[0]
      }

      return
    }

    if (filter.type.includes('accounts-boolean')) {
      params['account.' + filter.name] = filter.values[0]
      return
    }

    if (filter.type.includes('users-boolean')) {
      params['user.' + filter.name] = filter.values[0]
      return
    }

    if (filter.type.includes('account-date') && filter.category !== 'allTime') {
      params['account.' + filter.name + '.gte'] = parseDateParam(filter.values[0])
      params['account.' + filter.name + '.lt'] = parseDateParam(filter.values[1], 1)
      return
    }

    if (filter.type.includes('users-date') && filter.category !== 'allTime') {
      params['user.' + filter.name + '.gte'] = parseDateParam(filter.values[0])
      params['user.' + filter.name + '.lt'] = parseDateParam(filter.values[1], 1)
    }
  })

  return params
}

export const prefixFilterName = (prefix: string, filterName: string) => {
  return prefix + filterName.replace(prefix, '')
}

export const ALL_DATE_FILTER_TYPES: DateFilterType[] = [
  'date',
  'users-date',
  'accounts-date',
  'accounts-datetime',
  'users-datetime',
  'datetime'
]

export const DATE_FILTER_TYPES: DateFilterType[] = ['date', 'users-date', 'accounts-date']

export const ALL_ACCOUNTS_DATE_FILTER_TYPES: DateFilterType[] = [
  'date',
  'accounts-date',
  'accounts-datetime',
  'datetime'
]

export const ALL_USERS_DATE_FILTER_TYPES: DateFilterType[] = [
  'date',
  'users-date',
  'users-datetime',
  'datetime'
]
