import { MouseEvent, ReactNode, useEffect } from 'react'
import { X } from '@phosphor-icons/react'
import * as DialogPrimitive from '@radix-ui/react-dialog'
import * as Styled from './Dialog.styles'
import { CSS } from '@/theme'
import { VariantProps } from '@stitches/react'
import useHideIntercom from '@/hooks/useHideIntercom'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'

type DialogContentVariants = VariantProps<typeof Styled.DialogContent>
export interface DialogContentProps
  extends DialogPrimitive.DialogContentProps,
    DialogPrimitive.DialogPortalProps {
  css?: CSS
  noBackdrop?: boolean
}

function DialogContent({
  children,
  noBackdrop = false,
  ...props
}: DialogContentProps & DialogContentVariants) {
  return (
    <DialogPrimitive.Portal>
      <Styled.DialogOverlay noBackdrop={noBackdrop} />
      <Styled.DialogContent {...props}>{children}</Styled.DialogContent>
    </DialogPrimitive.Portal>
  )
}

export type DialogProps = DialogPrimitive.DialogProps &
  DialogContentVariants & {
    trigger?: ReactNode
    /** Use itle for accessibility. Or compose it with the title compoent instead  */
    title?: ReactNode
    /** Use this prop if you don't want to show the title */
    hiddenTitle?: boolean
    description?: ReactNode
    contentProps?: DialogContentProps
    closeIcon: boolean
    noBackdrop?: boolean
    /** modal must be false */
    enableInteractionsOutside?: boolean
  }

const Dialog = ({
  children,
  trigger,
  title,
  hiddenTitle,
  description,
  contentProps,
  closeIcon,
  align,
  useFullHeight,
  noBackdrop = false,
  enableInteractionsOutside,
  width,
  ...props
}: DialogProps) => {
  const handleIntercom = useHideIntercom()

  useEffect(() => {
    handleIntercom(props.open)

    return () => handleIntercom(false)
  }, [props.open, handleIntercom])

  const onInteractOutside = (event: unknown) => {
    if (enableInteractionsOutside) (event as MouseEvent).preventDefault()
  }

  return (
    <DialogPrimitive.Root {...props}>
      {trigger && <DialogPrimitive.Trigger asChild>{trigger}</DialogPrimitive.Trigger>}

      <DialogContent
        {...contentProps}
        align={align}
        noBackdrop={noBackdrop}
        onClick={e => e.stopPropagation()}
        onInteractOutside={onInteractOutside}
        useFullHeight={useFullHeight}
        width={width}
        aria-describedby={description ? 'description' : undefined}
      >
        <Styled.CloseButton align={align} visible={closeIcon}>
          <X size={16} />
        </Styled.CloseButton>

        {hiddenTitle ? (
          <VisuallyHidden>
            {/* <Styled.OldDialogTitle>{title}</Styled.OldDialogTitle> */}
            <Styled.DialogTitle>{title}</Styled.DialogTitle>
          </VisuallyHidden>
        ) : (
          // <Styled.OldDialogTitle>{title}</Styled.OldDialogTitle>
          <Styled.DialogTitle>{title}</Styled.DialogTitle>
        )}

        {description && <Styled.DialogDescription>{description}</Styled.DialogDescription>}

        {children}
      </DialogContent>
    </DialogPrimitive.Root>
  )
}

export default Dialog

/** Accessible component to compose the Dialog Title. Used in the Dialog component */
export const DialogTitleDefault = Styled.DialogTitle
