import { useMemo, useState } from 'react'
import { useCalendarState } from '@react-stately/calendar'
import { useLocale, useDateFormatter } from '@react-aria/i18n'
import { createCalendar, getLocalTimeZone } from '@internationalized/date'

import type { CalendarProps } from '@react-aria/calendar'
import type { DateValue } from '@react-types/calendar'

import Popover, { PopoverFooter } from '../popover'
import { PopoverClose } from '@radix-ui/react-popover'
import Button from '../button'
import { CalendarBlank, CaretDown } from '@phosphor-icons/react'
import Text from '../text'
import FlexContainer from '../flex-container'
import DatePickerCalendar from './DatePickerCalendar'
import { CSS } from '@/theme'
import { useTranslation } from 'react-i18next'

const popoverCss: CSS = {
  '@short': {
    transform: 'translateY(50%)'
  }
}

interface Props extends CalendarProps<DateValue> {
  triggerCss?: CSS
  placeholder?: string
}

function DatePicker({ value, onChange, ...props }: Props) {
  const [calendarOpen, setCalendarOpen] = useState(false)
  const { t } = useTranslation()

  const [dateInput, setDateInput] = useState<DateValue | undefined | null>(value)

  const { locale } = useLocale()
  const state = useCalendarState({
    ...props,
    value: dateInput,
    onChange: setDateInput,
    locale,
    createCalendar
  })

  const dateFormatter = useDateFormatter({
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    timeZone: state.timeZone
  })

  const placeholder = props.placeholder ?? t('selectADate')

  const displayDateRange = useMemo(() => {
    const start = state.value?.toDate(getLocalTimeZone())

    return start ? dateFormatter.format(start) : placeholder
  }, [state.value, dateFormatter, placeholder])

  const onSelect = () => {
    if (!state.value) return
    onChange?.(state.value)
  }

  const button = (
    <FlexContainer alignItems="center" css={{ gap: 8, width: '100%' }}>
      <CalendarBlank size={16} />
      <Text css={{ marginRight: 'auto' }} typeface="subtitleRegularXXXS">
        {displayDateRange}
      </Text>
      <CaretDown size={16} />
    </FlexContainer>
  )

  return (
    <Popover
      bordered
      buttonChildren={button}
      contentProps={{
        css: popoverCss
      }}
      iconButtonCss={{ px: 16, ...props.triggerCss }}
      onOpenChange={setCalendarOpen}
      open={calendarOpen}
      title={placeholder}
    >
      <DatePickerCalendar {...props} value={dateInput} onChange={setDateInput} />
      <PopoverFooter>
        <PopoverClose asChild>
          <Button text size="small">
            {t('cancel')}
          </Button>
        </PopoverClose>
        <PopoverClose asChild onClick={onSelect}>
          <Button size="small" disabled={!state.value}>
            {t('select')}
          </Button>
        </PopoverClose>
      </PopoverFooter>
    </Popover>
  )
}

export default DatePicker
