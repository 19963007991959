import * as Sentry from '@sentry/react'
import useUser from './useUser'
import useSegment from './useSegment'
import { APP_ENVIRONEMNT } from '@/config'
import { ExceptionParams, extractErrorStatusCode } from '@/utils/errors'
import { useCallback } from 'react'

interface Params {
  context?: string
}

const useLogging = ({ context }: Params) => {
  const { track } = useSegment()
  const { currentUser } = useUser()

  const logException = useCallback(
    (error: unknown, { message, priority, tags }: ExceptionParams = {}) => {
      if (APP_ENVIRONEMNT !== 'production') return

      const code = extractErrorStatusCode(error)

      Sentry.withScope(scope => {
        scope.setTransactionName(message)
        Sentry.captureException(error, {
          tags: {
            context,
            message,
            priority,
            code,
            ...tags
          },
          user: {
            id: currentUser?.user_id,
            username: currentUser?.email
          }
        })
      })

      track('error', { context, message, ...tags })
    },
    [track, context, currentUser]
  )

  return { logException }
}

export default useLogging
