import { Megaphone } from '@phosphor-icons/react'
import { sourceToMetrics } from './metrics'
import {
  currencyFormater,
  integerFormatter,
  percentageFormatter,
  percentageScoreTransformer
} from './formatters'
import { MetricItem, SupportTicketMetricKey, TicketsSatisfactionMetricKey } from '@/types/metrics'
import i18n from '../../plugins/i18n/i18n'
import { TICKET_SATISFACTION_METRICS_KEYS } from './ticketsSatisfactionMetrics'

export const SUPPORT_TICKET_TIMESERIES_METRICS_KEYS: (
  | SupportTicketMetricKey
  | TicketsSatisfactionMetricKey
)[] = [
  'support_ticket',
  'support_ticket_share',
  'ticket_cost',
  'ticket_contact_rate',
  'ticket_share_over_collection',
  'ticket_share_over_area',
  ...TICKET_SATISFACTION_METRICS_KEYS
]

export const SUPPORT_TICKET_METRICS_KEYS: (
  | SupportTicketMetricKey
  | TicketsSatisfactionMetricKey
)[] = [
  'support_ticket',
  'support_ticket_share',
  'potential_ticket_savings',
  'ticket_contact_rate',
  'ticket_share_over_collection',
  'ticket_share_over_area',
  ...TICKET_SATISFACTION_METRICS_KEYS
]

export const supportTicketTimeseries: Record<SupportTicketMetricKey, MetricItem> = {
  support_ticket: {
    label: i18n.t('count'),
    selectedLabel: i18n.t('ticketsCount'),
    icon: Megaphone,
    formatter: integerFormatter,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'feedback_count',
      label: `${sourceToMetrics.support_ticket.label}_count`,
      filter: { kind: 'support_ticket' }
    }
  },
  support_ticket_share: {
    label: i18n.t('percentCount'),
    selectedLabel: i18n.t('ticketsPercentCount'),
    icon: Megaphone,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `${sourceToMetrics.support_ticket.label}_share`,
      filter: { kind: 'support_ticket' }
    }
  },
  ticket_cost: {
    label: i18n.t('cost'),
    selectedLabel: i18n.t('ticketsCost'),
    icon: Megaphone,
    formatter: currencyFormater,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'ticket_cost',
      label: 'ticket_cost'
    }
  },
  potential_ticket_savings: {
    label: i18n.t('potentialSaving'),
    selectedLabel: i18n.t('ticketsPotentialSaving'),
    icon: Megaphone,
    formatter: currencyFormater,
    numberTransformer: (value: number) => value,
    metric: {
      name: 'potential_ticket_savings',
      label: 'potential_ticket_savings'
    }
  },
  ticket_contact_rate: {
    label: i18n.t('contactRate'),
    selectedLabel: i18n.t('ticketsContactRate'),
    icon: Megaphone,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'ticket_contact_rate',
      label: 'ticket_contact_rate'
    }
  },
  ticket_share_over_collection: {
    label: i18n.t('percentCountOverCollection'),
    selectedLabel: i18n.t('tictedPercentCountOverCollection'),
    icon: Megaphone,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `ticket_share_over_collection`,
      filter: { kind: 'support_ticket' },
      share_filter: { kind: 'support_ticket' }
    }
  },
  ticket_share_over_area: {
    label: i18n.t('percentCountOverArea'),
    selectedLabel: i18n.t('ticketsPercentCountOverArea'),
    icon: Megaphone,
    formatter: percentageFormatter,
    numberTransformer: percentageScoreTransformer,
    metric: {
      name: 'feedback_share',
      label: `ticket_share_over_area`,
      filter: { kind: 'support_ticket' },
      share_filter: { kind: 'support_ticket' }
    }
  }
}
