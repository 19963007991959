import Request, { CustomHeader } from './Request'

import { InviteData, UserAccess, UserRequests, UserWithInviter } from '@/types/auth'
import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js'
import RequestHandler from './RequestHandlers/NewRequest'
import { BaseRequestReturnType } from './RequestHandlers/BaseRequestHandler'
import axios from 'axios'
import { API_URL } from '@/config'

export default class UserService {
  static PREFIX = '/users'

  static async token(payload: GetTokenSilentlyVerboseResponse & { token_type?: string }) {
    return await Request.post<UserAccess>(`${this.PREFIX}/token`, payload)
  }

  static async verifyByEmail(email: string, token: string) {
    return await Request.get<UserRequests.VerifyByEmailResponse>(
      `${this.PREFIX}/verify/${email}`,
      undefined,
      {
        headers: { [CustomHeader.BirdieToken]: token }
      }
    )
  }

  static async getInviter(currentUserId: string) {
    return await Request.get<UserWithInviter>(`${this.PREFIX}/${currentUserId}`)
  }

  static async invite(payload: UserRequests.InvitePayload) {
    return await Request.post(`${this.PREFIX}/invite`, payload)
  }

  static async reinvite(invitedUserId: string) {
    return await Request.post(`${this.PREFIX}/reinvite/${invitedUserId}`)
  }

  static async removeInvite(invitedUserId: string) {
    return await Request.del(`${this.PREFIX}/${invitedUserId}/invite`)
  }

  static async getInviteByHash(inviteHash: string): Promise<BaseRequestReturnType<InviteData>> {
    const [error, data] = await RequestHandler.get<UserRequests.GetInviteByHashResponse>(
      `/invites/${inviteHash}`,
      undefined,
      {
        headers: {
          [CustomHeader.BirdieHash]: inviteHash
        }
      }
    )

    if (error) {
      return [error, undefined]
    }

    return [
      undefined,
      {
        inviteeEmail: data.invitee_email,
        inviterName: data.inviter_name,
        organizationName: data.organization_name,
        inviteeId: data.invitee_id || ''
      } as InviteData
    ]
  }

  static async createAccount(
    userId: string,
    payload: UserRequests.CreateAccountPayload,
    inviteHash: string
  ) {
    return await Request.patch<UserRequests.CreateAccountResponse>(
      `${this.PREFIX}/${userId}/invite`,
      payload,
      {
        headers: {
          [CustomHeader.BirdieHash]: inviteHash
        }
      }
    )
  }

  static async getUsers() {
    return await Request.get<UserWithInviter[]>(`${this.PREFIX}`)
  }

  static async updateUserBlockStatus(userId: string, block: boolean) {
    return await Request.patch(`${this.PREFIX}/${userId}`, { is_blocked: block })
  }

  static async userOnboarding(payload: UserRequests.OnboardingPayload, token: string) {
    return await Request.post<UserRequests.OnboardingResponse>(
      `${this.PREFIX}/onboarding`,
      payload,
      {
        headers: { [CustomHeader.BirdieToken]: token }
      }
    )
  }

  static async updateUserRole(userId: string, payload: UserRequests.UpdateUserRolePayload) {
    return await Request.patch<void>(`${this.PREFIX}/${userId}/role`, payload)
  }

  static async getRoles() {
    return await Request.get<UserRequests.RolesResponse>('roles')
  }

  static async logout(token: string) {
    const client = axios.create({
      baseURL: API_URL
    })
    return await client.post<void>(`${this.PREFIX}/logout`, undefined, {
      headers: { Authorization: `Bearer ${token}` }
    })
  }
}
