import { OpportunityItem, OpportunityItemWithMergedContext } from '@/types/opportunity/Opportunity'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'
import useLogging from '../useLogging'
import AreaService from '@/services/AreaService'

interface Params {
  opportunities: OpportunityItem[]
  enabled: boolean
  collectionId?: string
  areasId?: string[]
}

const useOpportunitiesContexts = (
  { enabled = true, collectionId, areasId, opportunities }: Params = {
    enabled: true,
    opportunities: []
  }
) => {
  const { logException } = useLogging({ context: 'useOpportunitiesContexts' })

  const { areas } = useBasicAreaOfInterestQuery({ enabled: true, collectionId })

  const queryFn = async () => {
    let contextBy: 'collection-areas' | 'area' | 'all' = 'all'
    const filterAreasIdsPerCollection = (relations: string[]) => {
      if (areasId) {
        contextBy = 'area'
        return relations.filter(relation => areasId.includes(relation))
      }
      // the context of the opps should be of the relations of areas present in the collection
      if (collectionId && areas) {
        contextBy = 'collection-areas'
        const collectionAreasIds = areas.map(area => area.id)
        return relations.filter(relation => collectionAreasIds.includes(relation))
      }

      return relations
    }
    const [mergedContextError, mergedContextResponse] = await AreaService.getMergedAreas({
      areas: opportunities.map(opp => ({
        identifier: opp.id,
        areas_ids: filterAreasIdsPerCollection(opp.relations)
      }))
    })

    if (mergedContextError) {
      logException(mergedContextError, { message: 'Failed to fetch merged areas context' })
      throw mergedContextError
    }

    const opportunitiesWithContext = opportunities.map(
      (opportunity, index) =>
        ({
          ...opportunity,
          content: mergedContextResponse[index].content,
          mergedContext: mergedContextResponse[index].context,
          contextBy
        }) as OpportunityItemWithMergedContext
    )

    return opportunitiesWithContext
  }

  const isEnabled = enabled && opportunities.length > 0

  const { data, isLoading, isError, isFetched } = useQuery({
    queryKey: [
      'generate-contexts-for-opps',
      {
        collectionId,
        areasId,
        areas,
        opportunities
      }
    ],
    queryFn,
    enabled: isEnabled,
    retry: false
  })

  const opportunitiesWithContext = useMemo(() => {
    if (!data || !isEnabled) return opportunities as OpportunityItemWithMergedContext[]

    return data
  }, [data, isEnabled, opportunities])

  return {
    data,
    opportunitiesWithContext,
    isLoading: isEnabled ? isLoading : false,
    isError,
    isFetched
  }
}

export default useOpportunitiesContexts
