import OnboardingSuccessDialog from '@/components/atoms/dialog/OnboardingSuccessDialog'
import { FullScreenLoader } from '@/components/atoms/loader'
import useScrollToTop from '@/hooks/useScrollToTop'
import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import TopBar, { TopBarProps } from './top-bar/TopBar'
import { MainContainer } from './styles'
import PageErrorBoundary from '@/components/atoms/error-boundary/PageErrorBoundary'
import NavigationBar from './navigation-bar/NavigationBar'

interface Props extends Omit<TopBarProps, 'hideUsersItems'> {
  hideAllItems?: boolean
}

function Layout({ hideAllItems, white, ...topBarProps }: Props) {
  const { pathname } = useLocation()
  useScrollToTop([pathname])

  return (
    <React.Fragment>
      <TopBar {...topBarProps} hideUsersItems={hideAllItems} />
      {!hideAllItems && <NavigationBar />}
      <MainContainer opportunityPlan clearBackground={white}>
        <PageErrorBoundary>
          <Outlet />
        </PageErrorBoundary>
      </MainContainer>
      <FullScreenLoader />
      <OnboardingSuccessDialog />
    </React.Fragment>
  )
}

export default Layout
