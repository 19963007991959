import { Route, createRoutesFromElements } from 'react-router-dom'

import AuthMiddleware from './auth'
import NotFoundPage from './not-found'
import SignupPage from './sign-up'
import RedirectPage from './redirect'
import ErrorBoundary from '@/components/atoms/error-boundary'
import InvitePage from './invite'
import Integrations from './sources/Integrations'
import UploadData from './sources/UploadData'
import CustomFields from './sources/CustomFields'
import ArchivedContent from './sources/ArchivedContent'
import CustomFieldForm from '@/components/molecules/custom-fields/CustomFieldForm'
import ConnectorLibrary from '@/components/molecules/integrations/ConnectorLibrary'
import IntegrationDetails from '@/components/molecules/integrations/IntegrationDetails'
import IntegrationForm from '@/components/molecules/integrations/IntegrationForm'
import IntegrationPlaceholder from '@/components/molecules/integrations/IntegrationPlaceholder'
import IntegrationSelection from '@/components/molecules/integrations/IntegrationSelection'
import FeedbacksPage from './feedbacks'
import DiscoveryPage from './discovery'
import MergeFindingsPage from './discovery/MergeFindings'
import FeedbacksWithChatPage from './feedbacks/FeedbacksWithChat'
import DiscoveryWithAssistantPage from './discovery/DiscoveryWithAssistant'
import DashboardsAndTrackingPage from './dashboards/DashboardsAndTrackingPage'
import ManageCustomers from '@/components/molecules/manage-customers'
import HomeManageCustomers from '@/components/molecules/manage-customers/HomeManageCustomers'
import Accounts from '@/components/molecules/manage-customers/accounts/Accounts'
import Settings from './settings'
import {
  AlertsManagement,
  NotificationChannels,
  NotificationsTopicPlan
} from './settings/Notifications'
import Users from '@/components/molecules/manage-customers/users/Users'
import KeywordsPage from './topics'
import TopicsPage from './topics/TopicsExperience'
import SlackSuccessPage from './slack/SlackSuccessPage'
import AnalyticsPage from './analytics/AnalyticsPage'
import AnalyticsPageLayout from './analytics'
import TopicDetailsPage from './analytics/topic/TopicDetailsPage'
import FeedbackFeed from './feedbacks/new/FeedbackFeed'
import ExploreFeedbackPage from './analytics/explore-feedback/ExploreFeedbackPage'
import ExplorationPage from './exploration'
import AreaPage from './exploration/AreaPage'
import OpportunityPage from './exploration/OpportunityPage'
import CustomFieldFormV2 from '@/components/molecules/custom-fields-v2/CustomFieldForm'
import CustomFieldsV2 from './sources/CustomFieldsV2/CustomFieldsV2'
import { Layout, LayoutTopicPlan } from '@/components/molecules/layout'
import DashboardsPage from './dashboards'
import LayoutCurrentPlan from '@/components/molecules/layout/LayoutCurrentPlan'
import PageWrapper from './PageWrapper'
import AreasAndOpportunitiesPage from './areas-and-opportunities'
import HomePage from './home'
import i18n from '../plugins/i18n/i18n'
import TextReplacementPage from './text-replacement'
import ParamsPage from './params'
import OrganizationSettings from '@/components/molecules/organization-settings'
import InitiativePage from './initiatives'
import AllInitiativesPage from './initiatives/AllInitiativesPage'
import PageErrorBoundary from '@/components/atoms/error-boundary/PageErrorBoundary'
import AccessDenied from './access-denied'
// import InitiativesPage from './initiatives'

const routes = createRoutesFromElements(
  <>
    <Route
      element={
        <AuthMiddleware>
          <RedirectPage />
        </AuthMiddleware>
      }
      path="/"
    />
    <Route
      element={
        <PageWrapper>
          <LayoutTopicPlan />
        </PageWrapper>
      }
    >
      <Route element={<AnalyticsPageLayout />} path="/analytics">
        <Route element={<AnalyticsPage />} index />
        <Route element={<ExploreFeedbackPage />} path="/analytics/explore-feedback" />
        <Route element={<TopicDetailsPage />} path="/analytics/:topicType/:topicString" />
      </Route>
      <Route element={<DiscoveryPage />} path="/discovery" />
      <Route element={<KeywordsPage />} path="/topics" />
      <Route element={<KeywordsPage useKeywordTitle />} path="/keywords" />
      <Route element={<TopicsPage />} path="/topic-management" />
      <Route element={<FeedbacksPage />} path="/feedback" />
      <Route element={<FeedbackFeed />} path="/feedback/new" />

      <Route element={<FeedbacksWithChatPage />} path="/feedback/chat" />
      <Route element={<MergeFindingsPage />} path="/merge_findings" />
      <Route element={<DiscoveryWithAssistantPage />} path="/discovery/assistant" />
      <Route element={<DashboardsAndTrackingPage />} path="/tracking" />

      <Route element={<NotFoundPage />} path="*" />
    </Route>

    <Route
      element={
        <AuthMiddleware>
          <ErrorBoundary>
            <SignupPage />
          </ErrorBoundary>
        </AuthMiddleware>
      }
      path="/signup"
    >
      <Route element={<NotFoundPage />} path="*" />
    </Route>

    <Route element={<PageWrapper name={i18n.t('exploration')} />} path="/exploration">
      <Route element={<ExplorationPage />} path="/exploration">
        <Route element={<AreaPage />} index />
      </Route>
    </Route>

    <Route element={<PageWrapper name={i18n.t('areasAndOpportunities')} />} path="/exploration">
      <Route element={<ExplorationPage />} path="/exploration">
        <Route element={<AreaPage />} path=":shortAreaId" />
        <Route element={<OpportunityPage />} path=":shortAreaId/:shortOpportunityId" />
      </Route>
    </Route>

    <Route element={<PageWrapper name={i18n.t('initiatives')} />} path="/initiatives">
      <Route element={<AllInitiativesPage />} index />
    </Route>

    <Route element={<PageWrapper white name={i18n.t('initiatives')} />} path="/initiatives">
      <Route element={<InitiativePage />} path=":shortInitiativeId" />
    </Route>

    <Route element={<PageWrapper name={i18n.t('areaOfInterest')} />} path="/area/:shortAreaId">
      <Route element={<ExplorationPage />} path="/area/:shortAreaId">
        <Route element={<AreaPage />} index />
      </Route>
    </Route>

    <Route
      element={<PageWrapper name={i18n.t('opportunity')} />}
      path="/opportunity/:shortOpportunityId"
    >
      <Route element={<ExplorationPage />} path="/opportunity/:shortOpportunityId">
        <Route element={<OpportunityPage />} index />
      </Route>
    </Route>

    <Route
      element={
        <PageWrapper>
          <LayoutCurrentPlan name={i18n.t('settings')} />
        </PageWrapper>
      }
      path="/settings"
    >
      <Route element={<Settings />} path="/settings">
        <Route element={<NotificationsTopicPlan />} path="topic_notifications" />
        <Route element={<AlertsManagement />} path="manage_notifications" />
        <Route element={<NotificationChannels />} path="notification_channels" />
        <Route element={<OrganizationSettings />} path="organization" />
        <Route element={<Integrations />} path="integrations">
          <Route element={<ConnectorLibrary />} index />
          <Route element={<IntegrationSelection />} path=":connectorId" />
          <Route element={<IntegrationForm />} path=":connectorId/new" />
          <Route element={<IntegrationPlaceholder />} path=":connectorId/placeholder" />
          <Route element={<IntegrationDetails />} path=":connectorId/:integrationId" />
          <Route element={<IntegrationForm />} path=":connectorId/:integrationId/edit" />
        </Route>
        <Route element={<UploadData />} path="import_data" />
        <Route element={<CustomFieldsV2 />} path="custom_fields">
          <Route element={<CustomFieldFormV2 />} path="new" />
          <Route element={<CustomFieldFormV2 />} path=":customFieldId/edit" />
        </Route>
        <Route element={<CustomFields />} path="custom_fields_deprecated">
          <Route element={<CustomFieldForm />} path="new" />
          <Route element={<CustomFieldForm />} path=":customFieldId/edit" />
        </Route>
        <Route element={<ArchivedContent />} path="archived_content" />

        <Route element={<ManageCustomers />} path="import_customers">
          <Route element={<HomeManageCustomers />} index />
          <Route element={<Accounts />} path="accounts" />
          <Route element={<Users />} path="users" />
        </Route>
      </Route>
    </Route>

    <Route element={<PageWrapper name="Home" />} path="/home">
      <Route element={<HomePage />} index />
    </Route>

    <Route
      element={<PageWrapper name={i18n.t('areasAndOpportunities')} />}
      path="/areas-and-opportunities"
    >
      <Route element={<AreasAndOpportunitiesPage />} index />
    </Route>

    <Route element={<PageWrapper name="Dashboards" />} path="/dashboards">
      <Route element={<DashboardsPage />} index />
    </Route>

    <Route element={<PageWrapper name="Params" />} path="/params">
      <Route element={<ParamsPage />} index />
    </Route>

    <Route element={<PageWrapper name="Text replacement" />} path="txtrplc">
      <Route element={<TextReplacementPage />} index />
    </Route>

    <Route element={<InvitePage />} path="/invite" />

    <Route element={<RedirectPage />} path="/redirect" />

    <Route element={<SlackSuccessPage />} path="/slack/success" />

    <Route
      element={
        <PageErrorBoundary>
          <Layout hideAllItems />
        </PageErrorBoundary>
      }
      path="/access-denied"
    >
      <Route element={<AccessDenied />} index />
    </Route>
  </>
)

export default routes
