import { useMemo } from 'react'
import useAdvancedFilters from './useAdvancedFilters'
import useEnumFiltersQuery from '../filters/useEnumFiltersQuery'
import useStringSelection from '../useStringSelection'
import { intentionFeedQueryParam } from '@/utils/intention'
import { Intention } from '@/types/reasons'
import { prefixFilterName, sentimentToFeedQueryParam } from '@/utils/filters'
import { EnumFilterType } from '@/types/filters/AdvancedFilters'
import useAccountStringFilter from '@/components/molecules/filters/FiltersSidebar/CustomerRecordsFilter/Accounts/useAccountStringFilter'
import useCustomerUsersStringFilter from '@/components/molecules/filters/FiltersSidebar/CustomerRecordsFilter/Users/useCustomerUsersStringFilter'

interface Params {
  name: string
  path: string
  enableQuery?: boolean
  initialValue?: string[]
  type?: EnumFilterType
  index?: number
}

const useEnumAdvancedFilter = ({
  name,
  path,
  enableQuery = true,
  initialValue = [],
  type = 'enum',
  index
}: Params) => {
  const isCustomerRecord = type !== 'enum'
  const { data, isLoading } = useEnumFiltersQuery({
    name,
    enabled: enableQuery && !isCustomerRecord
  })

  const { options: accountOptions, isLoading: isLoadingAccount } = useAccountStringFilter({
    filterKey: name.replace('account.', ''),
    enabled: enableQuery && type === 'accounts-enum'
  })
  const { options: userOptions, isLoading: isLoadingUser } = useCustomerUsersStringFilter({
    filterKey: name.replace('user.', ''),
    enabled: enableQuery && type === 'users-enum'
  })

  const options = useMemo(() => {
    if (type === 'accounts-enum') return accountOptions
    if (type === 'users-enum') return userOptions
    if (!data) return []
    return data.values ?? []
  }, [data, type, accountOptions, userOptions])

  const { addFilter, updateFilter, removeFilter } = useAdvancedFilters()

  const { selectOption, selectedOptions, isChecked } = useStringSelection(initialValue)

  let prefix = ''
  if (type === 'accounts-enum') {
    prefix = 'account.'
  } else if (type === 'users-enum') {
    prefix = 'user.'
  }

  const apply = () => {
    let value = selectedOptions
    if (name === 'intention') {
      value = selectedOptions.map(intention => intentionFeedQueryParam[intention as Intention])
    } else if (name === 'sentiment') {
      value = selectedOptions.map(
        sentiment => sentimentToFeedQueryParam[sentiment as SimpleSentiment]
      )
    }

    addFilter({ name: prefixFilterName(prefix, name), type, value, path, status: 'valid' })
  }

  const update = () => {
    if (index === undefined) return

    let value = selectedOptions

    if (value.length === 0) {
      removeFilter({ index })
      return
    }

    if (name === 'intention') {
      value = selectedOptions.map(intention => intentionFeedQueryParam[intention as Intention])
    } else if (name === 'sentiment') {
      value = selectedOptions.map(
        sentiment => sentimentToFeedQueryParam[sentiment as SimpleSentiment]
      )
    }

    updateFilter({
      index,
      filter: { name: prefixFilterName(prefix, name), type, value, path, status: 'valid' }
    })
  }

  const loading =
    (!isCustomerRecord && isLoading && enableQuery) ||
    (type === 'accounts-enum' && isLoadingAccount) ||
    (type === 'users-enum' && isLoadingUser)

  const isDisabled = loading || selectedOptions.length === 0

  return {
    apply,
    update,
    isChecked,
    selectOption,
    selectedOptions,
    options,
    isDisabled,
    isLoading: loading
  }
}

export default useEnumAdvancedFilter
