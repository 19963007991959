import Text from '@/components/atoms/text'
import { styled } from '@/theme'
import FlexContainer from '@/components/atoms/flex-container'
import Dialog from '@/components/atoms/dialog/Dialog'
import Button from '@/components/atoms/button'
import { useState } from 'react'
import Input from '@/components/atoms/input'
import Divider from '@/components/atoms/divider'
import { useTranslation } from 'react-i18next'

const Container = styled(FlexContainer, {
  flexDirection: 'column',
  padding: '$xs $md',
  gap: '$xs'
})

interface Props {
  initiativeName?: string
  onSave: (newName: string) => void
  onClose: () => void
}

function RenameInitiativeDialog({ initiativeName, onSave, onClose }: Props) {
  const [name, setName] = useState(initiativeName || '')

  const { t } = useTranslation()
  const onOpenChange = (open: boolean) => {
    if (!open) onClose()
  }

  const save = () => {
    onSave(name)
    onClose()
  }

  const isDisabled = !name.trim().length || name === initiativeName

  return (
    <Dialog
      align="center"
      closeIcon
      contentProps={{ css: { padding: 0 } }}
      modal
      onOpenChange={onOpenChange}
      open={!!initiativeName}
      width="medium"
    >
      <Container>
        <Text fontSize="xs" fontWeight="bold" lineHeight="default">
          {t('renameInitiative')}
        </Text>

        <FlexContainer direction="column" gap="xxs">
          <Text> {t('nameTheInitiativeAsYouWantItDisplayed')}</Text>
          <Input onChange={e => setName(e.currentTarget.value)} value={name} />
        </FlexContainer>

        <Divider line="solid" orientation="horizontal" />

        <Button css={{ width: 'auto' }} disabled={isDisabled} onClick={save}>
          {t('rename')}
        </Button>
      </Container>
    </Dialog>
  )
}

export default RenameInitiativeDialog
