import FlexContainer from '@/components/atoms/flex-container'
import { InitiativeItem } from '@/types/initiatives/Initiatives'
import InitiativeStatusChip from '../../InitiativeStatusChip'
import { DueDateChipWithUsers } from '../../DueDateChip'
import Divider from '@/components/atoms/divider'
import { useCallback, useMemo } from 'react'
import shortUUID from 'short-uuid'
import { LinkFromTo } from '@/components/atoms/links'
import useInitiativeStore from '@/store/initiatives/useInitiativeStore'
import { styled } from '@/theme'

const IniativeLink = styled(LinkFromTo, {
  fontSize: '$xxxs',

  '&:hover': {
    color: '$brandPrimaryMedium',
    textDecoration: 'underline'
  }
})

interface Props {
  initiative: InitiativeItem
}

const RelatedInitiativeRow = ({ initiative }: Props) => {
  const translator = useMemo(() => shortUUID(), [])

  const shortInitiativeId = useMemo(
    () => translator.fromUUID(initiative.id ?? ''),
    [initiative.id, translator]
  )

  const toLink = useMemo(() => `/initiatives/${shortInitiativeId}`, [shortInitiativeId])

  const setCurrentInitiative = useInitiativeStore(state => state.setCurrentInitiative)
  const onClick = useCallback(() => {
    setCurrentInitiative(initiative)
  }, [initiative, setCurrentInitiative])

  return (
    <FlexContainer direction="column" gap="micro">
      <FlexContainer alignItems="center" justifyContent="spaceBetween">
        <IniativeLink onClick={onClick} to={toLink}>
          {initiative.name}
          {/* <Text fontSize="xxxs">{initiative.name}</Text> */}
        </IniativeLink>
        <InitiativeStatusChip status={initiative.status} />
      </FlexContainer>
      <DueDateChipWithUsers initiative={initiative} />
      <Divider line="solid" orientation="horizontal" css={{ mt: '$xxs' }} />
    </FlexContainer>
  )
}

export default RelatedInitiativeRow
