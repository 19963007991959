import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import { useTranslation } from 'react-i18next'
import { SentimentFilterContent } from '../../FiltersSidebar/SentimentFilter'
import { Sentiment } from '@/utils/sentiments'
import useSentimentFilter from '@/hooks/advancedFilters/useSentimentFilter'

interface Props {
  value: Sentiment
  index: number
  disabled?: boolean
}

const SentimentFilterApplied = ({ value, index, disabled = false }: Props) => {
  const { t } = useTranslation()

  const { update, selectedOption, setSelectedOption } = useSentimentFilter({
    initialValue: value,
    index
  })

  const label = selectedOption ? t(selectedOption) : ' '

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={label}
      contentType="label"
      disabled={disabled}
      dropdown={<SentimentFilterContent value={selectedOption} onValueChange={setSelectedOption} />}
      onApply={update}
      title={t('sentiment')}
      tooltip={label}
    />
  )
}

export default SentimentFilterApplied
